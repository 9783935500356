import axios from "axios";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AtkMessage from "../AtlaskitControls/AtkMessage";
import NcLogo from "../assets/images/nowcertlogo.png";
import Footer from "../common/Footer";
import Header from "../common/Header";
import CommonValues from "../common/utils";

export default function NowcertsSsoPage() {
  const navigate = useNavigate();
  const toast: any = useRef("");

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token");
    if (window.location.href.includes("nowcertssso")) {
      validateNowcertsToken(token);
    }
  }, []);

  const validateNowcertsToken = (token: any) => {
    setLoading(true);
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_AUTHAPI}/api/account/validateNowcertsToken?token=${token}`,
    };

    axios(config)
      .then((response: any) => {
        if (response.data.token == "") {
          CommonValues.Logout(navigate);
        } else {
          localStorage.setItem("igtoken", response.data.token);
          localStorage.setItem("tenantId", response.data.tenantId);
          localStorage.setItem("userName", response.data.userName);
          onUpdateMomentumClick(response.data.userName);
        }
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting details!",
            life: 3000,
          });
        }
      });
  };
  const onUpdateMomentumClick = (ncSetupUserName: any) => {
    let token = CommonValues.GetToken();
    var data = JSON.stringify({
      NCUserName: ncSetupUserName,
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/verifyMomentumCreds`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        if (response.data != "" && response.data != null) {
          localStorage.setItem("NCToken", response.data.access_token);
          localStorage.setItem("NCAgencyId", response.data.agencyId);
          getMomentumToken();
        }
      })
      .catch((error: any) => {
        console.log(error);
        setLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else if (error.response.status == 409) {
            toast.current.show({
              severity: "info",
              detail:
                "It look likes you are not registered with NowCerts, Please register first.",
              life: 3000,
            });
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while updating the NowCerts Settings",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while updating the NowCerts Settings",
            life: 3000,
          });
        }
      });
  };
  const getMomentumToken = () => {
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/getmomentumtoken`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        if (response.data != "" || response.data != null) {
          localStorage.setItem("NCToken", response.data.access_token);
          localStorage.setItem("NCAgencyId", response.data.agencyId);
          getWizardSetupDetails();
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage =
              "Unknown error while loading the NowCerts Setting details";
            toast.current.show({
              severity: "error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        } else {
          errorMessage =
            "Unknown error while loading the NowCerts Setting details";
          toast.current.show({
            severity: "error",
            detail: { errorMessage },
            life: 3000,
          });
        }
      });
  };
  const getWizardSetupDetails = () => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/getwizardsetup`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
    };
    axios(config)
      .then((response: any) => {
        if (response.status == 204) {
          insertWizardDetails();
        } else {
          setLoading(false);
          if (response.data.isWizardSetupDone) {
            navigate("/premiumfinance");
            localStorage.setItem("isSetupCompleted", "true");
          } else {
            localStorage.setItem("isSetupCompleted", "false");

            navigate("/setupwizard");
          }
        }
      })
      .catch((error: any) => {
        setLoading(false);
        let errorMessage = "";

        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the Setting details";
            toast.current.show({
              severity: "error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        } else {
          errorMessage = "Unknown error while loading the Setting details";
          toast.current.show({
            severity: "error",
            detail: { errorMessage },
            life: 3000,
          });
        }
      });
  };
  const insertWizardDetails = () => {
    var token = CommonValues.GetToken();
    var config = {
      method: "post",
      url: `${
        process.env.REACT_APP_UserService_Url
      }/api/user/insertwizardsetup?IsWizardSetupDone=${false}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
    };
    axios(config)
      .then((response: any) => {
        setLoading(false);
        if (response.data.isWizardSetupDone) {
          navigate("/premiumfinance");
          localStorage.setItem("isSetupCompleted", "true");
        } else {
          navigate("/setupwizard");
          localStorage.setItem("isSetupCompleted", "false");
        }
      })
      .catch((error: any) => {
        let errorMessage = "";
        setLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the Setting details";
            toast.current.show({
              severity: "error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        } else {
          errorMessage = "Unknown error while loading the Setting details";
          toast.current.show({
            severity: "error",
            detail: { errorMessage },
            life: 3000,
          });
        }
      });
  };
  return (
    <div className="nowcerts-sso-page">
      <Toast ref={toast} />
      <Header />
      <div className="content-wrapper container-fluid landing-content-wrapper d-flex justify-content-center">
        <div className="login-box ">
          <div className="row mx-auto d-flex align-items-center">
            <div className="col-12 text-center  nowcerts-loader">
              {loading ? (
                <>
                  <div className=" authenticating-block text-center">
                    <img src={NcLogo} height="60px" />
                    <div className="mt-3 mb-3">
                      <span className="">Authenticating</span>
                    </div>
                    <div className="d-flex justify-content-center nowcerts-sso-loader">
                      <AtkLoader />
                    </div>
                  </div>
                </>
              ) : (
                <div
                  className=" d-flex align-items-center justify-content-center"
                  style={{ minHeight: "100px" }}
                >
                  <span className="not-authorize-text">
                    You are not authorized
                  </span>
                </div>
              )}
              {errorMessage != null && errorMessage != "" ? (
                <div className="col-12 mb-2 mt-2 ">
                  <AtkMessage appearance="error" messageText={errorMessage} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
