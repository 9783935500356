import axios from "axios";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AtkButton from "../AtlaskitControls/AtkButton";
import AtkButtonIconBefore from "../AtlaskitControls/AtkButtonIconBefore";
import AtkIcon from "../AtlaskitControls/AtkIcon";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AtkMessage from "../AtlaskitControls/AtkMessage";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import HistoryIcon from "../assets/images/History_B.svg";
import Magnifier from "../assets/images/MagnifierIcon.png";
import NewIcon from "../assets/images/NewQuote_B 1.svg";
import VehicleLogo from "../assets/images/Vehicle.png";
import Footer from "../common/Footer";
import Header from "../common/Header";
import CommonValues from "../common/utils";
import VehicleHistory from "./VehicleHistory";

export default function Vehicle() {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [search, setSearch] = useState("");
  const [clientName, setClientName] = useState("");
  const [searchClientLoading, setSearchClientLoading] = useState(false);
  const [searchClientList, setSearchClientList] = useState<any>([]);
  const [insuredDataBaseId, setInsuredDataBaseId] = useState("");
  const [selectedTab, setSelectedTab] = useState(1);
  const [showVehicleData, setShowVehicleData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDotNumber, setSelectedDotNumber] = useState(false);
  const [selectedLicensePlateNumber, setSelectedLicensePlateNumber] =
    useState(false);
  const [selectedCompanyName, setSelectedCompanyName] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [valueOfSelectedType, setValueOfSelectedType] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showVehicleHistory, setShowVehicleHistory] = useState(false);
  const [showClientSearch, setShowClientSearch] = useState(true);
  const [clientNameError, setClientNameError] = useState("");
  const [selectedTypeError, setSelectedTypeError] = useState("");
  const [valueOfSelectedTypeError, setValueOfSelectedTypeError] = useState("");
  const [requestDataList, setRequestDataList] = useState<any>([]);
  const [responseVehicleDataList, setResponseVehicleDataList] = useState<any>(
    []
  );
  const [transactionId, setTransactionId] = useState("");
  const [currentBalance, setCurrentBalance] = useState("0");
  const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [onSubmitLoader, setonSubmitLoader] = useState(false);
  const [showRateLimitPopMessage, setShowRateLimitPopMessage] = useState(false);
  const [hideAllData, setHideAllData] = useState(false);
  const [existingDataInAMSList,setExistingDataInAMSList]=useState<any>([]);

  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      navigate("/");
      CommonValues.Logout(navigate);
    }
    if (search == "") {
      return;
    }
    setSearchClientLoading(true);
    if (
      CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id
    ) {
      const searchTimeout = setTimeout(() => {
        getNowCertsClients(search);
      }, 10);
      return () => clearTimeout(searchTimeout);
    }
  }, [search]);

  useEffect(() => {
    getCardDetails();
  }, []);

  const getCardDetails = () => {
    setCurrentBalanceLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success) {
          setCurrentBalance(
            "" + response.data.currentBalance / 100
          );
        }
        setCurrentBalanceLoading(false);
      })
      .catch((error) => {
        setCurrentBalanceLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting current balance",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting current balance",
            life: 3000,
          });
        }
      });
  };
  const onClientSearchChange = (value: any) => {
    if (value.length > 255) {
      return;
    }
    setSearch(value);
    setClientName(value);
    setClientNameError("");
  };
  const onEnterValueChange = (value: any) => {
    if (value.length > 255) {
      return;
    }
    setValueOfSelectedType(value);
    setValueOfSelectedTypeError("");
  };
  const getNowCertsClients = (query: string) => {
    const data = {
      SearchValue: query,
    };
    const nowCertsToken = localStorage.getItem("NCToken")
      ? localStorage.getItem("NCToken")
      : "";
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlist?searchText=${query}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setSearchClientList(response.data);
        setSearchClientLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail:
                "Unknown error while searching the insureds data.Please try again later.",
              life: 3000,
            });
          }
        }
        // setErrorMessage(errorMessage);
        setSearchClientLoading(false);
      });
  };
  const onClientSelect = (client: any) => {
    let insuredDatabaseId = client.databaseId;
    setInsuredDataBaseId(insuredDatabaseId);
    setSearch("");
    setClientName(client.name);
    getExistingVehicleList(insuredDatabaseId);
  };
  const onTabSelect = (index: any) => {
    if (index == 1) {
      setSelectedTab(index);
      setShowClientSearch(true);
    } else {
      setSelectedTab(index);
      setShowClientSearch(false);
    }
  };
  const vehicleSearchDetailsValidation = () => {
    let formValid = true;
    setClientNameError("");
    setSelectedTypeError("");
    setValueOfSelectedTypeError("");

    if (
      clientName === "" ||
      clientName === null ||
      insuredDataBaseId === "" ||
      insuredDataBaseId === null
    ) {
      setClientNameError("Please enter and select the client name.");
      formValid = false;
    }

    if (selectedType === "" || selectedType === null) {
      setSelectedTypeError(
        "Please select the vehicle identification criteria."
      );
      formValid = false;
    }

    if (valueOfSelectedType !== "" && valueOfSelectedType !== null) {
      const alphaNumericRegex = /^[A-z\d]+$/i;
      const numericRegex = /^[\d]+$/i;
      if (selectedType === "DOT-Number") {
        if (!numericRegex.test(valueOfSelectedType)) {
          setValueOfSelectedTypeError("Please enter a valid DOT number.");
          formValid = false;
        }
      } else if (selectedType === "License-Plate") {
        const licensePlate = valueOfSelectedType.split("-")[0];
        const state = valueOfSelectedType.split("-")[1];
        const stateRegex = /^[A-z]/i;
        if (
          state === undefined ||
          !alphaNumericRegex.test(licensePlate) ||
          !stateRegex.test(state) ||
          state.length != 2
        ) {
          setValueOfSelectedTypeError(
            "Please enter a valid license plate number. Example: 'AF60214-PA'."
          );
          formValid = false;
        }
      } else if (selectedType === "Company-Name") {
        const companyNameRegex = /^[\w\s]/i;
        if (!companyNameRegex.test(valueOfSelectedType)) {
          setValueOfSelectedTypeError("Please enter a valid company name");
          formValid = false;
        }
      } else {
        setSelectedTypeError(
          "Please select the vehicle identification criteria."
        );
        formValid = false;
      }
    } else {
      setValueOfSelectedTypeError(
        "Please enter value of  vehicle identification criteria."
      );
      formValid = false;
    }

    return formValid;
  };
  const vehicleSearchDetails = () => {
    if (!vehicleSearchDetailsValidation()) {
      return;
    }
    setLoading(true);
    const data = {
      target: "VEHICLE-CARRIER-SEARCH",
      insuredName: clientName,
      insuredId: insuredDataBaseId,
      selectedType: selectedType,
      selectedTypeOfValue: valueOfSelectedType,
    };
    const token = CommonValues.GetToken();
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/vehicle/searchvehicledetails`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        if(response.data.success == true){
        setShowVehicleData(true);
        setLoading(false);
        getCardDetails();
        let responseData = response.data.vehiclePrefillMaster.map(
          (item: any) => {
            return {
              ...item,
              check: false,
              existingDataInAMSFlag:false
            };
          }
        );
        setResponseVehicleDataList(responseData);
        
        if(existingDataInAMSList.length >0){
        let existingDataList=existingDataInAMSList
        let dataList= responseData.map((item:any)=>{
          existingDataList.map((element:any)=>{
            if(element.active)
              {
                if(element.vin == item.vinNumber)
                  {
                    item.existingDataInAMSFlag=true
                  }
              }
           })
           return item
        })
        setResponseVehicleDataList(dataList);
      }
        let transactionId = response.data.transactionId;
        setTransactionId(transactionId);
        setHideAllData(false);
    }
    else if (
      response.data.success === false &&
      response.data.errorMessage ===
      "User does not have enough credits for vehicle carrier search API call."
    ) {
      setHideAllData(true);
      toast.current.show({
        severity: "info",
        detail: "User does not have enough credits in the current balance.",
        life: 3000,
      });
    } else {
    }
        
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else if (error.response.status === 429) {
            setShowVehicleData(false);
            setShowRateLimitPopMessage(true);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while searching vehicle details",
              life: 3000,
            });
          }
        }
        setLoading(false);
      });
  };
  const onDotNumberChange = () => {
    setSelectedType("DOT-Number");
    setSelectedTypeError("");
    setValueOfSelectedType("");
    setSelectedDotNumber(true);
    setSelectedCompanyName(false);
    setSelectedLicensePlateNumber(false);
  };
  const onLicensePlateChange = () => {
    setSelectedType("License-Plate");
    setValueOfSelectedType("");
    setSelectedTypeError("");
    setSelectedLicensePlateNumber(true);
    setSelectedDotNumber(false);
    setSelectedCompanyName(false);
  };
  const onCompanyNameChange = () => {
    setSelectedType("Company-Name");
    setValueOfSelectedType(clientName);
    setValueOfSelectedTypeError("");
    setSelectedTypeError("");
    setSelectedCompanyName(true);
    setSelectedDotNumber(false);
    setSelectedLicensePlateNumber(false);
  };
  const onSelectDataChanges = (element: any, index: any) => {
    const updateList = responseVehicleDataList.map((item: any) => {
      if (element.uniqueId === item.uniqueId) {
        if (item.check) {
          const updatedList = {
            ...item,
            check: false
          };
          return updatedList;
        } else {
          const updatedList = {
            ...item,
            check: true,
          };
          return updatedList;
        }
      }
      return item;
    });
    setResponseVehicleDataList(updateList);
  };
  const onSubmitValidation = () => {
    if (
      responseVehicleDataList.find((element: any) => element.check === true) ==
      undefined
    ) {
      toast.current.show({
        severity: "info",
        detail: "Please select at least one vehicle prefill details.",
        life: 3000,
      });
      return;
    }
    setShowConfirmationBox(true);
  };
  const onSubmitClick = () => {
    setShowConfirmationBox(false);
    setSubmitLoading(true);
    setonSubmitLoader(true);

    responseVehicleDataList.forEach((item: any, index: any) => {
      if (item.check) {
        if (
          requestDataList.find(
            (element: any) => element.vinNumber === item.vinNumber
          ) === undefined
        ) {
          requestDataList.push({
            insured_database_id: insuredDataBaseId,
            insured_commercial_name: clientName,
            vinNumber: item.vinNumber,
            vehicleType: item.vehicleType,
            vehicleManufacturer: item.vehicleManufacturer,
            check: item.check,
          });
        }
      } else {
        requestDataList.splice(index, 1);
      }
    });
    const data = {
      transactionId: transactionId,
      addVehicleRequests: requestDataList,
    };

    let nowCertsToken = localStorage.getItem("NCToken")
      ? localStorage.getItem("NCToken")
      : "";
    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/addvehicles?&ncToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        toast.current.show({
          severity: "success",
          detail: "Vehicle added successfully in NowCerts AMS.",
          life: 3000,
        });
        setTimeout(() => {
          setResponseVehicleDataList([]);
          setClientName("");
          setSelectedDotNumber(false);
          setSelectedLicensePlateNumber(false);
          setSelectedCompanyName(false);
          setValueOfSelectedType("");
          setSubmitLoading(false);
          setShowVehicleData(false);
          setonSubmitLoader(false);
        }, 2000);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else if (error.response.status === 400) {
            toast.current.show({
              severity: "error",
              detail: "Bad Request",
              life: 3000,
            });
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while adding vehicle data in nowcerts",
              life: 3000,
            });
          }
        }
        setSubmitLoading(false);
        setonSubmitLoader(false);
      });
  };

  const getExistingVehicleList =(insuredDatabaseId:any)=> {
    let nowCertsToken = localStorage.getItem("NCToken")
    ? localStorage.getItem("NCToken")
    : "";
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/vehicles?&insuredDatabaseId=${insuredDatabaseId}&ncToken=${nowCertsToken}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then((response) => {
      let existingDataInAMSList=response.data.value;
      setExistingDataInAMSList(existingDataInAMSList)
    })
    .catch((error) => {
      let errorMessage = "";
      if (error.response != null) {
        if (error.response.status === 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting existing vehicle data.",
            life: 3000,
          });
        }
      }
    });
};

  const onNewVehiclePrefilClick = () => {
    setShowVehicleData(false);
    setValueOfSelectedType("");
    setSelectedDotNumber(false);
    setSelectedLicensePlateNumber(false);
    setSelectedCompanyName(false);
    setSearch("");
    setClientName("");
    setShowVehicleHistory(false);
    setRequestDataList([]);
    setResponseVehicleDataList([]);
  };
  const onVehicleHitoryClick = () => {
    setShowVehicleHistory(true);
    setShowVehicleData(false);
  };
  const oncancelClicks = () => {
    setShowConfirmationBox(false);
  };

  return (
    <div className="fenris-main-page">
      <Header />
      <div className="content-wrapper">
        <Toast ref={toast} />
        <div className="row wrapper-main-row mx-auto wrapper-below-div">
          <div className="col-12">
            <div className="middle-header-col active ms-2">
              <img
                src={VehicleLogo}
                className="middle-header-logo"
              />
              <span className="middle-header-title">Vehicle Prefill</span>
            </div>
          </div>
          <div className="row main-content-row mx-auto">
            <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
              <div
                className="pt-4 pf-sidebar-block p-0"
                onClick={onNewVehiclePrefilClick}
              >
                <span
                  className={
                    selectedTab == 1
                      ? " pf-sidebar-menu active"
                      : "pf-sidebar-menu show-cursor"
                  }
                  onClick={() => onTabSelect(1)}
                >
                  <img
                    src={NewIcon}
                    className="pf-sidebar-img pf-sidebar-img-active"
                  />
                  <span className="sidebar-menu-title">New Prefill</span>{" "}
                </span>
              </div>
              <div
                className="pt-2 pf-sidebar-block p-0"
                onClick={onVehicleHitoryClick}
              >
                <span
                  className={
                    selectedTab == 2
                      ? " pf-sidebar-menu active"
                      : "pf-sidebar-menu show-cursor"
                  }
                  onClick={() => onTabSelect(2)}
                >
                  <img
                    src={HistoryIcon}
                    className="pf-sidebar-img pf-sidebar-img-active"
                  />
                  <span className="sidebar-menu-title">History</span>{" "}
                </span>
              </div>
            </div>
            <div className="col-lg-10 col-md-12 col-sm-12 main-content-col">
              <div className="row client-search-row ">
                {showClientSearch ? (
                  <>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 position-relative p-0">
                          <div>
                            <AtkTextField
                              type="text"
                              id="text"
                              className="vehicle-search-area"
                              name="clientName"
                              onChange={(e) => {
                                onClientSearchChange(e.target.value);
                                setClientNameError("");
                              }}
                              value={clientName}
                              placeholder="Enter client name"
                              autoComplete="off"
                            />
                          </div>
                          {search.length > 0 && (
                            <div
                              id="vehicles-client-search"
                              className="position-absolute w-100"
                            >
                              {searchClientLoading ? (
                                <div className="d-flex justify-content-center my-2 client-loader-div">
                                  <AtkLoader />
                                </div>
                              ) : searchClientList.length == 0 ? (
                                <div
                                  style={{
                                    padding: "5px",
                                    background: "white",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "#ddd",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    No results
                                  </span>
                                </div>
                              ) : (
                                searchClientList.map((item: any) => (
                                  <div
                                    style={{ cursor: "pointer", width: "100%" }}
                                    onClick={() => {
                                      onClientSelect(item);
                                    }}
                                  >
                                    <a
                                      href="#"
                                      style={{
                                        color: "black",
                                        width: "100%",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {item.name}
                                    </a>
                                  </div>
                                ))
                              )}
                            </div>
                          )}
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-8">
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4 d-flex align-items-center">
                              <input
                                type="checkbox"
                                value=""
                                checked={selectedDotNumber}
                                onChange={onDotNumberChange}
                                className="show-cursor bundle-checkbox me-2"
                              />
                              <span className="me-2">
                                <b>DOT Number</b>
                              </span>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 d-flex align-items-center">
                              {" "}
                              <input
                                type="checkbox"
                                value=""
                                checked={selectedLicensePlateNumber}
                                onChange={onLicensePlateChange}
                                className="show-cursor bundle-checkbox me-2"
                              />
                              <span className="me-2">
                                <b>License Plate</b>
                              </span>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 d-flex align-items-center">
                              <input
                                type="checkbox"
                                value=""
                                checked={selectedCompanyName}
                                onChange={onCompanyNameChange}
                                className="show-cursor bundle-checkbox me-2"
                              />
                              <span className="me-2">
                                <b>Company Name</b>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 d-flex align-items-center">
                          <div>
                            <AtkTextField
                              type="text"
                              id="text"
                              className="search-area w-auto"
                              name="entervalue"
                              onChange={(e) => {
                                onEnterValueChange(e.target.value);
                              }}
                              placeholder="Enter value"
                              value={valueOfSelectedType}
                            />
                          </div>
                          <div>
                            <AtkButtonIconBefore
                              iconBefore={<AtkIcon icon={Magnifier} />}
                              title="Search vehicle details for entered value"
                              label="Search"
                              className="pf-secondary-btn mx-2"
                              onClick={() => vehicleSearchDetails()}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 d-flex align-items-center">
                          <div className="col-md-6">
                            <label>
                              <b>Current Balance</b>
                            </label>
                          </div>
                          <div className="col-md-6">
                            {currentBalanceLoading ? (
                              <AtkLoader />
                            ) : (
                              <div className="input-group">
                                <span className="input-group-text">$</span>
                                <AtkTextField
                                  className="input-field form-control p-0"
                                  type="text"
                                  id="text"
                                  value={currentBalance}
                                  isDisabled={true}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4">
                          {clientNameError !== "" && (
                            <span className="text-danger text-12">
                              {clientNameError}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-8">
                          <div className="row">
                            {selectedTypeError !== "" && (
                              <span className="text-danger text-12">
                                {selectedTypeError}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 d-flex align-items-center">
                          {valueOfSelectedTypeError !== "" && (
                            <span className="text-danger text-12">
                              {valueOfSelectedTypeError}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6"></div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>

              <div className="row mx-auto">
                {loading ? (
                  <div className="d-flex justify-content-center my-2 client-loader-div">
                    <AtkLoader />
                  </div>
                ) : (
                  <>
                    {hideAllData ? null : (
                      <>
                        {showVehicleData ? (
                          responseVehicleDataList.length !== 0 ? (
                            <>
                              <div className="col-lg-12 col-md-6 col-sm-12 p-0 vehicle-box-height">
                                <div className="row">
                                  {responseVehicleDataList.map(
                                    (item: any, index: any) => (
                                      <div
                                        className="col-lg-4 col-md-12 col-sm-12 mt-3 setting-width-for-media"
                                        key={item.vinNumber}
                                      >
                                        <div className="pf-company-col">
                                          <div className="row mx-auto ">
                                            <Toast ref={toast} />
                                            
                                            <h4 className={item.existingDataInAMSFlag ?  "vehicles-title": "pf-setting-title"}>
                                              <input
                                                type="checkbox"
                                                value=""
                                                checked={item.check}
                                                onChange={() =>
                                                  onSelectDataChanges(
                                                    item,
                                                    index
                                                  )
                                                }
                                                className="show-cursor bundle-checkbox"
                                              />
                                              {item.existingDataInAMSFlag ?  <div className=""><span className="text-13">{"(Data already exist in NowCerts)"}</span></div>:null}
                                            </h4>
                                            <div className="col-4 d-flex align-items-center mt-2">
                                              <label className="form-label">
                                                Vin Number
                                              </label>
                                            </div>

                                            <div className="col-8 mt-2 ">
                                              <AtkTextField
                                                type="text"
                                                id="vin"
                                                className="input-field"
                                                name={item.vinNumber}
                                                value={item.vinNumber}
                                                isDisabled={true}
                                              />
                                            </div>

                                            <div className="col-4 d-flex align-items-center mt-2">
                                              <label className="form-label">
                                                Type
                                              </label>
                                            </div>

                                            <div className="col-8 mt-2 ">
                                              <AtkTextField
                                                type="text"
                                                id="type"
                                                className="input-field"
                                                name={item.vehicleType}
                                                value={item.vehicleType}
                                                isDisabled={true}
                                              />
                                            </div>
                                            <div className="col-4 d-flex align-items-center mt-2">
                                              <label className="form-label">
                                                Make
                                              </label>
                                            </div>

                                            <div className="col-8 mt-2 ">
                                              <AtkTextField
                                                type="text"
                                                id="make"
                                                className="input-field"
                                                name={item.vehicleManufacturer}
                                                value={item.vehicleManufacturer}
                                                isDisabled={true}
                                              />
                                            </div>
                                            <div className="col-12 mt-2"></div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>

                              <div className="col-12 d-flex align-items-center  justify-content-end my-3">
                                {submitLoading ? (
                                  <div className="d-flex justify-content-center my-2 client-loader-div">
                                    <AtkLoader />
                                  </div>
                                ) : (
                                  <AtkButton
                                    label="Update in NowCerts"
                                    className="pf-secondary-btn w-auto me-2"
                                    onClick={() => {
                                      onSubmitValidation();
                                    }}
                                  />
                                )}
                              </div>
                            </>
                          ) : (
                            <div className="row mt-5">
                              <div className="col-md-4"></div>
                              <div className="col-md-4">
                                <AtkMessage messageText="No vehicle prefill data available." />
                              </div>
                              <div className="col-md-4"></div>
                            </div>
                          )
                        ) : null}
                      </>
                    )}
                  </>
                )}

                {showRateLimitPopMessage ? (
                  <>
                    <Dialog
                      visible={showRateLimitPopMessage}
                      position={"center"}
                      draggable={false}
                      resizable={false}
                      onHide={() => {
                        setShowRateLimitPopMessage(false);
                        setHideAllData(true);
                      }}
                    >
                      <AtkMessage
                        appearance="information"
                        messageText={
                          "User is only allowed to update one Prefill request per minute."
                        }
                      />
                    </Dialog>{" "}
                  </>
                ) : null}

                {showConfirmationBox ? (
                  <Dialog
                    visible={showConfirmationBox}
                    position={"center"}
                    draggable={false}
                    resizable={false}
                    closable={false}
                    onHide={() => {
                      setShowConfirmationBox(false);
                    }}
                  >
                    <p>
                      <span className="d-block text-13 mt-4">
                        <p className="text-center">
                          <b>
                            If selected data already exists in NowCerts AMS,
                            then it will be overrided.
                          </b>
                        </p>
                        <p className="text-center">
                          <b>Are you sure you want to proceed ?</b>
                        </p>
                      </span>
                    </p>
                    <div className="text-center mt-4">
                      {onSubmitLoader ? (
                        <>
                          <AtkLoader />
                        </>
                      ) : (
                        <AtkButton
                          label="OK"
                          onClick={() => {
                            onSubmitClick();
                          }}
                          className="pf-secondary-btn w-auto me-2"
                        />
                      )}
                      <AtkButton
                        label="Cancel"
                        onClick={() => {
                          oncancelClicks();
                        }}
                        className="pf-primary-btn w-auto"
                      />
                    </div>
                  </Dialog>
                ) : null}
                {showVehicleHistory ? <VehicleHistory /> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
