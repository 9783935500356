import SectionMessage from "@atlaskit/section-message";
import TextField from "@atlaskit/textfield";
import Toggle from "@atlaskit/toggle";
import axios from "axios";
import moment from "moment";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AtkButton from "../../../AtlaskitControls/AtkButton";
import AtkButtonIconBefore from "../../../AtlaskitControls/AtkButtonIconBefore";
import AtkLoader from "../../../AtlaskitControls/AtkLoader";
import AtkMessage from "../../../AtlaskitControls/AtkMessage";
import AtkTextField from "../../../AtlaskitControls/AtkTextField";
import PfdIcon from "../../../assets/images/file.png";
import PreviewIcon from "../../../assets/images/paper.png";
import RemoveIcon from "../../../assets/images/trashcan.png";
import CommonValues from "../../../common/utils";

export default function NewSmartScan(props: any) {
  const navigate = useNavigate();
  const [selectedtab, setSelectedTab] = useState(1);
  const [showHistory, setShowHistory] = useState(false);
  const [documentType, setDocumentType] = useState("");
  const [uploadFile, setUploadFile] = useState<any>([]);
  const [uploadFileLimit, setUploadFileLimit] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [filesCount, setFilesCount] = useState(0);
  const [requestFileCount, setRequestFileCount] = useState("");
  const [uploadingFiles, setUploadingFiles] = useState(false);
  const [uploadingRetry, setUploadingRetry] = useState(false);
  const [incompleteRequestData, setIncompleteRequestData] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState("");
  const [isfileuploaded, setIsfileuploaded] = useState(false);
  const [fileNameTitle, setFileNameTitle] = useState("");
  const [fileName, setFileName] = useState("");
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [uploadPdfUrl, setUploadPdfUrl] = useState("");
  const [errorMessageDocumentType, setErrorMessageDocumentType] = useState("");
  const [pdfUploadLoading, setPDFUploadLoading] = useState(false);
  const [showHideCancel, setShowHideCancel] = useState(false);
  const [selectedAMS, setSelectedAMS] = useState<any>();
  const [amsSettingError, setAMSSettingError] = useState("");
  const [activeTab, setActiveTab] = useState("Insured");
  const [allCarrierNames, setallCarrierNames] = useState<string[]>([]);

  const [insuredFirstName, setInsuredFirstName] = useState("");
  const [insuredMiddleName, setInsuredMiddelName] = useState("");
  const [insuredLastName, setInsuredLastName] = useState("");
  const [insuredEmail, setInsuredEmail] = useState("");
  const [insuredPhoneNumber, setInsuredPhoneNumber] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessDba, setBusinessDba] = useState("");
  const [businessFein, setBusinessFein] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessWebsite, setBusinessWebsite] = useState("");
  const [mailingAddressLine1, setMailingAddressLine1] = useState("");
  const [mailingCity, setMailingCity] = useState("");
  const [mailingState, setMailingState] = useState("");
  const [mailingZipCode, setMailingZipCode] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [policyPremium, setPolicyPremium] = useState("");
  const [carrierName, setCarrierName] = useState("Houston Casualty Company");
  const [lineOfBusiness, setLineOfBusiness] = useState("");
  const [bindDate, setBindDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [effectiveDate, setEffectiveDate] = useState("");
  const [quoteNumber, setQuoteNumber] = useState("");
  const [sicCode, setSicCode] = useState("");
  const [eachAccident, setEachAccident] = useState("");
  const [diseasePolicyLimit, setDiseasePolicyLimit] = useState("");
  const [diseaseEachEmployee, setDiseaseEachEmployee] = useState("");

  const [insuredFirstNameError, setInsuredFirstNameError] = useState("");
  const [insuredLastNameError, setInsuredLastNameError] = useState("");
  const [insuredEmailError, setInsuredEmailError] = useState("");
  const [insuredPhoneNumberError, setInsuredPhoneNumberError] = useState("");
  const [businessNameError, setBusinessNameError] = useState("");
  const [businessDbaError, setBusinessDbaError] = useState("");
  const [businessFeinError, setBusinessFeinError] = useState("");
  const [businessEmailError, setBusinessEmailError] = useState("");
  const [mailingAddressLine1Error, setmailingAddressLine1Error] = useState("");
  const [mailingCityError, setMailingCityError] = useState("");
  const [mailingStateError, setMailingStateError] = useState("");
  const [mailingZipCodeError, setMailingZipCodeError] = useState("");
  const [policyNumberError, setPolicyNumberError] = useState("");
  const [policyPremiumError, setPolicyPremiumError] = useState("");
  const [carrierNameError, setCarrierNameError] = useState("");
  const [lineOfBusinessError, setLineOfBusinessError] = useState("");
  const [quoteNumberError, setQuoteNumberError] = useState("");
  const [effectiveDateError, setEffectiveDateError] = useState("");
  const [expiryDateError, setExpiryDateError] = useState("");

  const [carrierNameLoading, setcarrierNameLoading] = useState(false);
  const [hideSubmitButton, setHideSubmitButton] = useState(true);
  const [onSubmitLoader, setOnSubmitLoader] = useState(false);
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [createInsured, setCreateInsured] = useState(false);
  const [createQuote, setCreateQuote] = useState(false);
  const [ncURL, setNcURL] = useState("");
  const [toggleSelectErrorMsg, setToggleSelectErrorMsg] = useState("");
  const [createProspectLoading, setCreateProspectLoading] = useState(false);
  const [createInsuredSuccessMsg, setCreateInsuredSuccessMsg] = useState("");
  const [createInsuredErrorMsg, setCreateInsuredErrorMsg] = useState("");
  const [createQuoteErrorMsg, setCreateQuoteErrorMsg] = useState("");
  const [workersCompensationSuccess, setWorkersCompensationSuccess] =
    useState("");
  const [submissionId, setSubmissionId] = useState("");
  const [smartScanHistoryDataLoading, setSmartScanHistoryDataLoading] =
    useState(false);

  let options1 = [
    { value: "Acord125", label: "Acord 125" },
    { value: "Acord126", label: "Acord 126" },
    { value: "Acord130", label: "Acord 130" },
    { value: "DecPage", label: "Dec Page" },
  ];

  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }

    if (props.isSubmissionIdFromHistory) {
      setSmartScanHistoryDataLoading(true);
      getExistingSmartScanData(props.submissionIdFromHistory);
    }
  }, [props.submissionIdFromHistory]);

  const OnChangeDocumentType = (value: any) => {
    if (value == "Select Document Type") {
      setDocumentType("");
    } else {
      setDocumentType(value);
    }
    setErrorMessageDocumentType("");
  };

  const validateSelectedFiles = (files: any) => {
    var regularExpression = /(?:\.([^.]+))?$/;
    var isValid = true;

    for (let index = 0, len = files.length; index < len; index++) {
      const fileExtension: any = regularExpression.exec(files[index].name);
      if (fileExtension === undefined || fileExtension[0] == "") {
        isValid = false;
        break;
      }
      if (fileExtension?.[1].toLowerCase().includes("pdf")) {
        isValid = true;
      } else {
        isValid = false;
        break;
      }
    }
    return isValid;
  };

  const onFileSelectHandler = (files: any) => {
    setFileName("");
    setFileNameTitle("");
    const isValidated = validateSelectedFiles(files);
    if (!isValidated) {
      setErrorMessage("Please select PDF files only");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return false;
    }

    let fileList = [];
    let totalSize = 0;
    let currentFiles = uploadFile;
    let filename = files[0].name;
    if (filename.length > 50) {
      let shortenString = filename.substring(0, 50).concat("...");
      setFileName(shortenString);
      setFileNameTitle(filename);
    } else {
      setFileName(filename);
      setFileNameTitle(filename);
    }
    for (let i = 0, len = files.length; i < len; i++) {
      fileList.push({
        file: files[i],
        name: files[i].name,
        size: files[i].size,
      });
      totalSize = totalSize + files[i].size;
    }

    if (fileList.length > 0) {
      if (currentFiles.length > 0) {
        for (var i = 0, l = currentFiles.length; i < l; i++) {
          for (var j = 0; j < fileList.length; j++) {
            if (currentFiles[i].name === fileList[j].name) {
              fileList.splice(j, 1);
            }
          }
        }
      }
      let allFiles = currentFiles.concat(fileList);
      if (allFiles.length > uploadFileLimit) {
        allFiles = allFiles.slice(0, uploadFileLimit);
        setErrorMessage(
          "Multiple files cannot be uploaded at the same time; please upload a single file"
        );
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
      setUploadFile(allFiles);
      setFilesCount(allFiles.length);
      setRequestFileCount(allFiles.length);
      setFilesCount(allFiles.length);
    }
  };

  const removeFileFromList = () => {
    setUploadFile([]);
    setIsfileuploaded(false);
    setSuccessMessage("");
  };

  const getExistingSmartScanData = (submissionIdFromHistory: string) => {
    let token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/smartscandata/${submissionIdFromHistory}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response: any) => {
        setInsuredFirstName(response.data.firstName);
        setInsuredLastName(response.data.lastName);
        setInsuredEmail(response.data.email);
        setInsuredPhoneNumber(response.data.phone);
        setBusinessName(response.data.name);
        setBusinessDba(response.data.dba);
        setBusinessFein(response.data.fein);
        setBusinessEmail(response.data.businessEmail);
        setMailingAddressLine1(response.data.addressLine1);
        setMailingCity(response.data.city);
        setMailingState(response.data.state);
        setMailingZipCode(response.data.zipCode);
        setPolicyNumber(response.data.policyNumber);
        setPolicyPremium(response.data.policyPremium);
        getNCCarrierList(response.data.carrierName);
        setLineOfBusiness(response.data.lineOfBusiness);
        setQuoteNumber(response.data.quoteNumber);
        setEffectiveDate(response.data.effectiveDate);
        setExpiryDate(response.data.proposedExpDate);
        setUploadPdfUrl(response.data.fileUrl);
        setSmartScanHistoryDataLoading(false);
      })
      .catch((error: any) => {
        setSmartScanHistoryDataLoading(false);
        if (error.response != null && error.response.status == 401) {
          CommonValues.Logout(navigate);
        } else {
          setErrorMessage("Error while getting smart scan data from history.");
          setTimeout(() => {
            setErrorMessage("");
          }, 3000);
        }
      });
  };

  const createUploadRequest = () => {
    setLoading(true);

    if (uploadFile.length == 0) {
      setTimeout(() => {
        setErrorMessage("Please select at least one file to upload");
      }, 3000);
      setLoading(false);
      return;
    }
    if (documentType.length == 0) {
      setErrorMessageDocumentType("Please select document type");
      setLoading(false);

      return;
    }
    const FormData = require("form-data");
    const data = new FormData();
    data.append("file", uploadFile[0].file);
    data.append("documentType", documentType);
    let token = CommonValues.GetToken();
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/nowcertssmartscan`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        if (response.status === 200) {
          setIsFileUpload(true);
          setPDFUploadLoading(true);
          setShowHideCancel(true);
          let documentType = response.data.documentType;
          setSubmissionId(response.data.submissionId);
          if (documentType == "Acord125") {
            bindAccord125Model(response.data);
          } else if (documentType == "Acord130") {
            binAccord130Model(response.data);
          } else if (documentType == "Acord126") {
            bindAccord126Model(response.data);
          } else if (documentType == "DecPage") {
            bindDecPageModel(response.data);
          }
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        } else {
          setErrorMessage("Something went wrong.");
          setLoading(false);
        }
      })
      .catch((error: any) => {
        localStorage.removeItem("currentPage");
        if (error.response != null && error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else {
          setErrorMessage("Error while creating the request.");
          setTimeout(() => {
            setErrorMessage("");
          }, 3000);
          setLoading(false);
        }
      });
  };

  const bindAccord125Model = (uploadFileResponse: any) => {
    let incomingState = uploadFileResponse.state;
    let state: any =
      Allstate.find((State) => State.code === incomingState) ||
      Allstate.find((State) => State.name === incomingState);
    if (
      uploadFileResponse.fileUrl != "" &&
      uploadFileResponse.fileUrl != null
    ) {
      setUploadPdfUrl(uploadFileResponse.fileUrl);
      setTimeout(() => {
        setPDFUploadLoading(false);
      }, 3000);
    }
    if (uploadFileResponse.name != "" && uploadFileResponse.name != null) {
      setBusinessName(uploadFileResponse.name);
      setBusinessNameError("");
    }
    if (
      uploadFileResponse.firstName != "" &&
      uploadFileResponse.firstName != null
    ) {
      setInsuredFirstName(uploadFileResponse.firstName);
      setInsuredFirstNameError("");
    }
    if (
      uploadFileResponse.lastName != "" &&
      uploadFileResponse.lastName != null
    ) {
      setInsuredLastName(uploadFileResponse.lastName);
      setInsuredLastNameError("");
    }
    if (uploadFileResponse.phone != "" && uploadFileResponse.phone != null) {
      let phoneNumber = uploadFileResponse.phone.replace(/ /g, "");
      phoneNumber = formatPhoneNumber(phoneNumber);
      setInsuredPhoneNumber(phoneNumber);
      setInsuredPhoneNumberError("");
    }
    setInsuredEmail(
      uploadFileResponse.email
        ? uploadFileResponse.email
        : uploadFileResponse.businessEmail
    );
    setInsuredEmailError("");
    if (uploadFileResponse.dba != "" && uploadFileResponse.dba != null) {
      setBusinessDba(uploadFileResponse.dba);
      setBusinessDbaError("");
    }
    if (uploadFileResponse.fein != "" && uploadFileResponse.fein != null) {
      setBusinessFein(uploadFileResponse.fein);
    }
    if (
      uploadFileResponse.website != "" &&
      uploadFileResponse.website != null
    ) {
      setBusinessWebsite(uploadFileResponse.website);
    }

    setBusinessEmail(
      uploadFileResponse.email
        ? uploadFileResponse.email
        : uploadFileResponse.businessEmail
    );
    setBusinessEmailError("");
    if (
      uploadFileResponse.addressLine1 != "" &&
      uploadFileResponse.addressLine1 != null
    ) {
      setMailingAddressLine1(uploadFileResponse.addressLine1);
      setmailingAddressLine1Error("");
    }
    if (uploadFileResponse.city != "" && uploadFileResponse.city != null) {
      setMailingCity(uploadFileResponse.city.replace(",", ""));
      setMailingCityError("");
    }
    if (state != null && state != undefined && state != "") {
      setMailingState(state.code ? state.code : "");
      setMailingStateError("");
    }
    if (
      uploadFileResponse.zipCode != "" &&
      uploadFileResponse.zipCode != null
    ) {
      setMailingZipCode(uploadFileResponse.zipCode);
      setMailingZipCodeError("");
    }
    if (
      uploadFileResponse.policyNumber != "" &&
      uploadFileResponse.policyNumber != null
    ) {
      setPolicyNumber(uploadFileResponse.policyNumber);
      setPolicyNumberError("");
    }

    if (
      uploadFileResponse.policyPremium != "" &&
      uploadFileResponse.policyPremium != null
    ) {
      setPolicyPremium(uploadFileResponse.policyPremium.replace(/,/g, ""));
      setPolicyPremiumError("");
    } else {
      setPolicyPremium("0");
      setPolicyPremiumError("");
    }
    // if (uploadFileResponse.carrierName != "" && uploadFileResponse.carrierName != null) {
    //     setCarrierName(uploadFileResponse.carrierName);
    // }
    getNCCarrierList(uploadFileResponse.carrierName);
    if (
      uploadFileResponse.effectiveDate != "" &&
      uploadFileResponse.effectiveDate != null
    ) {
      setEffectiveDate(uploadFileResponse.effectiveDate);
      setEffectiveDateError("");
    }
    if (
      uploadFileResponse.proposedExpDate != "" &&
      uploadFileResponse.proposedExpDate != null
    ) {
      setExpiryDate(uploadFileResponse.proposedExpDate);
      setExpiryDateError("");
    }
    if (
      uploadFileResponse.lineOfBusiness != "" &&
      uploadFileResponse.lineOfBusiness != null
    ) {
      setLineOfBusiness(uploadFileResponse.lineOfBusiness);
      setLineOfBusinessError("");
    }
  };
  const bindAccord126Model = (uploadFileResponse: any) => {
    let incomingState = uploadFileResponse.state;
    let state: any =
      Allstate.find((State) => State.code === incomingState) ||
      Allstate.find((State) => State.name === incomingState);
    if (
      uploadFileResponse.fileUrl != "" &&
      uploadFileResponse.fileUrl != null
    ) {
      setUploadPdfUrl(uploadFileResponse.fileUrl);
    }
    if (
      uploadFileResponse.applicantName != "" &&
      uploadFileResponse.applicantName != null
    ) {
      setBusinessName(uploadFileResponse.applicantName);
      setBusinessNameError("");
    }
    if (
      uploadFileResponse.effectiveDate != "" &&
      uploadFileResponse.effectiveDate != null
    ) {
      setEffectiveDate(uploadFileResponse.effectiveDate);
      setEffectiveDateError("");
    }
    if (
      uploadFileResponse.expiryDate != "" &&
      uploadFileResponse.expiryDate != null
    ) {
      setExpiryDate(uploadFileResponse.expiryDate);
      setExpiryDateError("");
    }
    if (
      uploadFileResponse.premium != "" &&
      uploadFileResponse.premium != null
    ) {
      setPolicyPremium(
        uploadFileResponse.premium
          .replace(/[,$]/g, "")
          .replace(/\.00$/, "")
          .trim()
      );
      setPolicyPremiumError("");
    } else {
      setPolicyPremium("0");
      setPolicyPremiumError("");
    }
    setLineOfBusiness("General Liability ");
    getNCCarrierList(carrierName);
  };
  const binAccord130Model = (uploadFileResponse: any) => {
    let incomingState = uploadFileResponse.mailingAddress.state;
    let state: any =
      Allstate.find((State) => State.code === incomingState) ||
      Allstate.find((State) => State.name === incomingState);
    if (
      uploadFileResponse.fileUrl != "" &&
      uploadFileResponse.fileUrl != null
    ) {
      setUploadPdfUrl(uploadFileResponse.fileUrl);
    }
    if (
      uploadFileResponse.applicantName != "" &&
      uploadFileResponse.applicantName != null
    ) {
      setBusinessName(uploadFileResponse.applicantName);
      setBusinessNameError("");
    }
    if (
      uploadFileResponse.lastName != "" &&
      uploadFileResponse.lastName != null
    ) {
      setInsuredLastName(uploadFileResponse.lastName);
      setInsuredLastNameError("");
    }
    if (
      uploadFileResponse.contactInformation[0].name != "" &&
      uploadFileResponse.contactInformation[0].name != null
    ) {
      let name = uploadFileResponse.contactInformation[0].name.split(" ");
      setInsuredFirstName(name[0]);
      setInsuredFirstNameError("");
      setInsuredLastName(name[1]);
      setInsuredLastNameError("");
    }
    if (
      uploadFileResponse.contactInformation[0].mobilePhone != "" &&
      uploadFileResponse.contactInformation[0].mobilePhone != null
    ) {
      let phoneNumber =
        uploadFileResponse.contactInformation[0].mobilePhone.replace(/ /g, "");
      phoneNumber = formatPhoneNumber(phoneNumber);
      setInsuredPhoneNumber(phoneNumber);
      setInsuredPhoneNumberError("");
    } else {
      if (
        uploadFileResponse.contactInformation[0].officePhone != "" &&
        uploadFileResponse.contactInformation[0].officePhone != null
      ) {
        let phoneNumber =
          uploadFileResponse.contactInformation[0].officePhone.replace(
            / /g,
            ""
          );
        phoneNumber = formatPhoneNumber(phoneNumber);
        setInsuredPhoneNumber(phoneNumber);
        setInsuredPhoneNumberError("");
      }
    }
    if (
      uploadFileResponse.mailingAddress.addressLine1 != "" &&
      uploadFileResponse.mailingAddress.addressLine1 != null
    ) {
      setMailingAddressLine1(uploadFileResponse.mailingAddress.addressLine1);
      setmailingAddressLine1Error("");
    }
    if (
      uploadFileResponse.mailingAddress.city != "" &&
      uploadFileResponse.mailingAddress.city != null
    ) {
      setMailingCity(uploadFileResponse.mailingAddress.city);
      setMailingCityError("");
    }
    if (state != null && state != undefined && state != "") {
      setMailingState(state.code ? state.code : "");
      setMailingStateError("");
    }
    if (
      uploadFileResponse.mailingAddress.zipCode != "" &&
      uploadFileResponse.mailingAddress.zipCode != null
    ) {
      setMailingZipCode(uploadFileResponse.mailingAddress.zipCode);
      setMailingZipCodeError("");
    }
    if (
      uploadFileResponse.policyEffectiveDate != "" &&
      uploadFileResponse.policyEffectiveDate != null
    ) {
      setEffectiveDate(uploadFileResponse.policyEffectiveDate);
      setEffectiveDateError("");
      setExpiryDateError("");
    }
    if (
      uploadFileResponse.policyExpiryDate != "" &&
      uploadFileResponse.policyExpiryDate != null
    ) {
      setExpiryDate(uploadFileResponse.policyExpiryDate);
    }
    if (
      uploadFileResponse.SicCode != "" &&
      uploadFileResponse.SicCode != null
    ) {
      setSicCode(uploadFileResponse.SicCode);
    }
    if (
      uploadFileResponse.applicantEmail != "" &&
      uploadFileResponse.applicantEmail != null
    ) {
      setInsuredEmail(uploadFileResponse.applicantEmail);
      setInsuredEmailError("");
      setBusinessEmail(uploadFileResponse.applicantEmail);
      setBusinessEmailError("");
    } else {
      setInsuredEmail(uploadFileResponse.contactInformation[0].email);
      setBusinessEmail(uploadFileResponse.contactInformation[0].email);
      setInsuredEmailError("");
      setBusinessEmailError("");
    }
    if (
      uploadFileResponse.part2_EmployersLiability.eachAccident != "" &&
      uploadFileResponse.part2_EmployersLiability.eachAccident != null
    ) {
      setEachAccident(uploadFileResponse.part2_EmployersLiability.eachAccident);
    }
    if (
      uploadFileResponse.part2_EmployersLiability.diseasePolicyLimit != "" &&
      uploadFileResponse.part2_EmployersLiability.diseasePolicyLimit != null
    ) {
      setDiseasePolicyLimit(
        uploadFileResponse.part2_EmployersLiability.diseasePolicyLimit
      );
    }
    if (
      uploadFileResponse.part2_EmployersLiability.diseaseEachEmployee != "" &&
      uploadFileResponse.part2_EmployersLiability.diseaseEachEmployee != null
    ) {
      setDiseaseEachEmployee(
        uploadFileResponse.part2_EmployersLiability.diseaseEachEmployee
      );
    }
    setLineOfBusiness("Worker's Compensation");
    setPolicyPremium("0");
    getNCCarrierList(carrierName);
  };
  const bindDecPageModel = (uploadFileResponse: any) => {
    let incomingState = uploadFileResponse.state.replace(",", "");
    let state: any =
      Allstate.find((State) => State.code === incomingState) ||
      Allstate.find((State) => State.name === incomingState);
    if (
      uploadFileResponse.fileUrl != "" &&
      uploadFileResponse.fileUrl != null
    ) {
      setUploadPdfUrl(uploadFileResponse.fileUrl);
    }
    if (uploadFileResponse.firstName != "") {
      setInsuredFirstName(
        uploadFileResponse.firstName == null
          ? uploadFileResponse.businessName.replace(",", "")
          : uploadFileResponse.firstName.replace("-", "")
      );
      setInsuredFirstNameError("");
    }
    if (
      uploadFileResponse.lastName != "" &&
      uploadFileResponse.lastName != null
    ) {
      setInsuredLastName(uploadFileResponse.lastName.replace(",", ""));
      setInsuredLastNameError("");
    }
    if (uploadFileResponse.phone != "" && uploadFileResponse.phone != null) {
      let phoneNumber = uploadFileResponse.phone.replace(/ /g, "");
      phoneNumber = formatPhoneNumber(phoneNumber);
      setInsuredPhoneNumber(phoneNumber);
      setInsuredPhoneNumberError("");
    }
    setInsuredEmail(
      uploadFileResponse.email
        ? uploadFileResponse.email
        : uploadFileResponse.businessEmail
    );
    if (uploadFileResponse.dba != "" && uploadFileResponse.dba != null) {
      setBusinessDba(uploadFileResponse.dba);
    }
    if (uploadFileResponse.fein != "" && uploadFileResponse.fein != null) {
      setBusinessFein(uploadFileResponse.fein);
    }
    if (
      uploadFileResponse.website != "" &&
      uploadFileResponse.website != null
    ) {
      setBusinessWebsite(uploadFileResponse.website);
    }
    setBusinessEmail(
      uploadFileResponse.email
        ? uploadFileResponse.email
        : uploadFileResponse.businessEmail
    );
    if (
      uploadFileResponse.addressLine1 != "" &&
      uploadFileResponse.addressLine1 != null
    ) {
      setMailingAddressLine1(uploadFileResponse.addressLine1.replace(",", ""));
      setmailingAddressLine1Error("");
    }
    if (uploadFileResponse.city != "" && uploadFileResponse.city != null) {
      setMailingCity(uploadFileResponse.city.replace(",", ""));
      setMailingCityError("");
    }
    if (state != null && state != undefined && state != "") {
      setMailingState(state.code ? state.code : "");
      setMailingStateError("");
    }
    if (
      uploadFileResponse.zipCode != "" &&
      uploadFileResponse.zipCode != null
    ) {
      setMailingZipCode(uploadFileResponse.zipCode.replace(",", ""));
      setMailingZipCodeError("");
    }
    if (
      uploadFileResponse.policyNumber != "" &&
      uploadFileResponse.policyNumber != null
    ) {
      setPolicyNumber(uploadFileResponse.policyNumber.replace(/\s/g, ""));
      setPolicyNumberError("");
    }
    if (
      uploadFileResponse.quoteNumber != "" &&
      uploadFileResponse.quoteNumber != null
    ) {
      setQuoteNumber(uploadFileResponse.quoteNumber.replace(/\s/g, ""));
      setQuoteNumberError("");
    } else {
      if (
        uploadFileResponse.policyNumber != "" &&
        uploadFileResponse.policyNumber != null
      ) {
        setQuoteNumber(uploadFileResponse.policyNumber.replace(/\s/g, ""));
        setQuoteNumberError("");
      }
    }
    if (
      uploadFileResponse.policyPremium != "" &&
      uploadFileResponse.policyPremium != null
    ) {
      setPolicyPremium(uploadFileResponse.policyPremium.replace(/[,$]/g, ""));
      setPolicyPremiumError("");
    } else {
      setPolicyPremium("0");
      setPolicyPremiumError("");
    }
    if (
      uploadFileResponse.effectiveDate != "" &&
      uploadFileResponse.effectiveDate != null
    ) {
      setEffectiveDate(uploadFileResponse.effectiveDate);
      setEffectiveDateError("");
    }
    if (
      uploadFileResponse.proposedExpDate != "" &&
      uploadFileResponse.proposedExpDate != null
    ) {
      setExpiryDate(uploadFileResponse.proposedExpDate);
      setExpiryDateError("");
    }
    if (
      uploadFileResponse.lineOfBusinessName != "" &&
      uploadFileResponse.lineOfBusinessName != null
    ) {
      setLineOfBusiness(uploadFileResponse.lineOfBusinessName.replace(",", ""));
      setLineOfBusinessError("");
    }
    if (
      uploadFileResponse.businessName != "" &&
      uploadFileResponse.businessName != null
    ) {
      setBusinessName(uploadFileResponse.businessName.replace(",", ""));
      setBusinessNameError("");
    }
    if (uploadFileResponse.fein != "" && uploadFileResponse.fein != null) {
      setBusinessFein(uploadFileResponse.fein);
    }
    getNCCarrierList(uploadFileResponse.carrierName);
  };

  const getNCCarrierList = (carrierName: any) => {
    let nowCertsToken = localStorage.getItem("NCToken")
      ? localStorage.getItem("NCToken")
      : "";
    setcarrierNameLoading(true);
    let token = CommonValues.GetToken();
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/NowCert/getnowcertcarrierlist?carrierName=${carrierName}&ncToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response: any) => {
        setallCarrierNames(response.data.allCarrierNames);
        if (
          response.data.matchedCarrierName != "" &&
          response.data.matchedCarrierName != "null" &&
          response.data.matchedCarrierName != undefined
        ) {
          let matchingcarriername: any = response.data.matchedCarrierName;
          if (matchingcarriername !== undefined) {
            if (!allCarrierNames.includes(matchingcarriername)) {
              setallCarrierNames((allCarrierNames) => [
                ...allCarrierNames,
                matchingcarriername,
              ]);
            }
          }
          setCarrierName(matchingcarriername);
        }
        setcarrierNameLoading(false);
      })
      .catch((error: any) => {
        setcarrierNameLoading(false);
        console.log(error);
        if (error.response != null && error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else {
          setErrorMessage("Error while getting the request.");
          setTimeout(() => {
            setErrorMessage("");
          }, 3000);
        }
      });
  };

  const onDropHandler = (event: any) => {
    event.preventDefault();
    onFileSelectHandler(event.dataTransfer.files);
    setShowHideCancel(true);
  };

  const handleTabClick = (tabName: any) => {
    setActiveTab(tabName);
  };

  const onChangeInsuredFirstName = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const Value = inputValue.slice(0, 255);
    setInsuredFirstName(Value);
  };

  const onChangeInsuredMiddleName = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const Value = inputValue.slice(0, 255);
    setInsuredMiddelName(Value);
  };

  const onChangeInsuredLastName = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const Value = inputValue.slice(0, 255);
    setInsuredLastName(Value);
    setInsuredLastNameError("");
  };

  const onChangeBusinessDba = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const Value = inputValue.slice(0, 255);
    setBusinessDba(Value);
  };

  const onChangeInsuredEmail = (value: any) => {
    const Value = value.slice(0, 255);
    setInsuredEmail(Value);
    setInsuredEmailError("");
  };

  const onChangeBusinessFein = (value: any) => {
    const Value = /^[+-]?\d*(?:[.,]\d*)?$/;
    if (Value.test(value)) {
      if (value.length > 9) {
        return;
      }
      setBusinessFein(value);
    }
  };

  const onChangeBusinessEmail = (value: any) => {
    const Value = value.slice(0, 255);
    setBusinessEmail(Value);
    setBusinessEmailError("");
  };

  const onChangeAgencyMailingAddress1 = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
    const Value = inputValue.slice(0, 255);
    setMailingAddressLine1(Value);
    setmailingAddressLine1Error("");
  };

  const onChangeAgencyMailingCity = (value: any) => {
    const Value = value.slice(0, 255);
    setMailingCity(Value);
    setMailingCityError("");
  };

  const onChangeAgencyMailingState = (value: any) => {
    setMailingState(value);
    setMailingStateError("");
  };

  const onChangeAgencyMailingZip = (value: any) => {
    const re = /^[0-9\b]+$/;
    if (value === "" || re.test(value)) {
      if (value.length > 5) {
        return;
      }
      setMailingZipCode(value);
      setMailingZipCodeError("");
    }
  };

  const onChangePolicyNumber = (value: any) => {
    const Value = value.slice(0, 255);
    setPolicyNumber(Value);
    setPolicyNumberError("");
  };

  const onChangePolicyPremium = (value: any) => {
    const regex = /^[0-9]*(\.[0-9]{0,2})?$/;
    const numericValue = regex.test(value);
    if (numericValue) {
      setPolicyPremium(value);
      setPolicyPremiumError("");
    }
  };

  const onChangeCarrierName = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const formattedValue = inputValue.slice(0, 255);
    if (value == "") {
      setCarrierName("");
    } else {
      setCarrierName(formattedValue);
      setCarrierNameError("");
    }
  };

  const onChangeLineOfBusiness = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const Value = inputValue.slice(0, 255);
    setLineOfBusiness(Value);
    setLineOfBusinessError("");
  };

  const onChangeQuoteNumber = (value: any) => {
    const Value = value.slice(0, 255);
    setQuoteNumber(Value);
    setQuoteNumberError("");
  };

  const onChangeEffectiveDate = (value: any) => {
    setEffectiveDate(value);
    setEffectiveDateError("");
  };

  const onChangeExpiryDate = (value: any) => {
    setExpiryDate(value);
    setExpiryDateError("");
  };
  const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const currentValue = event.currentTarget.value;
    const formattedValue = formatPhoneNumber(currentValue);
    setInsuredPhoneNumber(formattedValue);
    setInsuredPhoneNumberError("");
  };

  const onChangeBusinessName = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const Value = inputValue.slice(0, 255);
    setBusinessName(Value);
    setBusinessNameError("");
  };

  const formatPhoneNumber = (phoneNumber: string) => {
    const numericValue = phoneNumber.replace(/\D/g, "");
    const phoneNumberLength = numericValue.length;
    if (phoneNumberLength <= 3) {
      return numericValue;
    } else if (phoneNumberLength <= 6) {
      return `(${numericValue.slice(0, 3)})${numericValue.slice(3)}`;
    } else {
      return `(${numericValue.slice(0, 3)})${numericValue.slice(
        3,
        6
      )}-${numericValue.slice(6, 10)}`;
    }
  };

  const handleCreateInsuredToggle = () => {
    setHideSubmitButton(true);
    setCreateInsured(!createInsured);
  };
  const handleCreatePolicyToggle = () => {
    setCreateQuote(!createQuote);
    if (!createInsured) {
      setHideSubmitButton(true);
      setCreateInsured(true);
    }
  };

  const onSubmitClick = async () => {
    setOnSubmitLoader(true);
    setShowHideCancel(false);
    if (createInsured !== false && createQuote == false) {
      createNewInsured();
      updateSmartScan();
    } else if (createQuote !== false && createInsured == false) {
      //CreateNewPolicy();
    } else if (createInsured !== false && createQuote !== false) {
      const insuredDatabaseId: any = await createNewInsured();
      setTimeout(() => insertQuote(insuredDatabaseId));
      updateSmartScan();
    } else if (createInsured != true && createQuote != true) {
      setToggleSelectErrorMsg(
        "Select any one of the process Create Prospect / Create Quote"
      );
      setTimeout(() => setToggleSelectErrorMsg(""), 6000);
      setOnSubmitLoader(false);
      setShowConfirmBox(false);
      setShowHideCancel(true);
    }
  };

  const createNewInsured = async () => {
    try {
      setCreateProspectLoading(true);
      setNcURL("");
      var token = CommonValues.GetToken();
      var ncToken = CommonValues.GetNCToken();
      var data = JSON.stringify({
        commercialName: businessName,
        firstName: insuredFirstName,
        lastName: insuredLastName,
        dba: businessDba,
        addressLine1: mailingAddressLine1,
        addressLine2: "",
        state: mailingState,
        city: mailingCity,
        zipCode: mailingZipCode,
        eMail: insuredEmail,
        cellPhone: insuredPhoneNumber,
        fein: businessFein,
      });
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/createnewprospect?nowCertsToken=${ncToken}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios(config);
      let insuredInfo = response.data.insuredDatabaseId;
      setNcURL(response.data.nowCertsUrlToLaunch);
      window.scrollTo(0, 1500);

      setCreateInsuredSuccessMsg(
        "Prospect has been created successfully in NowCerts"
      );
      setTimeout(() => setCreateInsuredSuccessMsg(""), 2000);

      setOnSubmitLoader(false);
      setHideSubmitButton(false);
      setCreateProspectLoading(false);
      setShowConfirmBox(false);
      return insuredInfo;
    } catch (error: any) {
      console.log(error);
      let errorMessage = "";
      if (error.response != null) {
        if (error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else if (error.response.status == 400) {
          errorMessage = error.response.errors;
        } else {
          errorMessage = "Please correct NowCerts username and password";
        }
      } else {
        errorMessage =
          "Unknown error while creating insured inside the NowCerts";
      }
      setOnSubmitLoader(false);
      setShowConfirmBox(false);
      setCreateInsuredErrorMsg(errorMessage);
      setTimeout(() => setCreateInsuredErrorMsg(""), 3000);
      setCreateProspectLoading(false);
      let insuredInfo = "";
      return insuredInfo;
    }
  };

  const insertQuote = (insuredDatabaseId: any) => {
    let token = CommonValues.GetToken();
    let nowCertsToken = localStorage.getItem("NCToken")
      ? localStorage.getItem("NCToken")
      : "";

    var data = JSON.stringify({
      insuredDatabaseId: insuredDatabaseId,
      insuredFirstName: insuredFirstName,
      insuredLastName: insuredLastName,
      insuredEmail: insuredEmail,
      effectiveDate: effectiveDate,
      expirationDate: moment(expiryDate).format("MM/DD/YYYY"),
      bindDate: "",
      insuredCommercialName: businessName,
      lineOfBusinessName: lineOfBusiness,
      carrierName: carrierName,
      premium: policyPremium,
      quoteNumber: quoteNumber,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/NowCert/insertquote?&ncToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        setWorkersCompensationSuccess("Quote Created Successfully!");
        setTimeout(() => setWorkersCompensationSuccess(""), 5000);
      })
      .catch((error: any) => {
        console.log(error);
        localStorage.removeItem("currentPage");
        if (error.response != null && error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else {
          setCreateQuoteErrorMsg("Error while quote creating the request.");
          setTimeout(() => {
            setCreateQuoteErrorMsg("");
          }, 3000);
        }
      });
  };

  const oncancelClicks = () => {
    setShowConfirmBox(false);
  };

  const OnSubmit = () => {
    if (checkValidation()) {
      setShowConfirmBox(true);
    }
  };

  const onCancel = () => {
    setDocumentType("");
    setActiveTab("Insured");
    setIsFileUpload(false);
    setInsuredEmail("");
    setInsuredFirstName("");
    setInsuredMiddelName("");
    setInsuredLastName("");
    setPolicyNumber("");
    setPolicyPremium("");
    setCarrierName("");
    setLineOfBusiness("");
    setExpiryDate("");
    setEffectiveDate("");
    setInsuredPhoneNumber("");
    setBusinessDba("");
    setBusinessName("");
    setBusinessFein("");
    setBusinessWebsite("");
    setBusinessEmail("");
    setMailingAddressLine1("");
    setMailingCity("");
    setMailingState("");
    setMailingZipCode("");
    setCreateInsured(false);
    setCreateQuote(false);
    setUploadFile([]);
    setFileName("");
    setFileNameTitle("");
    setInsuredFirstNameError("");
    setInsuredLastNameError("");
    setInsuredEmailError("");
    setInsuredPhoneNumberError("");
    setBusinessNameError("");
    setBusinessDbaError("");
    setBusinessFeinError("");
    setBusinessEmailError("");
    setmailingAddressLine1Error("");
    setMailingCityError("");
    setMailingStateError("");
    setMailingZipCodeError("");
    setInsuredPhoneNumber("");
    setPolicyNumberError("");
    setPolicyPremiumError("");
    setCarrierNameError("");
    setLineOfBusinessError("");
    setEffectiveDateError("");
    setExpiryDateError("");
    setNcURL("");
    setHideSubmitButton(true);
    setShowHideCancel(false);
    setEachAccident("");
    setDiseaseEachEmployee("");
    setDiseasePolicyLimit("");
    setSicCode("");
    setQuoteNumberError("");
  };

  const checkValidation = () => {
    let formIsValid = true;
    let activeInsuredTabFlag = false;
    setInsuredFirstNameError("");
    setInsuredLastNameError("");
    setInsuredEmailError("");
    setInsuredPhoneNumberError("");
    setBusinessNameError("");
    setBusinessDbaError("");
    setBusinessFeinError("");
    setBusinessEmailError("");
    setmailingAddressLine1Error("");
    setMailingCityError("");
    setMailingStateError("");
    setMailingZipCodeError("");
    setPolicyNumberError("");
    setPolicyPremiumError("");
    setCarrierNameError("");
    setLineOfBusinessError("");
    setEffectiveDateError("");
    setExpiryDateError("");
    setQuoteNumberError("");

    if (insuredFirstName == "" || insuredFirstName == null) {
      formIsValid = false;
      activeInsuredTabFlag = true;
      setInsuredFirstNameError("First name is required");
      window.scroll(0, 0);
    }
    if (insuredLastName == "" || insuredLastName == null) {
      formIsValid = false;
      activeInsuredTabFlag = true;
      setInsuredLastNameError("Last name is required");
      window.scroll(0, 0);
    }
    if (insuredEmail == "" || insuredEmail == null) {
      formIsValid = false;
      activeInsuredTabFlag = true;
      setInsuredEmailError("Email is required");
      window.scroll(0, 0);
    } else {
      const regex =
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
      if (regex.test(insuredEmail) == false) {
        activeInsuredTabFlag = true;
        formIsValid = false;
        setInsuredEmailError("Please enter valid Email.");
        window.scroll(0, 0);
      }
    }
    if (insuredPhoneNumber == "" || insuredPhoneNumber == null) {
      formIsValid = false;
      activeInsuredTabFlag = true;
      setInsuredPhoneNumberError("Phone number is required");
      window.scroll(0, 0);
    } else {
      const regexPhoneNumber = /^\(\d{3}\)\d{3}-\d{4}$/;
      if (regexPhoneNumber.test(insuredPhoneNumber) == false) {
        formIsValid = false;
        activeInsuredTabFlag = true;
        setInsuredPhoneNumberError(
          "Please enter valid phone number : (###)###-####"
        );
        window.scroll(0, 0);
      }
    }
    if (businessName == "" || businessName == null) {
      formIsValid = false;
      activeInsuredTabFlag = true;
      setBusinessNameError("Business name is required");
      window.scroll(0, 0);
    }
    if (businessEmail == "" || businessEmail == null) {
      formIsValid = false;
      activeInsuredTabFlag = true;
      setBusinessEmailError("Business email is required");
      window.scroll(0, 0);
    } else {
      const regex =
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
      if (regex.test(businessEmail) == false) {
        formIsValid = false;
        activeInsuredTabFlag = true;
        setBusinessEmailError("Please enter valid email.");
        window.scroll(0, 0);
      }
    }
    if (mailingAddressLine1 == "" || mailingAddressLine1 == null) {
      formIsValid = false;
      activeInsuredTabFlag = true;
      setmailingAddressLine1Error("Address is required");
    }
    if (mailingCity == "" || mailingCity == null) {
      formIsValid = false;
      activeInsuredTabFlag = true;
      setMailingCityError("City is required");
    }
    if (mailingState == "" || mailingState == null) {
      formIsValid = false;
      activeInsuredTabFlag = true;
      setMailingStateError("State is required");
    }
    if (mailingZipCode == "" || mailingZipCode == null) {
      formIsValid = false;
      activeInsuredTabFlag = true;
      setMailingZipCodeError("Zip code is required");
    }

    if (activeInsuredTabFlag) {
      // setActiveFirstTabError(true)
      setActiveTab("Insured");
    } else {
      if (createQuote == true || createInsured == false) {
        if (policyNumber == "" || policyNumber == null) {
          formIsValid = false;
          setActiveTab("Policy");
          setPolicyNumberError("Policy number is required");
        }
        if (policyPremium == "" || policyPremium == null) {
          formIsValid = false;
          setActiveTab("Policy");
          setPolicyPremiumError("Policy premium is required");
        }
        if (carrierName == "" || carrierName == null) {
          formIsValid = false;
          setActiveTab("Policy");
          setCarrierNameError("Carrier name is required");
        }
        if (lineOfBusiness == "" || lineOfBusiness == null) {
          formIsValid = false;
          setActiveTab("Policy");
          setLineOfBusinessError("LOB name is required");
        }
        if (effectiveDate == "" || effectiveDate == null) {
          formIsValid = false;
          setActiveTab("Policy");
          setEffectiveDateError("Effective date is required");
        }
        if (expiryDate == "" || expiryDate == null) {
          formIsValid = false;
          setActiveTab("Policy");
          setExpiryDateError("Expiration date is required");
        }
        if (quoteNumber == "" || quoteNumber == null) {
          formIsValid = false;
          setActiveTab("Policy");
          setQuoteNumberError("Quote number is required");
        }
      }
    }
    return formIsValid;
  };

  const onOpenClick = () => {
    window.open(ncURL, "_blank");
  };

  const uploadAgain = () => {
    setActiveTab("Insured");
    setIsFileUpload(false);
    setInsuredEmail("");
    setInsuredFirstName("");
    setInsuredMiddelName("");
    setInsuredLastName("");
    setPolicyNumber("");
    setPolicyPremium("");
    setCarrierName("");
    setLineOfBusiness("");
    setExpiryDate("");
    setEffectiveDate("");
    setInsuredPhoneNumber("");
    setBusinessDba("");
    setBusinessName("");
    setBusinessFein("");
    setBusinessWebsite("");
    setBusinessEmail("");
    setMailingAddressLine1("");
    setMailingCity("");
    setMailingState("");
    setMailingZipCode("");
    setCreateInsured(false);
    setCreateQuote(false);
    setUploadFile([]);
    setFileName("");
    setFileNameTitle("");
    setDocumentType("");
    setInsuredFirstNameError("");
    setInsuredLastNameError("");
    setInsuredEmailError("");
    setInsuredPhoneNumberError("");
    setBusinessNameError("");
    setBusinessDbaError("");
    setBusinessFeinError("");
    setBusinessEmailError("");
    setmailingAddressLine1Error("");
    setMailingCityError("");
    setMailingStateError("");
    setMailingZipCodeError("");
    setInsuredPhoneNumber("");
    setPolicyNumberError("");
    setPolicyPremiumError("");
    setCarrierNameError("");
    setLineOfBusinessError("");
    setEffectiveDateError("");
    setExpiryDateError("");
    setNcURL("");
    setHideSubmitButton(true);
    setEachAccident("");
    setDiseaseEachEmployee("");
    setDiseasePolicyLimit("");
    setSicCode("");
    setQuoteNumber("");
    setQuoteNumberError("");
  };

  const Allstate = [
    { name: "Alaska", code: "AK" },
    { name: "Alabama", code: "AL" },
    { name: "Arkansas", code: "AR" },
    { name: "Arizona", code: "AZ" },
    { name: "California", code: "CA" },
    { name: "Colorado", code: "CO" },
    { name: "Connecticut", code: "CT" },
    { name: "DistrictofColumbia", code: "DC" },
    { name: "Delaware", code: "DE" },
    { name: "Florida", code: "FL" },
    { name: "Georgia", code: "GA" },
    { name: "Hawaii", code: "HI" },
    { name: "Iowa", code: "IA" },
    { name: "Idaho", code: "ID" },
    { name: "Illinois", code: "IL" },
    { name: "Indiana", code: "IN" },
    { name: "Kansas", code: "KS" },
    { name: "Kentucky", code: "KY" },
    { name: "Louisiana", code: "LA" },
    { name: "Massachusetts", code: "MA" },
    { name: "Maryland", code: "MD" },
    { name: "Maine", code: "ME" },
    { name: "Michigan", code: "MI" },
    { name: "Minnesota", code: "MN" },
    { name: "Missouri", code: "MO" },
    { name: "Mississippi", code: "MS" },
    { name: "Montana", code: "MT" },
    { name: "NorthCarolina", code: "NC" },
    { name: "NorthDakota", code: "ND" },
    { name: "Nebraska", code: "NE" },
    { name: "NewHampshire", code: "NH" },
    { name: "NewJersey", code: "NJ" },
    { name: "NewMexico", code: "NM" },
    { name: "Nevada", code: "NV" },
    { name: "NewYork", code: "NY" },
    { name: "Ohio", code: "OH" },
    { name: "Oklahoma", code: "OK" },
    { name: "Oregon", code: "OR" },
    { name: "Pennsylvania", code: "PA" },
    { name: "RhodeIsland", code: "RI" },
    { name: "SouthCarolina", code: "SC" },
    { name: "SouthDakota", code: "SD" },
    { name: "Tennessee", code: "TN" },
    { name: "Texas", code: "TX" },
    { name: "Utah", code: "UT" },
    { name: "Virginia", code: "VA" },
    { name: "Vermont", code: "VT" },
    { name: "Washington", code: "WA" },
    { name: "Wisconsin", code: "WI" },
    { name: "WestVirginia", code: "WV" },
    { name: "Wyoming", code: "WY" },
  ];

  const updateSmartScan = () => {
    let token = CommonValues.GetToken();
    var data = {
      submissionId: submissionId,
      createProspect: createInsured,
      createQuote: createQuote,
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/NowCert/updatesmartscan`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {})
      .catch((error: any) => {
        console.log(error);
        localStorage.removeItem("currentPage");
        if (error.response != null && error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else {
          setErrorMessage("Error while smartscanupdating the request.");
          setTimeout(() => {
            setErrorMessage("");
          }, 3000);
        }
      });
  };

  return (
    <div className="row m-3">
      {smartScanHistoryDataLoading ? (
        <div className="col text-center">
          <AtkLoader />
        </div>
      ) : (
        <>
          {(isFileUpload || props.isSubmissionIdFromHistory) &&
          uploadPdfUrl != "" ? (
            <div className="col">
              <iframe
                src={uploadPdfUrl}
                style={{ overflow: "scroll", height: "100%", width: "100%" }}
              ></iframe>
            </div>
          ) : (
              <div
                className="col uploadNowcertsDoc25 me-3"
                onDrop={(e) => {
                  onDropHandler(e);
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="row w-100 ">
                  <div className="col-12 smart-scan-dropdown pb-0">
                    <select
                      className="smart-scan-dropdown"
                      onChange={(e) => OnChangeDocumentType(e.target.value)}
                    >
                      <option>Select Document Type</option>
                      {options1.map((item: any) => (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-12 mt-1 mb-2 text-center">
                    {errorMessageDocumentType.length == 0 ? null : (
                      <span className="text-danger text-12">
                        {errorMessageDocumentType}
                      </span>
                    )}
                  </div>

                  {uploadFile.length > 0 ? (
                    <div className="col-12">
                      {uploadFile.map((file: any, index: any) => (
                        <div className="show-pdf-icon">
                          <div className="file-name">
                            <div className="preview-icon">
                              <img src={PreviewIcon} />
                            </div>
                            <div>
                              <div title={fileNameTitle}>{fileName}</div>
                              <div>
                                {(parseInt(file.size) / 1000).toFixed(0)}KB
                              </div>
                            </div>
                          </div>
                          {uploadComplete ? (
                            <div>
                              <span className="uploaded">Uploaded</span>
                            </div>
                          ) : (
                            uploadingFiles == false && (
                              <div
                                onClick={() => removeFileFromList()}
                                className="show-cursor"
                              >
                                <img src={RemoveIcon} />
                              </div>
                            )
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <>
                      <>
                        <div className="row" style={{ textAlign: "center" }}>
                          <div className="col-12">
                            <img src={PfdIcon} alt="" width="70" />
                          </div>

                          <div className="col-12 mt-4">
                            <div className="col-12">
                              <span className="drag-and-drop">
                                Drag & Drop file here
                              </span>
                            </div>
                            <div className="col-12 my-2">
                              <span
                                style={{
                                  color: "black",
                                  fontSize: "large",
                                }}
                              >
                                or
                              </span>
                            </div>
                            <label className="mb-0 show-cursor">
                              <span className="browse">Browse PDF</span>
                              <input
                                type="file"
                                name="filefield"
                                accept=".pdf"
                                id="bulkFileUploader"
                                style={{
                                  visibility: "hidden",
                                  display: "none",
                                }}
                                onChange={(e) => {
                                  onFileSelectHandler(e.target.files);
                                }}
                              />
                            </label>
                          </div>
                          <div className="coi-info">
                            <span className="text-14 mt-2 info">
                              Supported document types: Acord 125, Acord 126 ,
                              Acord 130 and Dec Page.
                            </span>
                          </div>
                        </div>
                      </>
                    </>
                  )}

                  <div className="col-12 text-center">
                    {isfileuploaded ? null : (
                      <>
                        {uploadingFiles == false &&
                          incompleteRequestData == false &&
                          uploadingRetry == false &&
                          uploadFile.length > 0 && (
                            <div
                              className="d-flex justify-content-center"
                              style={{ width: "100%", padding: "20px" }}
                            >
                              {loading == false ? (
                                <>
                                  <AtkButtonIconBefore
                                    label="Upload"
                                    className="pf-secondary-btn"
                                    onClick={() => {
                                      createUploadRequest();
                                    }}
                                  />
                                </>
                              ) : (
                                <div className="d-flex justify-content-center align-items-center p-2">
                                  <AtkLoader></AtkLoader>
                                </div>
                              )}
                            </div>
                          )}
                      </>
                    )}

                    {errorMessage.length > 0 ? (
                      <div
                        className="d-flex justify-content-center Acord25-upload-file-error"
                        style={{ width: "100%", padding: "20px 20px 0px" }}
                      >
                        <AtkMessage
                          appearance="error"
                          messageText={errorMessage}
                        />
                      </div>
                    ) : null}
                    {successMessage.length > 0 ? (
                      <div
                        className="d-flex justify-content-center upload-file-error"
                        style={{ width: "100%", padding: "20px 20px 0px" }}
                      >
                        <AtkMessage
                          appearance="success"
                          messageText={successMessage}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
          )}

          <div
            className="col p-2 ms-2"
            style={{
              border: "1px solid darkgrey",
              borderRadius: "30px",
              minHeight: "66vh",
            }}
          >
            <div className="col smartscan-tab">
              <span
                className={
                  activeTab === "Insured"
                    ? "policy-name-span active show-cursor"
                    : "policy-name-span show-cursor"
                }
                onClick={() => {
                  handleTabClick("Insured");
                }}
              >
                Insured Details
              </span>
              <span
                className={
                  activeTab === "Policy"
                    ? "policy-name-span active show-cursor"
                    : "policy-name-span show-cursor"
                }
                onClick={() => {
                  handleTabClick("Policy");
                }}
              >
                Policy Details
              </span>
            </div>

            {activeTab === "Insured" ? (
              <>
                <div className="row my-2 mx-auto">
                  <div className="col-12">
                    <span className="text-14 ">
                      <b>Insured Information</b>
                    </span>
                  </div>
                </div>
                <div className="row smartscan-block mx-auto ">
                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <label>First Name</label>
                    <span className="text-danger ">*</span>
                    <TextField
                      className=""
                      type="text"
                      value={insuredFirstName}
                      placeholder="Enter first name"
                      onChange={(e: any) => {
                        onChangeInsuredFirstName(e.target.value);
                      }}
                      isDisabled={props.isSubmissionIdFromHistory}
                    />
                    <span className="text-danger text-12">
                      {insuredFirstNameError}
                    </span>
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <label>Middle Name</label>
                    <TextField
                      className=""
                      type="text"
                      value={insuredMiddleName}
                      placeholder="Enter middle name"
                      onChange={(e: any) => {
                        onChangeInsuredMiddleName(e.target.value);
                      }}
                      isDisabled={props.isSubmissionIdFromHistory}
                    />
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <label>Last Name</label>
                    <span className="text-danger ">*</span>
                    <TextField
                      placeholder="Enter last name"
                      className=""
                      type="text"
                      value={insuredLastName}
                      onChange={(e: any) => {
                        onChangeInsuredLastName(e.target.value);
                      }}
                      isDisabled={props.isSubmissionIdFromHistory}
                    />
                    <span className="text-danger text-12">
                      {insuredLastNameError}
                    </span>
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <label>Email</label>
                    <span className="text-danger ">*</span>
                    <TextField
                      className=""
                      type="text"
                      value={insuredEmail}
                      placeholder="Enter email"
                      onChange={(e: any) => {
                        onChangeInsuredEmail(e.target.value);
                      }}
                      isDisabled={props.isSubmissionIdFromHistory}
                    />
                    <span className="text-danger text-12">
                      {insuredEmailError}
                    </span>
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <label>Phone Number</label>
                    <span className="text-danger ">*</span>
                    <TextField
                      className=""
                      placeholder="Enter phone number"
                      type="text"
                      value={insuredPhoneNumber}
                      onChange={handlePhoneNumberChange}
                      isDisabled={props.isSubmissionIdFromHistory}
                    />
                    <span className="text-danger text-12">
                      {insuredPhoneNumberError}
                    </span>
                  </div>
                </div>

                <div className="row my-2 mx-auto">
                  <div className="col-12">
                    <span className="text-14 ">
                      <b>Business Information</b>
                    </span>
                  </div>
                </div>
                <div className="row smartscan-block mx-auto">
                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <span>Business Name</span>
                    <span className="text-danger"> *</span>
                    <TextField
                      className=""
                      type="text"
                      placeholder="Enter business name"
                      value={businessName}
                      onChange={(e: any) => {
                        onChangeBusinessName(e.target.value);
                      }}
                      isDisabled={props.isSubmissionIdFromHistory}
                    />
                    <span className="text-danger text-12">
                      {businessNameError}
                    </span>
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <span>DBA</span>
                    <TextField
                      className=""
                      type="text"
                      value={businessDba}
                      placeholder="Enter dba name"
                      onChange={(e: any) => {
                        onChangeBusinessDba(e.target.value);
                      }}
                      isDisabled={props.isSubmissionIdFromHistory}
                    />

                    <span className="text-danger text-12 btisErrorText">
                      {businessDbaError}
                    </span>
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <span>Fein</span>
                    <TextField
                      className=""
                      type="text"
                      value={businessFein}
                      placeholder="Enter fein"
                      onChange={(e: any) => {
                        onChangeBusinessFein(e.target.value);
                      }}
                      isDisabled={props.isSubmissionIdFromHistory}
                    />
                    <span className="text-danger text-12 btisErrorText">
                      {businessFeinError}
                    </span>
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <span>Business Email</span>
                    <span className="text-danger"> *</span>
                    <TextField
                      className=""
                      type="text"
                      value={businessEmail}
                      placeholder="Enter business email"
                      onChange={(e: any) => {
                        onChangeBusinessEmail(e.target.value);
                      }}
                      isDisabled={props.isSubmissionIdFromHistory}
                    />
                    <span className="text-danger text-12 btisErrorText">
                      {businessEmailError}
                    </span>
                  </div>
                </div>

                <div className="row my-2 mx-auto">
                  <div className="col-12">
                    <span className="text-14 ">
                      <b>Address Information</b>
                    </span>
                  </div>
                </div>
                <div className="row smartscan-block mx-auto">
                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <span>Address</span>
                    <span className="text-danger"> *</span>
                    <TextField
                      className=""
                      type="text"
                      value={mailingAddressLine1}
                      placeholder="Enter address"
                      onChange={(e: any) => {
                        onChangeAgencyMailingAddress1(e.target.value);
                      }}
                      isDisabled={props.isSubmissionIdFromHistory}
                    />
                    <span className="text-danger text-12">
                      {mailingAddressLine1Error}
                    </span>
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <span>City</span>
                    <span className="text-danger"> *</span>
                    <TextField
                      className=""
                      type="text"
                      value={mailingCity}
                      placeholder="Enter city"
                      onChange={(e: any) => {
                        onChangeAgencyMailingCity(e.target.value);
                      }}
                      isDisabled={props.isSubmissionIdFromHistory}
                    />
                    <span className="text-danger text-12 btisErrorText">
                      {mailingCityError}
                    </span>
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <span>State</span>
                    <span className="text-danger"> *</span>
                    <select
                      style={{
                        width: "102%",
                        color: "#5c5a5a",
                        fontWeight: "500",
                        height: "34px",
                      }}
                      className=""
                      value={mailingState}
                      onChange={(e: any) => {
                        onChangeAgencyMailingState(e.target.value);
                      }}
                      disabled={props.isSubmissionIdFromHistory}
                    >
                      <option value="" selected>
                        Select an option
                      </option>

                      {Allstate.map((state) => (
                        <option key={state.code} value={state.code}>
                          {state.code + "-" + state.name}
                        </option>
                      ))}
                    </select>
                    <span className="text-danger text-12 btisErrorText">
                      {mailingStateError}
                    </span>
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <span>Zip Code</span>
                    <span className="text-danger"> *</span>
                    <TextField
                      className=""
                      type="text"
                      value={mailingZipCode}
                      placeholder="Enter zip code"
                      onChange={(e: any) => {
                        onChangeAgencyMailingZip(e.target.value);
                      }}
                      isDisabled={props.isSubmissionIdFromHistory}
                    />
                    <span className="text-danger text-12 btisErrorText">
                      {mailingZipCodeError}
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="row my-2 mx-auto">
                  <div className="col-12">
                    <span className="text-14 ">
                      <b>Insured Information</b>
                    </span>
                  </div>
                </div>
                <div className="row smartscan-block mx-auto">
                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <label>First Name</label>
                    <span className="text-danger ">*</span>
                    <TextField
                      className=""
                      type="text"
                      value={insuredFirstName}
                      placeholder="Enter first name"
                      onChange={(e: any) => {
                        onChangeInsuredFirstName(e.target.value);
                      }}
                      isDisabled={props.isSubmissionIdFromHistory}
                    />
                    <span className="text-danger text-12">
                      {insuredFirstNameError}
                    </span>
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <label>Middle Name</label>
                    <TextField
                      className=""
                      type="text"
                      value={insuredMiddleName}
                      placeholder="Enter middle name"
                      onChange={(e: any) => {
                        onChangeInsuredMiddleName(e.target.value);
                      }}
                      isDisabled={props.isSubmissionIdFromHistory}
                    />
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <label>Last Name</label>
                    <span className="text-danger ">*</span>
                    <TextField
                      placeholder="Enter last name"
                      className=""
                      type="text"
                      value={insuredLastName}
                      onChange={(e: any) => {
                        onChangeInsuredLastName(e.target.value);
                      }}
                      isDisabled={props.isSubmissionIdFromHistory}
                    />
                    <span className="text-danger text-12">
                      {insuredLastNameError}
                    </span>
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <label>Email</label>
                    <span className="text-danger ">*</span>
                    <TextField
                      className=""
                      type="text"
                      value={insuredEmail}
                      placeholder="Enter email"
                      onChange={(e: any) => {
                        onChangeInsuredEmail(e.target.value);
                      }}
                      isDisabled={props.isSubmissionIdFromHistory}
                    />
                    <span className="text-danger text-12">
                      {insuredEmailError}
                    </span>
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <label>Phone Number</label>
                    <span className="text-danger ">*</span>
                    <TextField
                      className=""
                      placeholder="Enter phone number"
                      type="text"
                      value={insuredPhoneNumber}
                      onChange={handlePhoneNumberChange}
                      isDisabled={props.isSubmissionIdFromHistory}
                    />
                    <span className="text-danger text-12">
                      {insuredPhoneNumberError}
                    </span>
                  </div>
                </div>

                <div className="row my-2 mx-auto">
                  <div className="col-12">
                    <span className="text-14 ">
                      <b>Policy Information</b>
                    </span>
                  </div>
                </div>
                <div className="row smartscan-block mx-auto">
                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <span>Policy Number</span>
                    <span className="text-danger"> *</span>
                    <TextField
                      className=""
                      type="text"
                      value={policyNumber}
                      placeholder="Enter policy number"
                      onChange={(e: any) => {
                        onChangePolicyNumber(e.target.value);
                      }}
                      isDisabled={props.isSubmissionIdFromHistory}
                    />
                    <span className="text-danger text-12">
                      {policyNumberError}
                    </span>
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <span>Policy Premium ($)</span>
                    <span className="text-danger"> *</span>
                    <TextField
                      className=""
                      type="text"
                      value={policyPremium}
                      placeholder="Enter policy premium"
                      onChange={(e: any) => {
                        onChangePolicyPremium(e.target.value);
                      }}
                      isDisabled={props.isSubmissionIdFromHistory}
                    />
                    <span className="text-danger text-12">
                      {policyPremiumError}
                    </span>
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <span>Carrier Name</span>
                    <span className="text-danger"> *</span>
                    {carrierNameLoading ? (
                      <div className="d-flex justify-content-center align-items-center p-1">
                        <AtkLoader></AtkLoader>{" "}
                      </div>
                    ) : (
                      <select
                        style={{
                          width: "102%",
                          color: "#5c5a5a",
                          fontWeight: "500",
                          height: "34px",
                        }}
                        className=""
                        value={carrierName}
                        onChange={(e: any) => {
                          onChangeCarrierName(e.target.value);
                        }}
                        disabled={props.isSubmissionIdFromHistory}
                      >
                        <option value="" selected>
                          Select an option
                        </option>

                        {allCarrierNames.map((name: any) => (
                          <option key={name} value={name} title={name}>
                            {name.length > 16
                              ? name.substring(0, 16) + "..."
                              : name}
                          </option>
                        ))}
                      </select>
                    )}
                    <span className="text-danger text-12">
                      {carrierNameError}
                    </span>
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <span>Line of Business</span>
                    <span className="text-danger"> *</span>
                    <TextField
                      className=""
                      type="text"
                      value={lineOfBusiness}
                      placeholder="Enter line of business"
                      onChange={(e: any) => {
                        onChangeLineOfBusiness(e.target.value);
                      }}
                      isDisabled={props.isSubmissionIdFromHistory}
                    />
                    <span className="text-danger text-12">
                      {lineOfBusinessError}
                    </span>
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <span>Quote Number</span>
                    <span className="text-danger"> *</span>
                    <TextField
                      className=""
                      type="text"
                      value={quoteNumber}
                      placeholder="Enter quote number"
                      onChange={(e: any) => {
                        onChangeQuoteNumber(e.target.value);
                      }}
                      isDisabled={props.isSubmissionIdFromHistory}
                    />
                    <span className="text-danger text-12">
                      {quoteNumberError}
                    </span>
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <span>Effective Date</span>
                    <span className="text-danger"> *</span>
                    <AtkTextField
                      type="date"
                      id="datepicker"
                      value={moment(effectiveDate).format("YYYY-MM-DD")}
                      onChange={(e: any) => {
                        onChangeEffectiveDate(e.target.value);
                      }}
                      isDisabled={props.isSubmissionIdFromHistory}
                    />
                    <span className="text-danger text-12 btisErrorText">
                      {effectiveDateError}
                    </span>
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <span>Expiration Date</span>
                    <span className="text-danger"> *</span>
                    <AtkTextField
                      type="date"
                      id="datepicker"
                      value={moment(expiryDate).format("YYYY-MM-DD")}
                      onChange={(e: any) => {
                        onChangeExpiryDate(e.target.value);
                      }}
                      isDisabled={props.isSubmissionIdFromHistory}
                    />
                    <span className="text-danger text-12 btisErrorText">
                      {expiryDateError}
                    </span>
                  </div>
                </div>
              </>
            )}
            <div className="row p-0 d-flex align-items-center mx-auto">
              <div className="col-12 d-flex align-items-center justify-content-end">
                <div className=" d-flex align-items-center me-3">
                  <span className="">Create Prospect </span>

                  <Toggle
                    isChecked={createInsured}
                    onChange={handleCreateInsuredToggle}
                    isDisabled={createQuote ? true : false}
                  />
                </div>
                <div className=" d-flex align-items-center">
                  <span className="">Create Quote</span>
                  <Toggle
                    isChecked={createQuote}
                    onChange={handleCreatePolicyToggle}
                  />
                </div>
              </div>
            </div>

            {showConfirmBox ? (
              <div className="row">
                <div className="col-12 onsubmit-info-show my-2">
                  <SectionMessage appearance="warning">
                    <p>
                      <span className="d-block text-13">
                        By clicking{" "}
                        <span className="text-danger">
                          <b>Submit</b>
                        </span>
                        , a new prospect/quote will be created in NowCerts. Are
                        you sure you want to proceed?
                      </span>
                    </p>
                    <div className="text-center mt-1">
                      {onSubmitLoader ? (
                        <>
                          <AtkLoader />
                        </>
                      ) : (
                        <AtkButton
                          label="OK"
                          onClick={() => {
                            onSubmitClick();
                          }}
                          className="pf-secondary-btn w-auto me-2"
                        />
                      )}
                      <AtkButton
                        label="Cancel"
                        onClick={() => {
                          oncancelClicks();
                        }}
                        className="pf-primary-btn w-auto"
                      />
                    </div>
                  </SectionMessage>
                </div>
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="col-12 d-flex align-items-center  justify-content-end my-2">
                    {hideSubmitButton ? (
                      <AtkButton
                        label="Submit"
                        onClick={OnSubmit}
                        className="pf-secondary-btn w-auto me-2"
                      />
                    ) : null}
                    {showHideCancel ? (
                      <AtkButton
                        label="Cancel"
                        onClick={onCancel}
                        className="pf-primary-btn w-auto"
                      />
                    ) : null}
                  </div>
                </div>
              </>
            )}

            <div className="row">
              <div className="col-12 d-flex align-items-center  justify-content-end my-2">
                {ncURL != "" && ncURL != null ? (
                  <>
                    <AtkButton
                      label="Open Prospect"
                      onClick={onOpenClick}
                      className="pf-secondary-btn w-auto me-2 "
                      style={{ marginTop: "7px" }}
                    />

                    <AtkButton
                      label="Upload Again"
                      onClick={uploadAgain}
                      className="pf-secondary-btn w-auto me-2 "
                      style={{ marginTop: "7px" }}
                    />
                  </>
                ) : null}
              </div>
            </div>

            {toggleSelectErrorMsg.length > 0 ? (
              <div
                className="d-flex justify-content-center upload-file-error"
                style={{ width: "100%", padding: "20px 20px 0px" }}
              >
                <AtkMessage
                  appearance="error"
                  messageText={toggleSelectErrorMsg}
                />
              </div>
            ) : null}

            {createInsuredErrorMsg.length > 0 ? (
              <div
                className="d-flex justify-content-center upload-file-error"
                style={{ width: "100%", padding: "20px 20px 0px" }}
              >
                <AtkMessage
                  appearance="error"
                  messageText={createInsuredErrorMsg}
                />
              </div>
            ) : null}

            {createQuoteErrorMsg.length > 0 ? (
              <div
                className="d-flex justify-content-center upload-file-error"
                style={{ width: "100%", padding: "20px 20px 0px" }}
              >
                <AtkMessage
                  appearance="error"
                  messageText={createQuoteErrorMsg}
                />
              </div>
            ) : null}

            {createInsuredSuccessMsg.length > 0 ? (
              <div
                className="d-flex justify-content-center upload-file-error"
                style={{ width: "100%", padding: "20px 20px 0px" }}
              >
                <AtkMessage
                  appearance="success"
                  messageText={createInsuredSuccessMsg}
                />
              </div>
            ) : null}

            {workersCompensationSuccess.length > 0 ? (
              <div
                className="d-flex justify-content-center upload-file-error"
                style={{ width: "100%", padding: "20px 20px 0px" }}
              >
                <AtkMessage
                  appearance="success"
                  messageText={workersCompensationSuccess}
                />
              </div>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
}
