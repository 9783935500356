import React, { FC, useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CommonValues from "../../common/utils";
import axios from "axios";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import { Logout } from "../../common/utils";
import { Navigate, useNavigate } from "react-router-dom";

export default function ArchivedPolicies(props: any) {
  const navigate = useNavigate();
  const [archivePolicies, setArchivePolicies] = useState([]);
  const [policyArchiveLoading, setPolicyArchiveLoading] = useState(false);

  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    getArchivePolicies(props.clientId);
  }, []);

  const getArchivePolicies = (ClientId: any) => {
    const token = CommonValues.GetToken();
    setPolicyArchiveLoading(true);
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/archivequotes?ClientId=${ClientId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        let ArchiveError: any = false;
        let showConfirmation: any = false;
        let updatedArray: any = [];
        response.data.forEach((element: any) => {
          updatedArray.push({
            ams: element.ams,
            effectiveDate: element.effectiveDate,
            policyNumber: element.policyNumber,
            currentPremium:
              element.currentPremium == null || element.currentPremium == 0
                ? element.quotedPremium
                : element.currentPremium,
            quotedPremium: element.quotedPremium,
            businessName: element.businessName,
            businessEmail: element.businessEmail,
            businessPhoneNumber: element.businessPhoneNumber,
            showConfirmation: showConfirmation,
            transactionId: element.transactionId,
            id: element.id,
            displayOrder: element.displayOrder,
            title: element.title,
            isArchive: element.isArchive,
            policyTitle: element.policyTitle,
            linesOfBusinessJSON: element.linesOfBusinessJSON,
            locationsJSON: element.locationsJSON,
            businessAddressJSON: element.businessAddressJSON,
            linesOfBusiness: element.linesOfBusiness,
            businessAddress: element.businessAddress,
            term: element.term,
            carrier: element.carrier,
            archiveError: ArchiveError,
            taxes: element.taxes,
            fees: element.fees,
          });
        });
        setArchivePolicies(updatedArray);
        setPolicyArchiveLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        setPolicyArchiveLoading(false);
        if (error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        }
      });
  };
  const premiumTemplate = (rowData: any) => {
    const formattedPremium = parseFloat(rowData.currentPremium).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    return <span>{formattedPremium}</span>;
  };

  return (
    <div className="row">
      <div className="col-12 d-flex align-items-center mt-2">
        <span className="policy-type-heading">Archived Policies</span>
        <span className="client-name-span">
          {" "}
          <b className=" ">
            {props.clientName}{" "}
            {props.clientName.length > 0
              ? props.isProspect
                ? "(Prospect)"
                : "(Client)"
              : null}{" "}
          </b>
        </span>
      </div>

      <div className="col-12  history-table mt-3">
        {policyArchiveLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <AtkLoader />
          </div>
        ) : (
          <>
            <DataTable value={archivePolicies} paginator paginatorTemplate="PrevPageLink PageLinks NextPageLink" rows={10} responsiveLayout="scroll">
              <Column header="Title" field="title"></Column>
              <Column
                header="Quoted Premium"
                field="currentPremium"
                body={premiumTemplate}
              ></Column>
              <Column header="Business Name" field="businessName"></Column>
            </DataTable>
          </>
        )}
      </div>
    </div>
  );
}
