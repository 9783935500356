import { useEffect, useState } from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import CommonValues from "../common/utils";

export default function ErrorPage() {
  const [showLogin, setShowLogin] = useState(false);
  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      setShowLogin(true);
    }
  }, []);
  return (
    <div className="nowcerts-sso-page">
      <Header />
      <div className="content-wrapper container-fluid landing-content-wrapper d-flex justify-content-center">
        <div
          className="login-box d-flex align-items-center justify-content-center"
          style={{ minHeight: "30vh" }}
        >
          <div className="row mx-auto ">
            <div className="col-12   text-center ">
              <div className="privacy-heading">
                <h4 className="text-22"> Oops! Page not found</h4>
              </div>

              <div className="text-justify mt-3  container">
                {showLogin ? (
                  <a href="/" className="text-16 text-decoration-underline">
                    Goto login page
                  </a>
                ) : (
                  <a
                    href="/landingpage"
                    className="text-16 text-decoration-underline"
                  >
                    Goto landing page
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
