import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useRef, useState } from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import InjectedStripePaymentMethod from "./StripePaymentMethod";
import PaymentHistory from "./PaymentHistory";
import InjectedStripePaymentCredits from "./AddPaymentCredits";

export default function Billing() {
  const toast: any = useRef("");
  const [showPaymentMethod, setShowPaymentMethod] = useState(true);
  const [showBuyCredit, setShowBuyCredit] = useState(false);
  const [showPaymentHistory, setShowPaymentHistory] = useState(false);
  const [selectedtab, setSelectedTab] = useState(1);
  const isSocialLogin =
    localStorage.getItem("SocialLogin") === "true" ? true : false;
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
  const onTabSelect = (index: any) => {
    if (index == 1) {
      setSelectedTab(index);
      setShowPaymentMethod(true);
      setShowBuyCredit(false);
      setShowPaymentHistory(false);
    } else if (index == 2) {
      setSelectedTab(index);
      setShowPaymentMethod(false);
      setShowBuyCredit(true);
      setShowPaymentHistory(false);
    } else {
      setSelectedTab(index);
      setShowPaymentMethod(false);
      setShowBuyCredit(false);
      setShowPaymentHistory(true);
    }
  };
  return (
    <div className="billing-main-page">
      <Header />
      <div className="content-wrapper">
        <div className="row wrapper-main-row mx-auto wrapper-below-div">
          <div className="col-12">
            <div className="middle-header-col active ms-2">
              <span>
                <b>Billing</b>
              </span>
            </div>
          </div>
          <div className="row main-content-row mx-auto">
            <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
              <div className="pt-4 pf-sidebar-block p-0">
                <span
                  className={
                    selectedtab == 1
                      ? "pf-sidebar-menu active"
                      : "pf-sidebar-menu show-cursor"
                  }
                  onClick={() => onTabSelect(1)}
                >
                  <span className="sidebar-menu-title">Payment Method</span>{" "}
                </span>
              </div>
              <div className="pt-2 pf-sidebar-block p-0">
                <span
                  className={
                    selectedtab == 2
                      ? " pf-sidebar-menu active"
                      : "pf-sidebar-menu show-cursor"
                  }
                  onClick={() => onTabSelect(2)}
                >
                  <span className="sidebar-menu-title">Buy Credits</span>{" "}
                </span>
              </div>
              <div className="pt-2 pf-sidebar-block p-0">
                <span
                  className={
                    selectedtab == 3
                      ? " pf-sidebar-menu active"
                      : "pf-sidebar-menu show-cursor"
                  }
                  onClick={() => onTabSelect(3)}
                >
                  <span className="sidebar-menu-title">Payment History</span>{" "}
                </span>
              </div>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 main-content-col">
              <div className="row p-2 mx-auto">
                <>
                  {showPaymentMethod ? (
                    <div className="col-12">
                      <Elements stripe={stripePromise}>
                        <InjectedStripePaymentMethod />
                      </Elements>
                    </div>
                  ) : null}
                  {showBuyCredit ? (
                    <div className="col-12">
                      <Elements stripe={stripePromise}>
                        <InjectedStripePaymentCredits
                          onTabSelect={onTabSelect}
                        />
                      </Elements>
                    </div>
                  ) : null}
                  {showPaymentHistory ? (
                    <div className="col-12">
                      <PaymentHistory />
                    </div>
                  ) : null}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
