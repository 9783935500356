import PFOcrScan from "./PFSOcrScan"
import PFScanHistory from "./PFScanHistory"
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import HistoryIcon from "../../assets/images/History_B.svg";
import ScanIcon from "../../assets/images/scan.png";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import PfLogo from "../../assets/images/PF_Logo.svg";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import axios from "axios";
import CommonValues from "../../common/utils";
import { Toast } from "primereact/toast";
import AtkLoader from "../../AtlaskitControls/AtkLoader";

export default function () {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [showNewPFScan, setShowNewPFScan] = useState(true);
  const [showPFScanHistory, setShowPFScanHistory] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [submissionIdFromHistory, setSubmissionIdFromHistory] = useState("");
  const [isSubmissionIdFromHistory, setIsSubmissionIdFromHistory] =useState(false);
  const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
  const [currentBalance, setCurrentBalance] = useState("0");

  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      navigate("/");
      CommonValues.Logout(navigate);
    }
    //getCardDetails();
  })
  const onTabSelect = (index: any) => {
    if (index == 1) {
      setSelectedTab(index);
      setShowNewPFScan(true);
      setShowPFScanHistory(false);
    } else if (index == 2) {
      setSelectedTab(index);
      setShowNewPFScan(false);
      setShowPFScanHistory(true);
    }
  };

  const getCardDetails = () => {
    setCurrentBalanceLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success) {
          setCurrentBalance("" + response.data.currentBalance / 100);
        }
        setCurrentBalanceLoading(false);
      })
      .catch((error) => {
        setCurrentBalanceLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting current balance",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting current balance",
            life: 3000,
          });
        }
      });
  };

  return (
    <div className="billing-main-page">
      <Header />
      <div className="content-wrapper">
      <Toast ref={toast} />
        <div className="row wrapper-main-row mx-auto wrapper-below-div">
          <div className="col-12">
            <div className="middle-header-col active ms-2">
              <img src={PfLogo} className="middle-header-logo" />
              <span className="middle-header-title">Premium Finance Scan</span>
            </div>
          </div>
          <div className="row main-content-row mx-auto">
            <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
              <div className="pt-4 pf-sidebar-block p-0">
                <span
                  className={
                    selectedTab == 1
                      ? "pf-sidebar-menu active"
                      : "pf-sidebar-menu show-cursor"
                  }
                  onClick={() => onTabSelect(1)}
                >
                  <img
                    src={ScanIcon}
                    className={
                      selectedTab == 1
                        ? "pf-sidebar-img pf-sidebar-img-active"
                        : "pf-sidebar-img "
                    }
                  />
                  <span className="sidebar-menu-title">New Premium Finance Scan</span>{" "}
                </span>
              </div>
              <div className="pt-2 pf-sidebar-block p-0">
                <span
                  className={
                    selectedTab == 2
                      ? " pf-sidebar-menu active"
                      : "pf-sidebar-menu show-cursor"
                  }
                  onClick={() => onTabSelect(2)}
                >
                  <img
                    src={HistoryIcon}
                    className={
                      selectedTab == 2
                        ? "pf-sidebar-img pf-sidebar-img-active"
                        : "pf-sidebar-img"
                    }
                  />
                  <span className="sidebar-menu-title">Premium Finance Scan History</span>{" "}
                </span>
              </div>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 main-content-col">
              <div className="row p-2 mx-auto">
                {showNewPFScan ? (
                  <div className="col-12">
                    <PFOcrScan
                      submissionIdFromHistory={submissionIdFromHistory}
                      isSubmissionIdFromHistory={isSubmissionIdFromHistory}
                    />
                  </div>
                ) : null}
                {showPFScanHistory ? (
                  <div className="col-12">
                    <PFScanHistory />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}