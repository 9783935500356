import StarIcon from "@atlaskit/icon/glyph/star";
import StarFilledIcon from "@atlaskit/icon/glyph/star-filled";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AtkButton from "../AtlaskitControls/AtkButton";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AtkMessage from "../AtlaskitControls/AtkMessage";
import CommonValues from "../common/utils";

export default function FeedbackPage({
  onSkip,
}: {
  onSkip: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const [comment, setComment] = useState("");
  const [ratings, setRatings] = useState(0);
  const [star1Visible, setStar1Visible] = useState(true);
  const [star2Visible, setStar2Visible] = useState(true);
  const [star3Visible, setStar3Visible] = useState(true);
  const [star4Visible, setStar4Visible] = useState(true);
  const [star5Visible, setStar5Visible] = useState(true);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessageForComment, setErrorMessageForComment] = useState("");
  const onStarRatingClick = (starLabel: any, starValue: any) => {
    setRatings(starValue);
    switch (starLabel) {
      case "star1":
        setStar1Visible(false);
        setStar2Visible(true);
        setStar3Visible(true);
        setStar4Visible(true);
        setStar5Visible(true);
        break;
      case "star2":
        setStar1Visible(false);
        setStar2Visible(false);
        setStar3Visible(true);
        setStar4Visible(true);
        setStar5Visible(true);
        break;
      case "star3":
        setStar1Visible(false);
        setStar2Visible(false);
        setStar3Visible(false);
        setStar4Visible(true);
        setStar5Visible(true);
        break;
      case "star4":
        setStar1Visible(false);
        setStar2Visible(false);
        setStar3Visible(false);
        setStar4Visible(false);
        setStar5Visible(true);
        break;
      case "star5":
        setStar1Visible(false);
        setStar2Visible(false);
        setStar3Visible(false);
        setStar4Visible(false);
        setStar5Visible(false);
        break;
      default:
        break;
    }
  };
  const onRemoveStarRatingClick = (starLabel: any, starValue: any) => {
    setRatings(starValue);
    switch (starLabel) {
      case "star1":
        setStar1Visible(false);
        setStar2Visible(true);
        setStar3Visible(true);
        setStar4Visible(true);
        setStar5Visible(true);
        break;
      case "star2":
        setStar1Visible(false);
        setStar2Visible(false);
        setStar3Visible(true);
        setStar4Visible(true);
        setStar5Visible(true);
        break;
      case "star3":
        setStar1Visible(false);
        setStar2Visible(false);
        setStar3Visible(false);
        setStar4Visible(true);
        setStar5Visible(true);
        break;
      case "star4":
        setStar1Visible(false);
        setStar2Visible(false);
        setStar3Visible(false);
        setStar4Visible(false);
        setStar5Visible(true);
        break;
      case "star5":
        setStar1Visible(false);
        setStar2Visible(false);
        setStar3Visible(false);
        setStar4Visible(false);
        setStar5Visible(false);
        break;
      default:
        break;
    }
  };

  const onCommentChange = (e: any) => {
    const commentValue = e.target.value;
    if (commentValue.length > 250) {
      setErrorMessageForComment(
        "Please provide your feedback within 250 character"
      );
    } else if (commentValue.length == 0 || commentValue.trim() === "") {
      setErrorMessageForComment("Please provide your feedback");
    } else if (commentValue.length > 0) {
      setErrorMessageForComment("");
    }
    setComment(e.target.value);
  };
  const resetAll = () => {
    setComment("");
    setSuccessMessage("");
    setErrorMessage("");
    setStar1Visible(true);
    setStar2Visible(true);
    setStar3Visible(true);
    setStar4Visible(true);
    setStar5Visible(true);
    onSkip(false);
  };

  const checkValidation = () => {
    let formIsValid = true;
    setErrorMessage("");
    setErrorMessageForComment("");

    if (comment == null || comment.trim() === "") {
      formIsValid = false;
      setErrorMessageForComment("Please provide your feedback");
    }
    if (comment.length > 250) {
      formIsValid = false;
      setErrorMessageForComment(
        "Please provide your feedback within 250 character"
      );
    }
    return formIsValid;
  };

  const onSubmitClick = () => {
    if (checkValidation()) {
      setFeedbackLoading(true);
      let token = CommonValues.GetToken();
      const encodedFeedback = encodeURIComponent(comment);
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_UserService_Url}/api/user/feedback?ratings=${ratings}&feedback=${encodedFeedback}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then((response: any) => {
          setErrorMessage("");
          setSuccessMessage("Thank you! Feedback submitted successfully");
          setFeedbackLoading(false);
          setTimeout(() => {
            resetAll();
          }, 1000);
        })
        .catch((error: any) => {
          console.log(error);
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status == 401) {
              CommonValues.Logout(navigate);
            } else {
              errorMessage = "Unknown error while submitting the feedback";
            }
          } else {
            errorMessage = "Unknown error while submitting the feedback";
          }
          setErrorMessage(errorMessage);
          setFeedbackLoading(false);
        });
    }
  };
  const onCancelClick = () => {
    onSkip(false);
  };
  return (
    <div>
      <div className="row mx-auto">
        <div className="col-12 feedback-header">
          <h4>Feedback</h4>

          <div className="text-center my-2">
            {star1Visible && (
              <span
                className="bookmark-icon"
                onClick={() => onStarRatingClick("star1", 1)}
              >
                <StarIcon label="star1" />
              </span>
            )}
            {!star1Visible && (
              <span
                className="bookmarked-icon"
                onClick={() => onRemoveStarRatingClick("star1", 1)}
              >
                <StarFilledIcon label="star1" />
              </span>
            )}

            {star2Visible && (
              <span
                className="bookmark-icon"
                onClick={() => onStarRatingClick("star2", 2)}
              >
                <StarIcon label="star2" />
              </span>
            )}
            {!star2Visible && (
              <span
                className="bookmarked-icon"
                onClick={() => onRemoveStarRatingClick("star2", 2)}
              >
                <StarFilledIcon label="star2" />
              </span>
            )}

            {star3Visible && (
              <span
                className="bookmark-icon"
                onClick={() => onStarRatingClick("star3", 3)}
              >
                <StarIcon label="star3" />
              </span>
            )}
            {!star3Visible && (
              <span
                className="bookmarked-icon"
                onClick={() => onRemoveStarRatingClick("star3", 3)}
              >
                <StarFilledIcon label="star3" />
              </span>
            )}

            {star4Visible && (
              <span
                className="bookmark-icon"
                onClick={() => onStarRatingClick("star4", 4)}
              >
                <StarIcon label="star4" />
              </span>
            )}
            {!star4Visible && (
              <span
                className="bookmarked-icon"
                onClick={() => onRemoveStarRatingClick("star4", 4)}
              >
                <StarFilledIcon label="star4" />
              </span>
            )}

            {star5Visible && (
              <span
                className="bookmark-icon"
                onClick={() => onStarRatingClick("star5", 5)}
              >
                <StarIcon label="star5" />
              </span>
            )}
            {!star5Visible && (
              <span
                className="bookmarked-icon"
                onClick={() => onRemoveStarRatingClick("star5", 5)}
              >
                <StarFilledIcon label="star5" />
              </span>
            )}
          </div>
        </div>
        <div
          className={
            errorMessageForComment.length > 0
              ? "validation-div form-group col-12 mb-2 text-12"
              : "form-group col-12 mb-2 text-12"
          }
        >
          <textarea
            name="comment"
            onChange={onCommentChange}
            value={comment}
            className="form-control comment-text mb-2 "
            rows={6}
            placeholder="Enter your feedback here"
          />
          {comment != "" && comment != null ? (
            <label
              className={
                comment.length > 250
                  ? "counter counter-danger"
                  : "counter counter-color d-block"
              }
            >
              {comment.length}/250
            </label>
          ) : (
            <label className="counter d-block">0/250</label>
          )}

          <span className="text-danger text-12 d-block">
            {errorMessageForComment}
          </span>
        </div>

        <div className="col-12 text-end p-2">
          <AtkButton
            label="Skip"
            onClick={onCancelClick}
            className="pf-secondary-btn w-auto me-2"
          />
          {feedbackLoading ? (
            <AtkLoader></AtkLoader>
          ) : (
            <AtkButton
              label="Submit"
              onClick={onSubmitClick}
              className="pf-secondary-btn w-auto "
            />
          )}
        </div>
        {errorMessage != null && errorMessage != "" ? (
          <div className="col-12 mb-2 mt-2 ">
            <AtkMessage appearance="error" messageText={errorMessage} />
          </div>
        ) : null}
        {successMessage != null && successMessage != "" ? (
          <div className="col-12 mb-2 mt-2 ">
            <AtkMessage appearance="success" messageText={successMessage} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
