import { useState } from "react";
import HistoryIcon from "../../../assets/images/History_B.svg";
import ScanIcon from "../../../assets/images/scan.png";
import Footer from "../../../common/Footer";
import Header from "../../../common/Header";
import NewSmartScan from "./NewSmartScan";
import SmartScanHistory from "./SmartScanHistory";
import NowCertsLog from "../../../assets/images/NowCerts.png"

export default function SmartScan() {
  const [showNewSmartScan, setShowNewSmartScan] = useState(true);
  const [showSmartScanHistory, setShowSmartScanHistory] = useState(false);
  const [selectedtab, setSelectedTab] = useState(1);
  const [submissionIdFromHistory, setSubmissionIdFromHistory] = useState("");
  const [isSubmissionIdFromHistory, setIsSubmissionIdFromHistory] =
    useState(false);

  const onTabSelect = (index: any) => {
    if (index == 1) {
      setSelectedTab(index);
      setShowNewSmartScan(true);
      setShowSmartScanHistory(false);
    } else if (index == 2) {
      setSelectedTab(index);
      setShowNewSmartScan(false);
      setShowSmartScanHistory(true);
    }
  };

  return (
    <div className="billing-main-page">
      <Header />
      <div className="content-wrapper">
        <div className="row wrapper-main-row mx-auto wrapper-below-div">
          <div className="col-12">
            <div className="middle-header-col active ms-2">
            <img src={NowCertsLog} className="landing-page-img" />
              <span>
                <b>Smart Scan</b>
              </span>
            </div>
          </div>
          <div className="row main-content-row mx-auto">
            <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
              <div className="pt-4 pf-sidebar-block p-0">
                <span
                  className={
                    selectedtab == 1
                      ? "pf-sidebar-menu active"
                      : "pf-sidebar-menu show-cursor"
                  }
                  onClick={() => onTabSelect(1)}
                >
                  <img
                    src={ScanIcon}
                    className={
                      selectedtab == 1
                        ? "pf-sidebar-img pf-sidebar-img-active"
                        : "pf-sidebar-img "
                    }
                  />
                  <span className="sidebar-menu-title">New Smart Scan</span>{" "}
                </span>
              </div>
              <div className="pt-2 pf-sidebar-block p-0">
                <span
                  className={
                    selectedtab == 2
                      ? " pf-sidebar-menu active"
                      : "pf-sidebar-menu show-cursor"
                  }
                  onClick={() => onTabSelect(2)}
                >
                  <img
                    src={HistoryIcon}
                    className={
                      selectedtab == 2
                        ? "pf-sidebar-img pf-sidebar-img-active"
                        : "pf-sidebar-img"
                    }
                  />
                  <span className="sidebar-menu-title">Smart Scan History</span>{" "}
                </span>
              </div>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 main-content-col">
              <div className="row p-2 mx-auto">
                {showNewSmartScan ? (
                  <div className="col-12">
                    <NewSmartScan
                      submissionIdFromHistory={submissionIdFromHistory}
                      isSubmissionIdFromHistory={isSubmissionIdFromHistory}
                    />
                  </div>
                ) : null}
                {showSmartScanHistory ? (
                  <div className="col-12">
                    <SmartScanHistory />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
