import NewIcon from "../assets/images/NewQuote_B 1.svg";
import HistoryIcon from "../assets/images/History_B.svg";
import SaveIcon from "../assets/images/Saved Quotes_B.svg";
import SettingIcon from "../assets/images/Settings_B.svg";
import DocumentIconActive from "../assets/images/DocumentUpload_G.svg";
import DocumentComingSoonIcon from "../assets/images/document_o.svg";
import PaymentComingSoonIcon from "../assets/images/payment_o.svg";
import PaymentIconActive from "../assets/images/Payment_G.svg";
import ScanIcon from "../../src/assets/images/scan.png";

import { useNavigate } from "react-router-dom";

export default function Sidebar(props: any) {
  const navigate = useNavigate();

  const onNewQuoteClick = () => {
    navigate("/premiumfinance");
    //  window.location.reload();
  };

  const onHistoryClick = () => {
    navigate("/pfhistory");
  };

  const onSavedQuotesClick = () => {
    navigate("/pfsavedquotes");
  };

  const onDocumentUploadClick = () => {
    navigate("/pfuploadfile");
  };

  const onSettingClick = () => {
    navigate("/pfsettings");
  };

  const onNewScanClick = () => {
    navigate("/nowcertcoiscan");
  };
  const onCOIScanHistoryClick = () => {
    navigate("/coihistory");
  };
  return (
    <div className="pt-4 pf-sidebar-block p-0">
      <>
        <span
          className={
            props.pageName == "NewQuotepage" || props.pageName == "Quotespage"
              ? "pf-sidebar-menu active "
              : "pf-sidebar-menu pf-hover-effect show-cursor"
          }
          onClick={onNewQuoteClick}
        >
          <img
            src={NewIcon}
            className={
              props.pageName == "NewQuotepage" || props.pageName == "Quotespage"
                ? "pf-sidebar-img pf-sidebar-img-active"
                : "pf-sidebar-img "
            }
          />
          <span className="sidebar-menu-title">New Quote</span>{" "}
        </span>
        <span
          className={
            props.pageName == "Historypage"
              ? "pf-sidebar-menu active"
              : "pf-sidebar-menu pf-hover-effect show-cursor"
          }
          onClick={onHistoryClick}
        >
          <img
            src={HistoryIcon}
            className={
              props.pageName == "Historypage"
                ? "pf-sidebar-img pf-sidebar-img-active"
                : "pf-sidebar-img "
            }
          />
          <span className="sidebar-menu-title">History </span>
        </span>
        <span
          className={
            props.pageName == "SavedQuotespage"
              ? "pf-sidebar-menu active"
              : "pf-sidebar-menu pf-hover-effect show-cursor"
          }
          onClick={onSavedQuotesClick}
        >
          <img
            src={SaveIcon}
            className={
              props.pageName == "SavedQuotespage"
                ? "pf-sidebar-img pf-sidebar-img-active"
                : "pf-sidebar-img "
            }
          />
          <span className="sidebar-menu-title">Saved Quotes</span>{" "}
        </span>
        <span
          className={
            props.pageName == "PfSettingpage"
              ? "pf-sidebar-menu active"
              : "pf-sidebar-menu pf-hover-effect show-cursor"
          }
          onClick={onSettingClick}
        >
          <img
            src={SettingIcon}
            className={
              props.pageName == "PfSettingpage"
                ? "pf-sidebar-img pf-sidebar-img-active"
                : "pf-sidebar-img "
            }
          />
          <span className="sidebar-menu-title">PF Settings</span>{" "}
        </span>
        <span className="coming-soon-block mt-2">
          <span
            className={
              props.pageName == "DocumentUploadpage"
                ? "pf-sidebar-menu active"
                : "pf-sidebar-menu"
            }
            // onClick={onDocumentUploadClick}
          >
            {props.pageName == "DocumentUploadpage" ? (
              <img src={DocumentIconActive} className="pf-sidebar-img" />
            ) : (
              <img src={DocumentComingSoonIcon} className="pf-sidebar-img" />
            )}
            <span className="coming-soon-menu-title "> Document Upload</span>{" "}
          </span>
          <span className="coming-soon-title">Coming soon</span>
          <span
            className={
              props.pageName == "PfPaymentpage"
                ? "pf-sidebar-menu active"
                : "pf-sidebar-menu"
            }
          >
            {props.pageName == "PfPaymentpage" ? (
              <img src={PaymentIconActive} className="pf-sidebar-img" />
            ) : (
              <img src={PaymentComingSoonIcon} className="pf-sidebar-img" />
            )}
            <span className="coming-soon-menu-title">Payment</span>{" "}
          </span>
        </span>
      </>
    </div>
  );
}
