import React, { FC, useEffect, useState, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import AtkButton from "../AtlaskitControls/AtkButton";
import CommonValues from "../common/utils";
import axios from "axios";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AtkMessage from "../AtlaskitControls/AtkMessage";
import { Toast } from "primereact/toast";

export default function AmsAgencySetting(props: any) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageForAgencyName, setErrorMessageForAgencyName] = useState("");
  const [errorMessageForAddress, setErrorMessageForAddress] = useState("");
  const [errorMessageForCity, setErrorMessageForCity] = useState("");
  const [errorMessageForState, setErrorMessageForState] = useState("");
  const [errorMessageForZip, setErrorMessageForZip] = useState("");
  const [CompanyId, setCompanyId] = useState("");
  const [agencyName, setAgencyName] = useState("");
  const [State, setState] = useState("");
  const [Address1, setAddress1] = useState("");
  const [Address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [Country, setCountry] = useState("");
  const [Zip, setZip] = useState("");
  const [amsSettingSuccessMessage, setAmsSettingSuccessMessage] = useState("");
  const [amsSettingErrorMessage, setAmsSettingErrorMessage] = useState("");
  const [invalidCredMessage, setInvalidCredMessage] = useState("");
  const toast: any = useRef("");

  useEffect(() => {
    setLoading(false);
    if (
      localStorage.getItem("SelectedAMS") == "Nowcert" &&
      localStorage.getItem("NCAgencyId") !== "undefined" &&
      localStorage.getItem("NCToken") !== "undefined"
    ) {
      setTimeout(() => {
        // getNowCertsAgentDetails();
        getAmsAgencySettings();
      }, 5);
    }
    if (localStorage.getItem("SelectedAMS") == "Hawksoft") {
      setTimeout(() => {
        getAmsAgencySettings();

        // getHawksoftAgentDetails();
      }, 5);
    }
    if (localStorage.getItem("SelectedAMS") == "Vertafore") {
      setTimeout(() => {
        getAmsAgencySettings();

        // getVertaforeAgencyDetails();
      }, 5);
    }
  }, [props.Key]);
  const onAgencynameChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setAgencyName(e.target.value);
  };
  const onAgencyAddress1Change = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setAddress1(e.target.value);
  };
  const onAgencyAddress2Change = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setAddress2(e.target.value);
  };
  const onAgencyCityChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setCity(e.target.value);
  };
  const onAgencyStateChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setState(e.target.value);
  };
  const onAgencyCountryChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setCountry(e.target.value);
  };
  const onAgencyZipChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setZip(e.target.value);
  };
  const clearValues = () => {
    setAgencyName("");
    setAddress1("");
    setAddress2("");
    setCity("");
    setZip("");
    setState("");
    setCountry("");
  };
  
  const getAmsAgencySettings = () => {
    clearValues();
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/amsagencysettings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response: any) => {
        setLoading(false);
        setAgencyName(response.data.agencyName);
        setAddress1(response.data.address1);
        setAddress2(response.data.address2);
        setCity(response.data.city);
        setZip(response.data.zip);
        setState(response.data.state);
        setCountry(response.data.country);
        setAmsSettingSuccessMessage("");
        setAmsSettingErrorMessage("");
      })
      .catch((error: any) => {
        setLoading(false);

        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while loading the AMS Agency settings", life: 3000 });
          }
        }
        setAmsSettingErrorMessage(errorMessage);
      });
  };
  const checkValidation = () => {
    let formIsValid = true;
    setErrorMessageForAgencyName("");
    setErrorMessageForAddress("");
    setErrorMessageForCity("");
    setErrorMessageForState("");
    setErrorMessageForZip("");
    if (agencyName == "" || agencyName == null) {
      formIsValid = false;
      setErrorMessageForAgencyName("Please enter agency name");
    }
    if (Address1 == "" || Address1 == null) {
      formIsValid = false;
      setErrorMessageForAddress("Please enter address ");
    }
    if (city == "" || city == null) {
      formIsValid = false;
      setErrorMessageForCity("Please enter city ");
    }
    if (State == "" || State == null) {
      formIsValid = false;
      setErrorMessageForState("Please enter state ");
    }
    if (Zip == "" || Zip == null) {
      formIsValid = false;
      setErrorMessageForZip("Please enter zip ");
    }
    return formIsValid;
  };
 
  const onSaveAmsAgencySetting = () => {
    if (checkValidation()) {
      setLoading(true);
      // setInvalidCredMessage("Validating your credentials..");
      const token = CommonValues.GetToken();
      var data = {
        AgencyName: agencyName,
        Country: Country,
        Address1: Address1,
        Address2: Address2,
        State: State,
        Zip: Zip,
        CompanyId: CompanyId,
        City: city,
      };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/amsagencysettings`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "Application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        getAmsAgencySettings();
      })
      .catch((error: any) => {
        setLoading(false);
        setInvalidCredMessage("");

        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else if (error.response.status === 404) {
            toast.current.show({ severity: "error", detail: "Invalid ams360 credentials!", life: 3000 });
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while saving the AMS360 settings", life: 3000 });
          }
        }

        setAmsSettingErrorMessage(errorMessage);
      });
    }
  };
  const onEnterClick = (e: any) => {
    if (e.keyCode == 13) {
      onSaveAmsAgencySetting();
    }
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="row ams-header mx-auto">
        <div className="col-12">
          <h4 className="text-20 fw-normal">AMS Agency Settings</h4>
        </div>
      </div>
      <div className="row mx-auto text-12 p-1">
        <div className=" col-md-12 mt-2">
          {amsSettingErrorMessage.length > 0 ? <AtkMessage appearance="error" messageText={amsSettingErrorMessage} /> : null}

          {amsSettingSuccessMessage != null && amsSettingSuccessMessage != "" ? (
            <AtkMessage appearance="success" messageText={amsSettingSuccessMessage} />
          ) : null}
        </div>
        {loading ? (
          <>
            <div
              className="row"
              style={{
                minHeight: "40vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <div className="col-12">
                <AtkLoader></AtkLoader>
                <span className="text-14 ms-2">Fetching AMS Settings</span>
              </div>
            </div>
            {/* <div className="d-flex justify-content-center mt-2">
              {invalidCredMessage.length > 0 ? <AtkMessage appearance="warning" messageText={invalidCredMessage} /> : null}
            </div> */}
          </>
        ) : (
          <>
         <div className={errorMessageForAgencyName.length > 0 ? " form-group mt-2 validation-div mt-2" : " form-group mt-2"}>
              <label className="form-label">
                Agency Name <span className="text-danger">*</span>
              </label>
              <AtkTextField
                type="text"
                id="agencyName"
                name="agencyName"
                className="input-field"
                onChange={onAgencynameChange}
                value={agencyName}
                placeholder="Enter agency name"
              />
              <span className="text-danger small">{errorMessageForAgencyName}</span>
            </div>
            <div className={errorMessageForAddress.length > 0 ? " form-group  validation-div mt-2" : " form-group mt-2"}>
              <label className="form-label">
                Address 1 <span className="text-danger">*</span>
              </label>

              <AtkTextField
                type="text"
                id="Address1"
                name="Address"
                className="input-field"
                onChange={onAgencyAddress1Change}
                value={Address1}
                placeholder="Enter address 1"
              />
              <span className="text-danger small">{errorMessageForAddress}</span>
            </div>

            <div className=" form-group mt-2">
              <label className="form-label">Address 2</label>

              <AtkTextField
                type="text"
                id="Address2"
                name="Address2"
                className="input-field"
                onChange={onAgencyAddress2Change}
                value={Address2}
                placeholder="Enter address 2"
              />
            </div>

            <div className={errorMessageForCity.length > 0 ? "col-6 form-group mt-2 validation-div " : "col-6 form-group mt-2 "}>
              <label className="form-label">
                City <span className="text-danger">*</span>
              </label>

              <AtkTextField
                type="text"
                id="city"
                name="city"
                className="input-field"
                onChange={onAgencyCityChange}
                value={city}
                placeholder="Enter city"
              />
              <span className="text-danger small">{errorMessageForCity}</span>
            </div>

            <div className={errorMessageForState.length > 0 ? " col-6 form-group mt-2 validation-div" : " col-6 form-group mt-2 "}>
              <label className="form-label">
                State <span className="text-danger">*</span>
              </label>

              <AtkTextField
                type="text"
                id="State"
                name="State"
                className="input-field"
                onChange={onAgencyStateChange}
                value={State}
                placeholder="Enter state"
              />
              <span className="text-danger small">{errorMessageForState}</span>
            </div>

            <div className="col-6 form-group mt-2">
              <label className="form-label">Country</label>

              <AtkTextField
                type="text"
                id="Country"
                name="Country"
                className="input-field"
                onChange={onAgencyCountryChange}
                value={Country}
                placeholder="Enter country"
              />
            </div>

            <div className={errorMessageForZip.length > 0 ? " col-6 form-group  validation-div mt-2" : "col-6 form-group mt-2 "}>
              <label className="form-label">
                Zip <span className="text-danger">*</span>
              </label>

              <AtkTextField
                type="text"
                id="Zip"
                name="Zip"
                className="input-field"
                onChange={onAgencyZipChange}
                value={Zip}
                placeholder="Enter zip"
                onKeyDown={onEnterClick}
              />
              <span className="text-danger small">{errorMessageForZip}</span>
            </div>

            <div className="col-12 text-end mt-2">
              {loading ? (
                <div className="">
                  <AtkLoader />
                </div>
              ) : (
                <AtkButton label="Save" onClick={onSaveAmsAgencySetting} className="pf-secondary-btn w-auto my-3" />
              )}
            </div>
            <div className="mb-2">
              <span className="text-danger">{errorMessage}</span>
            </div>
          </>
        )}
      </div>
    </>
  );
}
