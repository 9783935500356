import axios from "axios";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AtkButton from "../../../AtlaskitControls/AtkButton";
import AtkLoader from "../../../AtlaskitControls/AtkLoader";
import ViewDetailsIcon from "../../../assets/images/ViewDetailsIcon.png";
import CommonValues from "../../../common/utils";
import NewSmartScan from "./NewSmartScan";

export default function SmartScanHistory(props: any) {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [historyLoading, setHistoryLoading] = useState(true);
  const [smartScanHistoryData, setSmartScanHistoryData] = useState<any>([]);
  const [actionLoading, setActionLoading] = useState(false);
  const [submissionId, setSubmissionId] = useState("");
  const [isSubmissionIdFromHistory, setIsSubmissionIdFromHistory] =
    useState(false);

  useEffect(() => {
    const token = CommonValues.GetToken();

    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    getSmartScanHistory();
  }, []);

  const getSmartScanHistory = () => {
    setHistoryLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/smartscanhistory`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setSmartScanHistoryData(response.data);
        setHistoryLoading(false);
      })
      .catch((error: any) => {
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting SmartScan history",
              life: 3000,
            });
          }
        }
        setHistoryLoading(false);
      });
  };

  const preview = (rowData: any) => {
    if (rowData.status == "Success") {
      return (
        <>
          {actionLoading ? (
            <div className="d-flex">
              <AtkLoader />{" "}
            </div>
          ) : (
            <span className="coi-download-icon">
              <img
                alt="View Details"
                src={ViewDetailsIcon}
                height="20"
                width="20"
                className="me-2 show-cursor"
                title="View Details"
                onClick={() => viewSmartScan(rowData.submissionId)}
              />
            </span>
          )}
        </>
      );
    } else {
      return <span className=" text-12 quote-not-span">Not Available</span>;
    }
  };

  const viewSmartScan = (submissionId: string) => {
    setSubmissionId(submissionId);
    setIsSubmissionIdFromHistory(true);
  };

  const OnBack = () => {
    setIsSubmissionIdFromHistory(false);
  };

  return (
    <>
      {isSubmissionIdFromHistory ? (
        <>
          <div className="col-12">
            <AtkButton
              label="Back"
              onClick={OnBack}
              className="pf-secondary-btn w-auto mx-4"
            />
          </div>
          <div className="col-12">
            <NewSmartScan
              submissionIdFromHistory={submissionId}
              isSubmissionIdFromHistory={isSubmissionIdFromHistory}
            />
          </div>
        </>
      ) : (
        <div className="row w-100">
          <Toast ref={toast} />
          <div className="col-12  history-table mt-3">
            {historyLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <AtkLoader />{" "}
              </div>
            ) : (
              <DataTable
                value={smartScanHistoryData}
                paginator
                paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                rows={10}
                selectionMode="single"
              >
                <Column
                  header="Created Date"
                  field="created"
                  body={(rowData: any) => {
                    if (rowData.created != null || rowData.created !== "") {
                      return moment
                        .utc(rowData.created, "YYYY-MM-DD")
                        .format("DD-MM-YYYY");
                    }
                  }}
                ></Column>
                <Column header="SubmissionId" field="submissionId"></Column>
                <Column header="File Name" field="fileName"></Column>
                <Column
                  header="Create Quote"
                  body={(rowData) => (
                    <span>{rowData.createQuote ? "Yes" : "No"}</span>
                  )}
                ></Column>
                <Column
                  header="Create Prospect"
                  body={(rowData) => (
                    <span>{rowData.createProspect ? "Yes" : "No"}</span>
                  )}
                ></Column>
                <Column header="Status" field="status"></Column>
                <Column header="Preview" body={preview}></Column>
              </DataTable>
            )}
          </div>
        </div>
      )}
    </>
  );
}
