import React, { FC, useEffect, useState, useRef } from "react";
import CommonValues, { Logout } from "../../common/utils";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import Header from "../../common/Header";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import MiddleHeader from "../../common/MiddleHeader";
import Sidebar from "../../common/Sidebar";
import Footer from "../../common/Footer";
import { Dropdown } from "primereact/dropdown";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import Magnifier from "../../assets/images/MagnifierIcon.png";
import AtkIcon from "../../AtlaskitControls/AtkIcon";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import BackIcon from "../../assets/images/Back Button.svg";
import ResponseTable from "./ResponseTable";
import LandingPage from "../../popup-page/LandingPage";
import { Toast } from "primereact/toast";
import AtkMessage from "../../AtlaskitControls/AtkMessage";

interface IProps {}
const PFHistory: FC<IProps> = (wholePolicyData: any) => {
  const navigate = useNavigate();
  const [premiumHistoryLoading, setPremiumHistoryLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [historyData, setHistoryData] = useState([]);
  const [partnerListLoading, setPartnerListLoading] = useState(false);
  const [partnerList, setPartnerList] = useState<any>([]);
  const [partner, setPartner] = useState({ id: null, name: "All" });
  const [fromDate, setFromDate] = useState<any>("");
  const [toDate, setToDate] = useState<any>("");
  const [selectList, setSelectList] = useState<any>([]);
  const [clientName, setClientName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;
  const toast: any = useRef("");

  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    setToDate(new Date().toISOString().substring(0, 10));
    setFromDate(oneMonthAgo.toISOString().substring(0, 10));
    getPremiumFinanceHistory(oneMonthAgo.toISOString().substring(0, 10), new Date().toISOString().substring(0, 10));
    getPremiumFinanceSettings();
  }, []);

  const getPremiumFinanceSettings = () => {
    setPartnerListLoading(true);
    var token = CommonValues.GetToken();

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/partnerlist`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setErrorMessage("");
        const allOption = { id: null, name: "All" };
        const updatedPartnerList = [allOption, ...response.data];
        setPartnerList(updatedPartnerList);
        setPartner(allOption);
        setPartnerListLoading(false);
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the Premium finance Setting details";
          }
        } else {
          errorMessage = "Unknown error while loading the Premium finance Setting details";
        }
        setPartnerListLoading(false);
        setErrorMessage(errorMessage);
      });
  };

  const getPremiumFinanceHistory = (fmDate: any, tDate: any) => {
    setPremiumHistoryLoading(true);
    var token = CommonValues.GetToken();

    var data = JSON.stringify({
      FromDate: fmDate,
      ToDate: tDate,
      PartnerIds: selectList,
      ClientName: clientName,
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/history`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        if (response.data && response.data.length > 0) {
          const updatedHistoryData = response.data.map((item: any) => ({
            ...item,
            selectedPolicyIndex: item.policyIndex,
          }));
          setHistoryData(updatedHistoryData);
        }

        // setHistoryData(response.data);

        setErrorMessage("");
        setPremiumHistoryLoading(false);
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the Premium finance history ";
          }
        } else {
          errorMessage = "Unknown error while loading the Premium finance history ";
        }
        setPremiumHistoryLoading(false);
        setErrorMessage(errorMessage);
      });
  };
  const partnerListDropdownClicked = (e: any) => {
    const selectedOption = e.target.value;
    if (selectedOption === "All") {
      setPartner(selectedOption);
      setSelectList([]);
    } else {
      setPartner(selectedOption);
      const selectedPartner = partnerList.find((partner: any) => partner.name == selectedOption.name);
      if (selectedPartner) {
        setSelectList(selectedPartner.id !== null ? [selectedPartner.id] : []);
      }
    }
  };
  const handleFromDateChange = (event: any) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event: any) => {
    setToDate(event.target.value);
  };
  const onClientNameChange = (event: any) => {
    if (event.target.value.length > 255) {
      return;
    }
    setClientName(event.target.value);
  };
  const isValid = () => {
    let valid = true;
    if (fromDate == null || fromDate == "") {
      toast.current.show({ severity: "error", detail: "Please select from date", life: 5000 });
      valid = false;
    }
    if (toDate == null || toDate == "") {
      toast.current.show({ severity: "error", detail: "Please select to date", life: 5000 });
      valid = false;
    }
    if (fromDate > toDate) {
      toast.current.show({ severity: "error", detail: "To date must be greater than from date", life: 5000 });
      valid = false;
    }
    return valid;
  };
  const OnSearch = (e: any) => {
    if (e.keyCode == 13) {
      if (isValid()) {
        getPremiumFinanceHistory(fromDate, toDate);
        setCurrentPage(1);
      }
    }
  };
  const onSearchClick = () => {
    if (isValid()) {
      getPremiumFinanceHistory(fromDate, toDate);
      setCurrentPage(1);
    }
  };
  const onBackButtonClick = () => {
    navigate("/landingPage");
  };
  return (
    <>
      <Toast ref={toast} />

      <div className="pf-history-main-page">
        <Header />
        <div className="content-wrapper ">
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <MiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <Sidebar pageName="Historypage" />
              </div>

              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row history-search-row d-flex  align-items-end text-12 position-relative">
                  {/* <div className="col-lg col-md  col-sm col my-1 back-btn-div back-btn-history" >
                    <img src={BackIcon} className="show-cursor" height={20} width={20} onClick={onBackButtonClick} />
                  </div> */}
                  <div className="col-lg-2  col-md-6 col-sm-6 col-6 my-1 ">
                    <label className="me-2 mb-1">From Date</label>

                    <AtkTextField
                      type="date"
                      id="datepicker"
                      className="input-field"
                      name="fromDate"
                      onChange={handleFromDateChange}
                      value={fromDate}
                      max={new Date().toISOString().substring(0, 10)}
                    />
                  </div>
                  <div className="col-lg-2  col-md-6 col-sm-6 col-6 my-1  ">
                    <label className="me-2 mb-1">To Date</label>
                    <AtkTextField
                      type="date"
                      id="datepicker"
                      name="toDate"
                      onChange={handleToDateChange}
                      value={toDate}
                      onKeyDown={() => {}}
                      placeholder=""
                      className="input-field"
                      max={toDate}
                    />
                  </div>
                  <div className="col-lg-3 col-md-5 col-sm-12 col-12 my-1 ">
                    <label className="me-2 mb-1">Finance Company</label>{" "}
                    {partnerListLoading ? (
                      <div className="text-center">
                        <AtkLoader />
                      </div>
                    ) : (
                      <Dropdown
                        type="text"
                        id="text"
                        className="finance-company-dropdown input-field"
                        value={partner}
                        onChange={partnerListDropdownClicked}
                        optionLabel="name"
                        options={partnerList}
                        placeholder="Finance Company"
                      />
                    )}
                  </div>
                  <div className="col-lg-3 col-md-5 col-sm-8 col-8 my-1 ">
                    <label className="me-2 mb-1">Client Name</label>

                    <AtkTextField
                      type="text"
                      id="text"
                      name="clientName"
                      onChange={onClientNameChange}
                      value={clientName}
                      onKeyDown={OnSearch}
                      placeholder="Search by client name"
                      className="input-field"
                    />
                  </div>
                  <div className="col-lg col-md col-sm-4 col-4 my-1 history-searchbtn-div">
                    <AtkButtonIconBefore
                      label="Search"
                      iconBefore={<AtkIcon icon={Magnifier} />}
                      className="pf-primary-btn"
                      onClick={onSearchClick}
                      onKeyDown={OnSearch}
                    />
                  </div>
                  {/* {fromDateError.length > 0 ? (
                    <div className="col-lg-2 col-md-6 col-sm-12 mt-2 ">
                      <span className="text-danger text-12">{fromDateError}</span>
                    </div>
                  ) : null}
                  {toDateError.length > 0 ? (
                    <div className="col-lg-2 col-md-6 col-sm-12  mt-2 ">
                      <span className="text-danger text-12">{toDateError}</span>
                    </div>
                  ) : null}
                  {toAndFromDateError.length > 0 ? (
                    <div className="col-12 mb-2">
                      {" "}
                      <span className="text-danger text-12">{toAndFromDateError}</span>
                    </div>
                  ) : null} */}
                </div>
                <div className="row">
                  {premiumHistoryLoading ? (
                    <div className="d-flex align-items-center justify-content-center mt-2">
                      <AtkLoader />
                    </div>
                  ) : (
                    <>
                      {historyData.length > 0 ? (
                        <div className="col-12 history-table p-0">
                          <ResponseTable tableValue={historyData} />
                        </div>
                      ) : (
                        <div className="col-12 mt-3 history-unavailable ">
                          <AtkMessage appearance="information" messageText={"No history found"} />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default PFHistory;
