import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import BackIcon from "../../assets/images/Back Button.svg";
import FenrisLogo from "../../assets/images/Fenris digital logo.png";
import HistoryIcon from "../../assets/images/History_B.svg";
import NewIcon from "../../assets/images/NewQuote_B 1.svg";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import CommonValues from "../../common/utils";
import ExistingAndFenrisData from "./ExistingAndFenrisData";
import FenrisHistory from "./FenrisHistory";

export default function FenrisMainPage(props: any) {
  const toast: any = useRef("");
  const [search, setSearch] = useState("");
  const [searchClientLoading, setSearchClientLoading] = useState(true);
  const [searchClientList, setSearchClientList] = useState<any>([]);
  const navigate = useNavigate();
  const [insuredDatabaseId, setInsuredDataBaseId] = useState("");
  const [existingData, setExistingData] = useState(false);
  const [clientName, setClientName] = useState("");
  const [enrichDataFlag, setEnrichDataFlag] = useState(false);
  const [showFenrisHistory, setShowFenrisHistory] = useState(false);
  const [selectedtab, setSelectedTab] = useState(1);
  const [enrichDataDisbaled, setEnrichDataDisbaled] = useState(true);
  const [searchTextBoxDisbaled, setSearchTextBoxDisbaled] = useState(false);
  const [currentBalance, setCurrentBalance] = useState("0");
  const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
  const [currentBalanceUpdateFlag, setCurrentBalanceUpdateFlag] =
    useState(false);

  const location = useLocation();
  const momentumPolicyId = location.state?.policyid || "";
  const momentumInsuredDatabaseId = location.state?.insuredid || "";

  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      navigate("/");
      CommonValues.Logout(navigate);
    }
    if (momentumInsuredDatabaseId != null && momentumInsuredDatabaseId != "") {
      getNowCertsClientsById(momentumInsuredDatabaseId);
      setInsuredDataBaseId(momentumInsuredDatabaseId);
      setEnrichDataDisbaled(false);
    }
  }, []);

  useEffect(() => {
    getCardDetails();
  }, [currentBalanceUpdateFlag]);

  const onNewPrefillClick = () => {
    setSearchTextBoxDisbaled(false);
    setEnrichDataDisbaled(true);
    setClientName("");
    setExistingData(false);
    setShowFenrisHistory(false);
  };
  const onBackButtonClick = () => {
    navigate("/landingPage");
    localStorage.removeItem("like");
    localStorage.removeItem("dislike");
  };

  const onClientSearchChange = (value: any) => {
    if (value.length > 255) {
      return;
    }
    setSearch(value);
    setClientName(value);
  };

  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      navigate("/");
      CommonValues.Logout(navigate);
    }
    if (search == "") {
      return;
    }
    setSearchClientLoading(true);
    if (
      CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id
    ) {
      const searchTimeout = setTimeout(() => {
        getNowCertsClients(search);
      }, 10);
      return () => clearTimeout(searchTimeout);
    }
  }, [search]);

  const getCardDetails = () => {
    setCurrentBalanceLoading(true);
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success == true) {
          setCurrentBalance(
            "" + response.data.currentBalance / 100
          );
        }
        setCurrentBalanceLoading(false);
      })
      .catch((error) => {
        setCurrentBalanceLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting current balance",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting current balance",
            life: 3000,
          });
        }
      });
  };

  const getNowCertsClientsById = (insuredDatabaseId: string) => {
    let nowCertsToken = localStorage.getItem("NCToken")
      ? localStorage.getItem("NCToken")
      : "";
    var data = {
      insuredDatabaseId: insuredDatabaseId,
    };
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlistbyId?insuredDatabaseId=${insuredDatabaseId}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        let insuredInfo = response.data;
         setClientName(insuredInfo[0].commercialName);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Error while getting insured data.",
              life: 3000,
            });
          }
        }
      });
  };
  const getNowCertsClients = (query: string) => {
    var data = {
      SearchValue: query,
    };
    let nowCertsToken = localStorage.getItem("NCToken")
      ? localStorage.getItem("NCToken")
      : "";
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlist?searchText=${query}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setSearchClientList(response.data);
        setSearchClientLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail:
                "Unknown error while loading the insureds data.Please try again later.",
              life: 3000,
            });
          }
        }
        // setErrorMessage(errorMessage);
        setSearchClientLoading(false);
      });
  };

  const onClientSelect = (client: any) => {
    let insuredDatabaseId = client.databaseId;
    setInsuredDataBaseId(insuredDatabaseId);
    setSearch("");
    setClientName(client.name);
    setEnrichDataDisbaled(false);
    localStorage.setItem("like", "false");
    localStorage.setItem("dislike", "false");
  };

  const onClickEnrich = () => {
    // setLoading(true);
    // setTimeout(() => setLoading(false), 1000);
    setExistingData(true);
    setEnrichDataFlag(!enrichDataFlag);
  };

  const onFenrisHistoryClick = () => {
    setClientName("");
    setShowFenrisHistory(true);
    setExistingData(false);
    setSearchTextBoxDisbaled(true);
    setEnrichDataDisbaled(true);
    localStorage.removeItem("like");
    localStorage.removeItem("dislike");
  };

  const onTabSelect = (index: any) => {
    if (index == 1) {
      setSelectedTab(index);
      onNewPrefillClick();
    } else {
      onFenrisHistoryClick();
      setSelectedTab(index);
    }
  };

  const clientNameSet = (
    clientName: any,
    textDisbaled: boolean,
    enrichBuutonDisbaled: boolean
  ) => {
    setClientName(clientName);
    setSearchTextBoxDisbaled(textDisbaled);
    setEnrichDataDisbaled(enrichBuutonDisbaled);
  };
  const enrichRatingDataButtonDisbaled = () => {
    setEnrichDataDisbaled(true);
  };

  const updateCurrentBalanceUpdateFlag = () => {
    setCurrentBalanceUpdateFlag(!currentBalanceUpdateFlag);
  };

  const balanceCheck =()=>{
    getCardDetails();
  }

  return (
    <div className="fenris-main-page">
      <Header />
      <div className="content-wrapper">
        <div className="row wrapper-main-row mx-auto wrapper-below-div">
          <div className="col-12">
            <div className="middle-header-col active ms-2">
              <img src={FenrisLogo} className="middle-header-logo" />
              <span className="middle-header-title">AMS PreFill</span>
            </div>
          </div>
          <div className="row main-content-row mx-auto">
            <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
              <div
                className="pt-4 pf-sidebar-block p-0"
                onClick={onNewPrefillClick}
              >
                <span
                  className={
                    selectedtab == 1
                      ? " pf-sidebar-menu active"
                      : "pf-sidebar-menu show-cursor"
                  }
                  onClick={() => onTabSelect(1)}
                >
                  <img
                    src={NewIcon}
                    className="pf-sidebar-img pf-sidebar-img-active"
                  />
                  <span className="sidebar-menu-title">New Prefill</span>{" "}
                </span>
              </div>
              <div
                className="pt-2 pf-sidebar-block p-0"
                onClick={onFenrisHistoryClick}
              >
                <span
                  className={
                    selectedtab == 2
                      ? " pf-sidebar-menu active"
                      : "pf-sidebar-menu show-cursor"
                  }
                  onClick={() => onTabSelect(2)}
                >
                  <img
                    src={HistoryIcon}
                    className="pf-sidebar-img pf-sidebar-img-active"
                  />
                  <span className="sidebar-menu-title">History</span>{" "}
                </span>
              </div>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 main-content-col">
              <div className="row client-search-row position-relative">
                <div className="col-lg-1 col-md-1 col-sm-1 my-1 ">
                  <img
                    src={BackIcon}
                    className="show-cursor"
                    height={20}
                    width={20}
                    onClick={onBackButtonClick}
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 p-0 my-1 client-search-input-col position-relative mx-auto">
                  <div className="search-bar ">
                    <AtkTextField
                      type="text"
                      id="text"
                      className="search-area "
                      name="clientName"
                      onChange={(e) => {
                        onClientSearchChange(e.target.value);
                      }}
                      value={clientName}
                      //   onKeyDown={onKeyClick}
                      placeholder={"Enter client name"}
                      isDisabled={searchTextBoxDisbaled ? true : false}
                      autoComplete="off"
                    />
                  </div>
                  {search.length > 0 && (
                    <div id="client-search" className=" position-absolute  ">
                      {searchClientLoading ? (
                        <div className="d-flex justify-content-center my-2 client-loader-div">
                          <AtkLoader />
                        </div>
                      ) : searchClientList.length == 0 ? (
                        <div style={{ padding: "5px", background: "white" }}>
                          <span style={{ color: "#ddd", marginLeft: "10px" }}>
                            No results
                          </span>
                        </div>
                      ) : (
                        searchClientList.map((item: any) => (
                          <div
                            style={{ cursor: "pointer", width: "100%" }}
                            onClick={() => {
                              onClientSelect(item);
                            }}
                          >
                            <a
                              href="#"
                              style={{
                                color: "black",
                                width: "100%",
                                fontSize: "12px",
                              }}
                            >
                              {item.name}
                            </a>
                          </div>
                        ))
                      )}
                    </div>
                  )}
                </div>

                <div className="col-lg-7 col-md-7 col-sm-7">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <AtkButtonIconBefore
                        label="Enrich Rating Data"
                        className="pf-secondary-btn"
                        onClick={() => onClickEnrich()}
                        isDisabled={enrichDataDisbaled ? true : false}
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 mt-2 text-start cred-title text-end">
                      <label>
                        <b>Current Balance</b>
                      </label>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      {currentBalanceLoading ? (
                        <AtkLoader />
                      ) : (
                        <div className="input-group">
                          <span className="input-group-text">$</span>
                          <AtkTextField
                            className="input-field form-control p-0"
                            type="text"
                            id="text"
                            value={currentBalance}
                            isDisabled={true}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-2 mx-auto">
                <>
                  {existingData ? (
                    <div className="col-12">
                      <ExistingAndFenrisData
                        insureddatabaseId={insuredDatabaseId}
                        clientnameset={clientNameSet}
                        enrichDataFlag={enrichDataFlag}
                        updateCurrentBalanceUpdateFlag={
                          updateCurrentBalanceUpdateFlag
                        }
                        balanceCheck={balanceCheck}

                      />
                    </div>
                  ) : null}
                  {showFenrisHistory ? (
                    <div className="col-12">
                      <FenrisHistory clientnameset={clientNameSet} />
                    </div>
                  ) : null}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
