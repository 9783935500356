import axios from "axios";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NowCertLogo from "../../src/assets/images/NowCerts.png";
import PFLogo from ".././assets/images/PF_Logo.svg";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AddApps from "../OtherPages/AddApps";
import FenrisLogo from "../assets/images/Fenris digital logo.png";
import VehicleLogo from "../assets/images/Vehicle.png";
import AddAppsIcon from "../assets/images/plus.svg";
import Footer from "../common/Footer";
import Header from "../common/Header";
import CommonValues from "../common/utils";

export default function LandingPage() {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [widgetData, setWidgetData] = useState<any[]>([]);
  const [isVisibleWidget, setIsVisibleWidget] = useState<any>([]);
  const [isVisibleWidgetList, setIsVisibleWidgetList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [settingLoading, setSettingLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showPrimaryAmsMessage, setShowPrimaryAmsMessage] = useState(false);
  const [showOCR, setShowOCR] = useState(false);
  const appsRef = useRef<OverlayPanel>(null);
  const [nowCertsMessageShow, setNowCertsMessageShow] = useState(false);
  const location = useLocation();
  const hostname = window.location.hostname;
  useEffect(() => {
    const token = CommonValues.GetToken();

    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    if (localStorage.getItem("PFSettings") == "true") {
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }

    getCommonSettings();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const getCommonSettings = () => {
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/pfcommonsettings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setSettingLoading(true);
    axios(config)
      .then((response: any) => {
        localStorage.setItem("tenantId", response.data.pfTenantId);
        localStorage.setItem("clientCount", response.data.clientCount);
        localStorage.setItem("HawksoftSettings", JSON.stringify(response.data));

        if (response.data.pfTenantId == 0) {
          setSettingLoading(false);
        }
        if (response.data.agencyId != null && response.data.agencyId != "") {
          localStorage.setItem("HawksoftAgencyId", "true");
        } else {
          localStorage.setItem("HawksoftAgencyId", "false");
        }
        if (response.data.pfList == 0) {
          localStorage.setItem("PFSettings", "true");
          setShowMessage(true);
        } else {
          localStorage.setItem("PFSettings", "false");
          setShowMessage(false);
        }

        if (
          response.data.pfTenantId == process.env.REACT_APP_NowCerts_Tenant_Id
        ) {
          localStorage.setItem("SelectedAMS", "Nowcert");
          setShowPrimaryAmsMessage(false);
        } else if (
          response.data.pfTenantId == process.env.REACT_APP_Hawksoft_Tenant_Id
        ) {
          localStorage.setItem("SelectedAMS", "Hawksoft");
          if (hostname == process.env.REACT_APP_Momentum_Url) {
            setShowPrimaryAmsMessage(true);
          } else {
            setShowPrimaryAmsMessage(false);
          }
          setSettingLoading(false);
        } else if (
          response.data.pfTenantId == process.env.REACT_APP_Vertafore_Tenant_Id
        ) {
          localStorage.setItem("SelectedAMS", "Vertafore");
          if (hostname == process.env.REACT_APP_Momentum_Url) {
            setShowPrimaryAmsMessage(true);
          } else {
            setShowPrimaryAmsMessage(false);
          }
          getVertaforeSettings();
        }
        if (
          response.data.pfTenantId == process.env.REACT_APP_NowCerts_Tenant_Id
        ) {
          getNowCertsCreds();
        } else {
        }
      })
      .catch((error: any) => {
        setSettingLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the Setting details";
            toast.current.show({
              severity: "error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        } else {
          errorMessage = "Unknown error while loading the Setting details";
          toast.current.show({
            severity: "error",
            detail: { errorMessage },
            life: 3000,
          });
        }
      });
  };
  const getNowCertsCreds = () => {
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/getcredentials`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setSettingLoading(false);
        if (
          response.data.ncUserName == null ||
          response.data.ncUserName == null
        ) {
          localStorage.setItem("NowCertsSettings", "false");
          setNowCertsMessageShow(true);
          setShowOCR(false);
        } else {
          localStorage.setItem("NowCertsSettings", "true");
          setNowCertsMessageShow(false);
          setShowOCR(true);
        }
      })
      .catch((error) => {
        setSettingLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage =
              "Unknown error while loading the NowCerts Setting details";
            toast.current.show({
              severity: "error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        } else {
          errorMessage =
            "Unknown error while loading the NowCerts Setting details";
          toast.current.show({
            severity: "error",
            detail: { errorMessage },
            life: 3000,
          });
        }
      });
  };
  const getVertaforeSettings = () => {
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/vertaforesettings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        if (
          response.data.agencyName == "" ||
          response.data.agencyName == null ||
          response.data.userName == "" ||
          response.data.userName == null
        ) {
          localStorage.setItem("VertaforeSetting", "true");
        } else {
          localStorage.setItem("VertaforeSetting", "false");
        }
        setSettingLoading(false);
      })
      .catch((error: any) => {
        setSettingLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the vertafore settings";
            toast.current.show({
              severity: "error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        }
      });
  };
  const getUserWidgets = () => {
    setLoading(true);
    var data = JSON.stringify({
      searchText: "",
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_AUTHAPI}/api/account/getuserwidgets`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setLoading(false);
        setWidgetData(response.data.map((widget: any) => widget.widgetCode));
        setIsVisibleWidgetList(
          response.data.map((widget: any) => widget.isVisibleOnChrLandingPage)
        );
        setIsVisibleWidget(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          }
        }
      });
  };
  const onNewQuoteClick = () => {
    navigate("/premiumfinance");
  };

  const onCoiScanClick = () => {
    navigate("/nowcertcoiscan");
  };

  const onSmartScanClick = () => {
    navigate("/smartscan");
  };
  const onFenrisTileClick = () => {
    navigate("/fenrisprefill");
  };
  const onSettingTileClick = () => {
    navigate("/pfsettings");
  };
  const gotopfsettingPage = () => {
    navigate("/commonsettings");
  };

  const onPFSmartScanClick = () => {
    navigate("/pfscan");
  };

  const onVehiclesClick = () => {
    navigate("/vehicleprefill");
  };

  return (
    <div className="landing-main-page">
      <Toast ref={toast} />

      <Header />
      <div className="content-wrapper container-fluid landing-content-wrapper only-landing-wrapper">
        <div className="row mx-auto p-2 wrapper-below-div">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
            <div className="landing-page-blocks  d-flex align-items-center justify-content-center flex-column">
              <img
                src={AddAppsIcon}
                className="landing-page-addapp-img show-cursor"
                onClick={(e: any) => appsRef.current?.toggle(e)}
                height={60}
                width={60}
              />
              <span
                className="text-18 add-new-app-title"
                onClick={(e: any) => appsRef.current?.toggle(e)}
              >
                Add New Apps
              </span>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
            {settingLoading ? (
              <div className="col-12 landing-page-blocks pf-landing-block d-flex align-items-center justify-content-center">
                <AtkLoader />
              </div>
            ) : showPrimaryAmsMessage ? (
              <div className="col-12 landing-page-blocks pf-landing-block d-flex align-items-center text-center">
                <span className="text-dark">
                  This version of the website does not support your AMS; please
                  use the Chrome Extension.
                </span>
              </div>
            ) : localStorage.getItem("tenantId") === "0" ? (
              <div className="col-12 landing-page-blocks pf-landing-block d-flex align-items-center justify-content-center">
                <span className="text-16 text-dark">
                  Please{" "}
                  <a
                    onClick={gotopfsettingPage}
                    className="no-primary-ams"
                  >
                    select
                  </a>{" "}
                  your primary AMS
                </span>
              </div>
            ) : nowCertsMessageShow &&
              CommonValues.GetTenantId() ===
                process.env.REACT_APP_NowCerts_Tenant_Id ? (
              <div className="col-12 landing-page-blocks pf-landing-block d-flex align-items-center text-center">
                <span className="text-dark">
                  Please{" "}
                  <a
                    onClick={gotopfsettingPage}
                    className="no-primary-ams"
                  >
                    provide
                  </a>{" "}
                  your NowCerts credentials to continue.
                </span>
              </div>
            ) : CommonValues.GetTenantId() ===
                process.env.REACT_APP_Hawksoft_Tenant_Id &&
              (localStorage.getItem("HawksoftAgencyId") === "false" ||
                localStorage.getItem("HawksoftAgencyId") == null ||
                localStorage.getItem("HawksoftAgencyId") === "") ? (
              <div className="col-12 landing-page-blocks pf-landing-block d-flex align-items-center text-center">
                <span className="text-dark">
                  Please{" "}
                  <a
                    onClick={gotopfsettingPage}
                    className="no-primary-ams"
                  >
                    provide{" "}
                  </a>{" "}
                  your HawkSoft agency id to continue.
                </span>
              </div>
            ) : CommonValues.GetTenantId() ===
                process.env.REACT_APP_Vertafore_Tenant_Id &&
              (localStorage.getItem("VertaforeSetting") === "true" ||
                localStorage.getItem("VertaforeSetting") == null ||
                localStorage.getItem("VertaforeSetting") === "") ? (
              <div className="col-12 landing-page-blocks pf-landing-block d-flex align-items-center text-center">
                <span className="text-dark">
                  Please{" "}
                  <a
                    onClick={gotopfsettingPage}
                    className="no-primary-ams"
                  >
                    provide{" "}
                  </a>{" "}
                  your AMS360 credentials to continue.
                </span>
              </div>
            ) : (
              <>
                <div
                  className="landing-page-blocks pf-landing-block show-cursor"
                  onClick={onNewQuoteClick}
                >
                  <img
                    src={PFLogo}
                    className="landing-page-img"
                  />
                  <span className="landing-page-title2">Premium Finance</span>
                </div>
              </>
            )}

            {showMessage && (
              <div className="col-12 my-3 text-center text-16 text-light">
                <span>
                  Please{" "}
                  <a
                    onClick={onSettingTileClick}
                    style={{
                      color: "#7ba340",
                    }}
                    className="show-cursor"
                  >
                    choose{" "}
                  </a>{" "}
                  Premium Finance partner(s).
                </span>
              </div>
            )}

            <OverlayPanel
              ref={appsRef}
              className="addapps-panel"
            >
              <AddApps />
            </OverlayPanel>
          </div>
          {showOCR ? (
            <>
              {hostname == process.env.REACT_APP_Momentum_Url &&
              CommonValues.GetTenantId() ===
                process.env.REACT_APP_NowCerts_Tenant_Id ? (
                <>
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
                    {loading ? (
                      <div className="col-12 landing-page-blocks pf-landing-block d-flex align-items-center justify-content-center">
                        <AtkLoader />
                      </div>
                    ) : (
                      <div
                        className="landing-page-blocks pf-landing-block show-cursor"
                        onClick={onCoiScanClick}
                      >
                        <img
                          src={NowCertLogo}
                          className="landing-page-coi-scan-icon"
                        />
                        <span className="landing-page-title2">
                          NowCerts COI Scan
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
                    {loading ? (
                      <div className="col-12 landing-page-blocks pf-landing-block d-flex align-items-center justify-content-center">
                        <AtkLoader />
                      </div>
                    ) : (
                      <div
                        className="landing-page-blocks pf-landing-block show-cursor"
                        onClick={onSmartScanClick}
                      >
                        <img
                          src={NowCertLogo}
                          className="landing-page-coi-scan-icon"
                        />
                        <span className="landing-page-title2">
                          Acord Form Scan
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
                    {loading ? (
                      <div className="col-12 landing-page-blocks pf-landing-block d-flex align-items-center justify-content-center">
                        <AtkLoader />
                      </div>
                    ) : (
                      <div
                        className="landing-page-blocks show-cursor pf-landing-block"
                        onClick={onFenrisTileClick}
                      >
                        <img
                          src={FenrisLogo}
                          className="landing-page-img text-center"
                          width="60px"
                        />
                        <span className="landing-page-title2">AMS Prefill</span>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
                    {loading ? (
                      <div className="col-12 landing-page-blocks pf-landing-block d-flex align-items-center justify-content-center">
                        <AtkLoader />
                      </div>
                    ) : (
                      <div
                        className="landing-page-blocks show-cursor pf-landing-block"
                        onClick={onPFSmartScanClick}
                      >
                        <img
                          src={PFLogo}
                          className="landing-page-img text-center"
                          width="60px"
                        />
                        <span className="landing-page-title2">
                          Premium Finance Scan
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
                    {loading ? (
                      <div className="col-12 landing-page-blocks pf-landing-block d-flex align-items-center justify-content-center">
                        <AtkLoader />
                      </div>
                    ) : (
                      <div
                        className="landing-page-blocks show-cursor pf-landing-block"
                        onClick={onVehiclesClick}
                      >
                        <img
                          src={VehicleLogo}
                          className="landing-page-img text-center"
                        />
                        <span className="landing-page-title2">
                          Vehicle Prefill
                        </span>
                      </div>
                    )}
                  </div>
                </>
              ) : null}
            </>
          ) : null}

          {/* <div className="col-lg-3 col-md-3 col-sm-12 mb-2">
            <div className="landing-page-blocks">
              <img src={PFLogo} className="landing-page-img" />
              <span className="landing-page-title2">CL Rater</span>
            </div>
          </div> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
