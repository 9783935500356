import React, { lazy, Suspense, useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./popup-page/Login";
import PFHistory from "./RTG/PremiumFinance/PFHistory";
import LandingPage from "./popup-page/LandingPage";
import PFSavedQuotes from "./RTG/PremiumFinance/PFSavedQuotes";
import StartupPage from "./popup-page/StartupPage";
import PremiumFinance from "./RTG/PremiumFinance/PremiumFinance";
import PfSettings from "./RTG/PremiumFinance/Setting/PfSettings";
import CommonSetting from "./CommonSettings/CommonSetting";
import NowcertsSsoPage from "./OtherPages/NowcertsSsoPage";
import TermsAndConditions from "./OtherPages/TermsAndConditions";
import SetupStart from "./SetupWizard/SetupStart";
import Details from "./UserProfile/Details";
import PrimaryAmsSetting from "./SetupWizard/PrimaryAmsSetting";
import LinkUser from "./popup-page/LinkUser";
import { Skeleton } from "primereact/skeleton";
import MomentumTokenValidation from "./MomentumBridge/MomentumTokenValidation";
import ErrorPage from "./OtherPages/ErrorPage";
import NowCertsCOIScan from "./RTG/NowCertsOcrScan/COIScan/NowCertsCOIScan";
import COIHistory from "./RTG/NowCertsOcrScan/COIScan/COIHistory";
import FenrisMainPage from "../src/Fenris-poc/Fenris/FenrisMainPage";
import SmartScan from "./RTG/NowCertsOcrScan/SmartScan/NowCertsSmartScan";
import GodMode from "./Admin/GodMode";
import Billing from "./Billing/Billing";
import PFOcrScan from "../src/RTG/PremiumFinanceOCRScan/PFSOcrScan";
import Vehicle from "../src/VehicleData/Vehicle";
import PFScanMainPage from "../src/RTG/PremiumFinanceOCRScan/PFScanMainPage";
const hostname = window.location.hostname;

function App() {
  const [cssLoaded, setCssLoaded] = useState(false);
  useEffect(() => {
    if ((window as any).userGuiding) {
      console.log((window as any).userGuiding);
    }
    const loadCss = async () => {
      try {
        if (hostname === process.env.REACT_APP_Momentum_Url) {
          await import("./assets/css/momentumtoolbox-color.css");
        } else {
          await import("./assets/css/app-color.css");
        }
        setCssLoaded(true);
      } catch (error) {
        console.error("Error loading CSS:", error);
      }
    };

    loadCss();
  }, [hostname]);

  if (!cssLoaded) {
    return (
      <div>
        <Skeleton width="100%" height="100%" className="app-skeleton"></Skeleton>
      </div>
    );
  }

  return (
    <div className="">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/landingpage" element={<LandingPage />}></Route>
          <Route path="/pfhistory" element={<PFHistory />}></Route>
          <Route path="/pfsavedquotes" element={<PFSavedQuotes />}></Route>
          <Route path="/startuppage" element={<StartupPage />}></Route>
          <Route path="/pfhisotry" element={<PFHistory />}></Route>
          <Route path="/premiumfinance" element={<PremiumFinance />}></Route>
          <Route path="/pfsettings" element={<PfSettings />}></Route>
          <Route path="/commonsettings/:param" element={<CommonSetting />}></Route>
          <Route path="/commonsettings" element={<CommonSetting />} />
          <Route path="/nowcertssso" element={<NowcertsSsoPage />}></Route>
          <Route path="/terms" element={<TermsAndConditions />}></Route>
          <Route path="/userprofile" element={<Details />}></Route>
          <Route path="/setupwizard" element={<SetupStart />}></Route>
          <Route path="/setupwizardstart" element={<PrimaryAmsSetting />}></Route>
          <Route path="/linkuser" element={<LinkUser />}></Route>
          <Route path="/bridge" element={<MomentumTokenValidation />} />
          <Route path="/nowcertcoiscan" element={<NowCertsCOIScan />} />
          <Route path="/coihistory" element={<COIHistory />} />
          <Route path="/fenrisprefill" element={<FenrisMainPage />} />
          <Route path="/smartscan" element={<SmartScan />} />
          <Route path="/admin/users" element={<GodMode />} />
          <Route path="/billing" element={<Billing />}></Route>
          <Route path="/pfsmartscan" element={<PFOcrScan/>}></Route>
          <Route path="/vehicleprefill" element={<Vehicle/>}></Route>
          <Route path="/pfscan" element={<PFScanMainPage/>}></Route>

          <Route path="*" element={<ErrorPage />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
