import React, { FC, useEffect, useState, useRef } from "react";
import IgLogo from "../assets/images/IGLogoIcon.png";
import MomentumLogo from "../assets/images/momentum-logo.png";
import HelpIcon from "../assets/images/help.png";
import HomeIcon from "../assets/images/Home_V.svg";
import SettingIcon from "../assets/images/Settings_B.svg";
import FeedbackIcon from "../assets/images/feedback_B.svg";
import AppsIcon from "../assets/images/Addapps.png";
import ProfileIcon from "../assets/images/profile.svg";
import LogoutIcon from "../assets/images/logout.png";
import { Navigate, useNavigate } from "react-router-dom";
import CommonValues, { Logout } from "./utils";
import Feedback from "../OtherPages/Feedback";
import { OverlayPanel } from "primereact/overlaypanel";
import AddApps from "../OtherPages/AddApps";
import NewIcon from "../assets/images/NewQuote_B 1.svg";
import HistoryIcon from "../assets/images/History_B.svg";
import SaveIcon from "../assets/images/Saved Quotes_B.svg";
import axios from "axios";
import { Toast } from "primereact/toast";
import { useParams, useLocation } from "react-router-dom";
import BillingHeader from "../assets/images/BillingHeader.svg";

export default function Header(props: any) {
  const navigate = useNavigate();
  const [settingLoading, setSettingLoading] = useState(false);
  const feedbackRef = useRef<OverlayPanel>(null);
  const appsRef = useRef<OverlayPanel>(null);
  const mobileMenuRef = useRef<OverlayPanel>(null);
  const mobileSettingsMenuRef = useRef<OverlayPanel>(null);
  const [showPrimaryAmsMessage, setShowPrimaryAmsMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast: any = useRef("");
  const location = useLocation();
  const hostname = window.location.hostname;
  useEffect(() => {
    let token = CommonValues.GetToken();
    const isSetupCompleted = localStorage.getItem("isSetupCompleted");
    if (token != null && token != "") {
      if (isSetupCompleted === "true" && !window.location.href.includes("bridge")) {
        getCommonSettings();
      }
    }
  }, []);

  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token != null && token != "") {
      if (
        location.pathname !== "/setupwizard" &&
        location.pathname !== "/setupwizardstart" &&
        location.pathname !== "/" &&
        !window.location.href.includes("bridge")
      ) {
        getWizardSetupDetails();
      }
    }
  }, [location]);
  const getWizardSetupDetails = () => {
    var token = CommonValues.GetToken();
    setLoading(true);

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/getwizardsetup`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
    };
    axios(config)
      .then((response: any) => {
        setLoading(false);

        if (response.status == 204) {
          navigate("/startuppage");
        } else {
          if (response.data.isWizardSetupDone) {
            localStorage.setItem("isSetupCompleted", "true");
          } else {
            navigate("/setupwizard");
            localStorage.setItem("isSetupCompleted", "false");
          }
        }
      })
      .catch((error: any) => {
        let errorMessage = "";
        setLoading(false);

        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the Setting details";
          }
        } else {
          errorMessage = "Unknown error while loading the Setting details";
        }
        // toast.current.show({ severity: "error",  detail: { errorMessage }, life: 3000 });
      });
  };
  const getCommonSettings = () => {
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/pfcommonsettings`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
    };
    setSettingLoading(true);
    axios(config)
      .then((response: any) => {
        setSettingLoading(false);
        localStorage.setItem("HawksoftSettings", JSON.stringify(response.data));

        if (response.data.pfTenantId == process.env.REACT_APP_NowCerts_Tenant_Id) {
          localStorage.setItem("SelectedAMS", "Nowcert");
          setShowPrimaryAmsMessage(false);
        } else if (response.data.pfTenantId == process.env.REACT_APP_Hawksoft_Tenant_Id) {
          localStorage.setItem("SelectedAMS", "Hawksoft");
          setShowPrimaryAmsMessage(false);
        } else if (response.data.pfTenantId == process.env.REACT_APP_Vertafore_Tenant_Id) {
          localStorage.setItem("SelectedAMS", "Vertafore");
          setShowPrimaryAmsMessage(false);
        }
      })
      .catch((error: any) => {
        setSettingLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the Setting details";
          }
        } else {
          errorMessage = "Unknown error while loading the Setting details";
        }
      });
  };
  const handleSkip = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
    }
  };

  const onHelpIconClick = () => {
    window.open("https://support.insurancegig.com", "_blank");
  };
  const onHomeIconClick = () => {
    navigate("/landingpage");
  };
  const onLogoutClick = () => {
    navigate("/");
    CommonValues.Logout(navigate);
  };
  const onProfileClick = () => {
    navigate("/userprofile");
  };
  const onBillingClick = () => {
    navigate("/billing");
  };
  const onCommonSettingsClick = () => {
    navigate("/commonsettings");
  };
  const onLogoClick = () => {
    navigate("/landingpage");
  };
  const onNewQuoteClick = () => {
    navigate("/premiumfinance");
  };

  const onHistoryClick = () => {
    navigate("/pfhistory");
  };

  const onSavedQuotesClick = () => {
    navigate("/pfsavedquotes");
  };

  const onPfSettingClick = () => {
    navigate("/pfsettings");
  };
  const onPrimaryAmsClick = () => {
    navigate("/commonsettings/primaryamssettings");
    if (mobileSettingsMenuRef.current) {
      mobileSettingsMenuRef.current.hide();
    }
  };

  const onAmsAgencySettingsClick = () => {
    navigate("/commonsettings/amsagencysettings");
    if (mobileSettingsMenuRef.current) {
      mobileSettingsMenuRef.current.hide();
    }
  };

  const onGigcodeSettings = () => {
    navigate("/commonsettings/gigcodesettings");
    if (mobileSettingsMenuRef.current) {
      mobileSettingsMenuRef.current.hide();
    }
  };
  return (
    <>
      <Toast ref={toast} />

      <div className="row pf-header-row p-2 mx-auto d-flex align-items-center">
        <div className="col-lg-6 col-md-6  col-sm-6 col-6">
          {props.pagename == "setup-start" || props.pagename == "login" ? (
            <img
              src={hostname === process.env.REACT_APP_Momentum_Url ? MomentumLogo : IgLogo}
              className={hostname === process.env.REACT_APP_Momentum_Url ? "header-momentum-logo " : "header-ig-logo"}
            />
          ) : (
            <img
              src={hostname === process.env.REACT_APP_Momentum_Url ? MomentumLogo : IgLogo}
              className={hostname === process.env.REACT_APP_Momentum_Url ? "header-momentum-logo show-cursor" : "header-ig-logo show-cursor"}
              onClick={onLogoClick}
            />
          )}
        </div>
        <div
          className={
            CommonValues.GetToken() == null
              ? "col-lg-6 col-md-6  col-sm-6 col-6 d-flex  align-items-center justify-content-end"
              : "col-lg-6 col-md-6  col-sm-6  col-6 d-flex  align-items-center justify-content-end "
          }
        >
          {CommonValues.GetToken() == null || props.pagename == "setup-start" ? null : (
            <>
              <span className={location.pathname === "/landingpage" ? "header-span active " : "header-span"} onClick={onHomeIconClick}>
                <img src={HomeIcon} />
                <span>Home</span>
              </span>
              <span className="header-span display-for-desktop-flex" onClick={onHelpIconClick}>
                <img src={HelpIcon} />
                <span>Help</span>
              </span>
            </>
          )}

          {CommonValues.GetToken() == null ? null : (
            <>
              {props.pagename == "setup-start" ? null : (
                <>
                <span
                  className={
                    location.pathname === "/billing"
                      ? "header-span display-for-desktop-flex active "
                      : "header-span display-for-desktop-flex"
                  }
                  onClick={onBillingClick}
                >
                  <img src={BillingHeader} />
                  <span>Billing</span>
                </span>
                  <span
                    className={
                      location.pathname === "/commonsettings"
                        ? "header-span display-for-desktop-flex active "
                        : "header-span display-for-desktop-flex"
                    }
                    onClick={onCommonSettingsClick}
                  >
                    <img src={SettingIcon} />
                    <span>Settings</span>
                  </span>

                  <span
                    className={
                      location.pathname === "/commonsettings" ||
                      location.pathname === "/commonsettings/primaryamssettings" ||
                      location.pathname === "/commonsettings/amsagencysettings" ||
                      location.pathname === "/commonsettings/gigcodesettings"
                        ? "header-span display-for-mobile-flex active "
                        : "header-span display-for-mobile-flex"
                    }
                    onClick={(e: any) => mobileSettingsMenuRef.current?.toggle(e)}
                  >
                    <img src={SettingIcon} />
                    <span>Settings</span>
                  </span>

                  <OverlayPanel ref={mobileSettingsMenuRef} className="menu-panel display-for-mobile">
                    <span className="mobile-view-header-menu " onClick={onPrimaryAmsClick}>
                      <img src={SettingIcon} />
                      <span>Primary AMS</span>
                    </span>
                    <span className="mobile-view-header-menu " onClick={onAmsAgencySettingsClick}>
                      <img src={SettingIcon} />
                      <span>AMS Agency Settings</span>
                    </span>
                    {hostname === process.env.REACT_APP_Momentum_Url ? null : (
                      <span className="mobile-view-header-menu " onClick={onGigcodeSettings}>
                        <img src={SettingIcon} />
                        <span>Gigcode Settings</span>
                      </span>
                    )}
                  </OverlayPanel>
                  <span className=" header-span display-for-desktop-flex" onClick={(e: any) => feedbackRef.current?.toggle(e)}>
                    <img src={FeedbackIcon} />
                    <span>Feedback</span>
                  </span>

                  <span className="header-span display-for-desktop-flex" onClick={(e: any) => appsRef.current?.toggle(e)}>
                    <img src={AppsIcon} />
                    <span>Add Apps</span>
                  </span>
                  <span
                    className={
                      location.pathname === "/userprofile" ? "header-span display-for-desktop-flex active " : "header-span display-for-desktop-flex"
                    }
                    onClick={onProfileClick}
                  >
                    <img src={ProfileIcon} />
                    <span>Profile</span>
                  </span>
                </>
              )}
              <span className=" header-span display-for-desktop-flex " onClick={onLogoutClick}>
                <img src={LogoutIcon} />
                <span>Logout</span>
              </span>
            </>
          )}
          {CommonValues.GetToken() == null ? null : (
            <button
              className=" display-for-mobile"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={(e: any) => mobileMenuRef.current?.toggle(e)}
            >
              <span className="humberger-icon" style={{ color: "white !important" }}>
                <i className="fa fa-bars" />
              </span>
            </button>
          )}
          {/* </div> */}
        </div>
      </div>
      {CommonValues.GetToken() == null ? null : (
        <OverlayPanel ref={mobileMenuRef} className="menu-panel display-for-mobile">
          {props.pagename == "setup-start" ? null : (
            <>
              {showPrimaryAmsMessage ? null : (
                <>
                  <span
                    className={location.pathname === "/premiumfinance" ? "mobile-view-header-menu mb-menu active " : "mobile-view-header-menu"}
                    onClick={onNewQuoteClick}
                  >
                    <img src={NewIcon} />
                    <span>New Quote</span>
                  </span>

                  <span
                    className={location.pathname === "/pfhistory" ? "mobile-view-header-menu mb-menu active " : "mobile-view-header-menu"}
                    onClick={onHistoryClick}
                  >
                    <img src={HistoryIcon} />
                    <span>History </span>
                  </span>

                  <span
                    className={location.pathname === "/pfsavedquotes" ? "mobile-view-header-menu mb-menu active " : "mobile-view-header-menu"}
                    onClick={onSavedQuotesClick}
                  >
                    <img src={SaveIcon} />
                    <span>Saved Quotes </span>
                  </span>

                  <span
                    className={location.pathname === "/pfsettings" ? "mobile-view-header-menu mb-menu active " : "mobile-view-header-menu"}
                    onClick={onPfSettingClick}
                  >
                    <img src={SettingIcon} />
                    <span>PF Settings </span>
                  </span>
                </>
              )}
              <span className="mobile-view-header-menu " onClick={(e: any) => appsRef.current?.toggle(e)}>
                <img src={AppsIcon} />
                <span>Add Apps</span>
              </span>
              <span className=" mobile-view-header-menu" onClick={(e: any) => feedbackRef.current?.toggle(e)}>
                <img src={FeedbackIcon} />
                <span>Feedback</span>
              </span>

              <span className="mobile-view-header-menu ">
                <img src={HelpIcon} onClick={onHelpIconClick} />
                <span>Help</span>
              </span>
            </>
          )}
          {CommonValues.GetToken() == null ? null : (
            <>
              {props.pagename == "setup-start" ? null : (
                <span
                  className={location.pathname === "/userprofile" ? "mobile-view-header-menu mb-menu active " : "mobile-view-header-menu"}
                  onClick={onProfileClick}
                >
                  <img src={ProfileIcon} />
                  <span>Profile</span>
                </span>
              )}
              <span className="mobile-view-header-menu " onClick={onLogoutClick}>
                <img src={LogoutIcon} />
                <span>Logout</span>
              </span>
            </>
          )}
          {/* </div> */}
        </OverlayPanel>
      )}
      <OverlayPanel ref={feedbackRef} className="feedback-panel">
        <Feedback onSkip={handleSkip} />
      </OverlayPanel>

      <OverlayPanel ref={appsRef} className="addapps-panel">
        <AddApps />
      </OverlayPanel>
    </>
  );
}
