import Header from '../common/Header'
import { Toast } from "primereact/toast";
import Footer from '../common/Footer';
import axios from "axios";
import AtkTextField from '../AtlaskitControls/AtkTextField';
import { useEffect, useState, useRef } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import AtkButton from '../AtlaskitControls/AtkButton';
import CommonValues from '../common/utils';
import AtkLoader from '../AtlaskitControls/AtkLoader';
import { useNavigate } from 'react-router-dom';
const hostname = window.location.hostname;


export default function GodMode() {
    const navigate=useNavigate();
    const toast: any = useRef("");
    const [search, setSearch] = useState("");
    const [workEmail, setWorkEmail] = useState("");
    const [users, setUsers] = useState([]);
    const [supportloading, setSupportLoading] = useState(false);
    const [allusersLoading, setAllUsersLoading] = useState(false);

    useEffect(() => {
        if (hostname === process.env.REACT_APP_Momentum_Url) {
            setTimeout(() => {
                getAllUsers(true);
            }, 10);
          } else {
            setTimeout(() => {
                getAllUsers(false);
            }, 10);
          }
    }, [search]);

    const getAllUsers = (ismomemntum:boolean) => {
        setAllUsersLoading(true);
        var data = {
            ismomemntum: ismomemntum,
            search:search
          };

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_AUTHAPI}/getallsdkuser`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then((response: any) => {
                setAllUsersLoading(false);
                setUsers(response.data);
            })
            .catch((error: any) => {
                setAllUsersLoading(false);
                toast.current.show({ severity: "error",  detail: "Unknown error while getting all users", life: 3000 });
            });
    }
    const onClientSearchChange = (e:any) => { 
        setSearch(e.target.value)
    }
    const handleRowSelect = (e: any) => {
      
        setWorkEmail(e.value.workEmail);
    }
    const Supportlogin = (rowData:any) => { 
        setWorkEmail(rowData.workEmail)
        setSupportLoading(true);
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_AUTHAPI}/impersonate?email=` + rowData.workEmail,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
                'Content-Type': 'application/json'
            },
        };

        axios(config)
            .then((response: any) => {
                setSupportLoading(false);
                if (response.data.token == '') {
                    let errorMessage=""
                    errorMessage = "Oops... something went wrong. Please try again later.";
                    toast.current.show({ severity: "error",  detail: { errorMessage }, life: 3000 });
                }
                else {
                    toast.current.show({ severity: "success", detail: "Support login Done Successfully!", life: 3000 });
                    CommonValues.SetValues(
                        response.data.token,
                        response.data.tenantId,
                        response.data.userName,
                        response.data.userId,
                        response.data.companyName,
                        response.data.roleId,
                        response.data.avatarName,
                        response.data.firstName,
                        "",
                        response.data.iconURL,
                        response.data.hasGIGMarket,
                        response.data.slugURL,
                        response.data.headerText,
 
                        
                    );
                    setTimeout(() => {
                        navigate("/landingpage")  
                    }, 1000);
                   
                   
                }
            })
            .catch((error: any) => {
                setSupportLoading(false);
                toast.current.show({ severity: "error",  detail: "Unknown error while SupportLogin", life: 3000 });
            });
    }

    return (
        <div className="new-quote-main-page">
            <Toast ref={toast} />
            <Header />
            <div className="content-wrapper">
                <div className="row  wrapper-main-row mx-auto wrapper-below-div">

                    <div className="row main-content-row mx-auto">
                        <div className="col-lg-12 col-md-12 col-sm-12 main-content-col">
                            <div className="row client-search-row position-relative">

                                <div className="col-lg-4 col-md-6 col-sm-6 col-10 p-0 my-1 client-search-input-col position-relative mx-auto">
                                    <div className="search-bar ">
                                        <AtkTextField
                                            type="text"
                                            id="text"
                                            className="search-area "
                                            name="clientName"
                                            onChange={onClientSearchChange}
                                            value={search}
                                            //   onKeyDown={onKeyClick}
                                            placeholder={"Enter user name"}
                                        />
                                    </div>

                                </div>
                                <div className="col-lg-8 col-md-6 col-sm-6 col-10 p-0 my-1 client-search-input-col position-relative mx-auto">

                                </div>
                            </div>
                            {
                                allusersLoading ? 
                                <div className="d-flex justify-content-center align-items-center mt-2 fix-width-support">
                                <AtkLoader></AtkLoader>
                              </div>: 
                              <DataTable
                              value={users}
                              paginator
                              paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                              rows={10}
                              selectionMode="single"
                              onSelectionChange={(e) => {
                                  handleRowSelect(e);
                                }}
                          >
                              <Column header="Full Name" field="fullName" sortable></Column>
                              <Column header="Work Email" field="workEmail" sortable></Column>
                              <Column header="Company Name" field="companyName" sortable></Column>
                              <Column
                                  header="Action"
                                  body={(rowData: any) => {
                                      return supportloading && workEmail == rowData.workEmail ? 
                                       <div className="d-flex justify-content-center align-items-center mt-2">
                                      <AtkLoader></AtkLoader>
                                    </div>
                                      :<AtkButton label='Support Login' className="pf-primary-btn w-auto" 
                                      onClick={() => Supportlogin(rowData)}
                                      />;
                                  }}
                              ></Column>
                          </DataTable>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
