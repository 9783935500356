import Footer from "../common/Footer";
import Header from "../common/Header";

export default function TermsAndConditions() {
  return (
    <div className="nowcerts-sso-page">
      <Header />
      <div className="content-wrapper container-fluid landing-content-wrapper d-flex justify-content-center">
        <div className="terms-box  ">
          <div className="row mx-auto d-flex align-items-center">
            <div className="col-12   ">
              <div className="privacy-heading text-center">
                <h4 className="mt-1">Premium Finance Disclaimer</h4>
              </div>

              <div className="text-justify mt-3  container">
                <h6>
                  <b>The Policy(s):</b>
                </h6>
                <br />
                <ol>
                  <li>
                    Must be a property and casualty policy not life or health.
                  </li>
                  <br />
                  <li>
                    May not have any fully earned or accelerated earning
                    provisions, i.e. a wind policy that becomes fully earned in
                    the months from June through October, etc.
                  </li>
                  <br />
                  <li>
                    Cannot be a direct billed policy from the insurance company
                    or MGA.
                  </li>
                  <br />
                  <li>
                    The insured must not be under bankruptcy or court
                    protection.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
