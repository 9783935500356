import React, { FC, useEffect, useState, useRef } from "react";
import { Toast } from "primereact/toast";
import NowcertsSetting from "./NowcertsSetting";
import HawksoftSetting from "./HawksoftSetting";
import Ams360Setting from "./Ams360Setting";
import Footer from "../common/Footer";
import Header from "../common/Header";
import AmsAgencySetting from "./AmsAgencySetting";
import Gigcode from "./Gigcode";
import CommonValues, { Logout } from "../common/utils";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import AtkButton from "../AtlaskitControls/AtkButton";
import AtkLoader from "../AtlaskitControls/AtkLoader";

export default function CommonSetting() {
  const navigate = useNavigate();
  const [nowcertsSelected, setNowcertsSelected] = useState(false);
  const [hawksoftSelected, setHawksoftSelected] = useState(false);
  const [vertaforeSelected, setVertaforeSelected] = useState(false);
  const [showNowCertsSettings, setShowNowCertsSettings] = useState(false);
  const [showHawkSoftSettings, setShowHawkSoftSettings] = useState(false);
  const [showVertaforeSettings, setShowVertaforeSettings] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [updateSetupLoading, setUpdateSetupLoading] = useState(false);
  const [amsAgencySettingKey, setAmsAgencySettingKey] = useState(0);
  const [showPrimaryAms, setShowPrimaryAms] = useState(false);
  const [showAmsAgency, setShowAmsAgency] = useState(false);
  const [showGigcode, setShowGigcode] = useState(false);
  const { param } = useParams();
  const location = useLocation();
  const hostname = window.location.hostname;
  const toast: any = useRef("");

  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    if (param === "primaryamssettings") {
      setShowPrimaryAms(true);
      setShowAmsAgency(false);
      setShowGigcode(false);
    } else if (param === "amsagencysettings") {
      setShowPrimaryAms(false);
      setShowAmsAgency(true);
      setShowGigcode(false);
    } else if (param === "gigcodesettings") {
      setShowPrimaryAms(false);
      setShowAmsAgency(false);
      setShowGigcode(true);
    } else {
      setShowPrimaryAms(true);
      setShowAmsAgency(true);
      setShowGigcode(true);
    }
  }, [param]);

  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    localStorage.removeItem("client");
    if (localStorage.getItem("tenantId") == process.env.REACT_APP_NowCerts_Tenant_Id) {
      setShowNowCertsSettings(true);
      setNowcertsSelected(true);
    }
    if (localStorage.getItem("tenantId") == process.env.REACT_APP_Hawksoft_Tenant_Id) {
      setShowHawkSoftSettings(true);
      setHawksoftSelected(true);
    }
    if (localStorage.getItem("tenantId") == process.env.REACT_APP_Vertafore_Tenant_Id) {
      setShowVertaforeSettings(true);
      setVertaforeSelected(true);
    }
  }, []);

  const onHawksoftAMSSelected = () => {
    localStorage.setItem("SelectedAMS", "Hawksoft");
    setNowcertsSelected(false);
    setHawksoftSelected(!hawksoftSelected);
    setVertaforeSelected(false);
    updateTenantId(2, !hawksoftSelected, false, false);
    updateAmsAgencySetting();
  };
  const onVertaforeAMSSelected = () => {
    localStorage.setItem("SelectedAMS", "Vertafore");
    setNowcertsSelected(false);
    setHawksoftSelected(false);
    setVertaforeSelected(!vertaforeSelected);
    updateTenantId(3, false, !vertaforeSelected, false);
    updateAmsAgencySetting();
  };
  const onNowcertsAMSSelected = () => {
    localStorage.setItem("SelectedAMS", "Nowcert");
    setHawksoftSelected(false);
    setVertaforeSelected(false);
    setNowcertsSelected(!nowcertsSelected);
    updateTenantId(4, false, !nowcertsSelected, false);
    updateAmsAgencySetting();
  };
  const updateAmsAgencySetting = () => {
    setAmsAgencySettingKey((prevKey) => prevKey + 1);
  };
  const updateTenantId = (tenantId: any, hawksoft: any, vertafore: any, nowcerts: any) => {
    setValidationMessage("");
    setErrorMessage("");
    if (hawksoft == true || vertafore == true || nowcerts == true) {
      setLoading(true);
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_UserService_Url}/api/user/primaryams?tenantid=${tenantId}`,
        headers: {
          Authorization: `Bearer ${CommonValues.GetToken()}`,
        },
      };

      axios(config)
        .then((response: any) => {
          setLoading(false);
          setValidationMessage("");
          setErrorMessage("");
          localStorage.setItem("tenantId", tenantId);
          getCommonSettings();
        })
        .catch((error: any) => {
          setLoading(false);
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status === 401) {
              navigate("/");
              CommonValues.Logout(navigate);
            } else {
              errorMessage = "Unknown error while setting primary AMS";
              toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
            }
          }
        });
    } else {
      setValidationMessage("Primary AMS cannot be empty");
      if (localStorage.getItem("tenantId") == process.env.REACT_APP_NowCerts_Tenant_Id) {
        setShowNowCertsSettings(true);
        setNowcertsSelected(true);
      }
      if (localStorage.getItem("tenantId") == process.env.REACT_APP_Hawksoft_Tenant_Id) {
        setShowHawkSoftSettings(true);
        setHawksoftSelected(true);
      }
      if (localStorage.getItem("tenantId") == process.env.REACT_APP_Vertafore_Tenant_Id) {
        setShowVertaforeSettings(true);
        setVertaforeSelected(true);
      }
    }
  };
  // const getAmsAgencySettings = () => {
  //   setLoading(true);
  //   const token = CommonValues.GetToken();
  //   var config = {
  //     method: "get",
  //     url: `${process.env.REACT_APP_UserService_Url}/api/user/amsagencysettings`,
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };

  //   axios(config)
  //     .then((response: any) => {
  //       setLoading(false);
  //     })
  //     .catch((error: any) => {
  //       let errorMessage = "";
  //       if (error.response != null) {
  //         if (error.response.status === 401) {
  //           navigate("/");
  //           CommonValues.Logout(navigate);
  //         } else {
  //           errorMessage = "Unknown error while loading the AMS Agency settings";
  //           toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
  //         }
  //       }
  //     });
  // };
  const getCommonSettings = () => {
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/pfcommonsettings`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
    };
    axios(config)
      .then((response: any) => {
        localStorage.setItem("tenantId", response.data.pfTenantId);
        localStorage.setItem("clientCount", response.data.clientCount);
        if (response.data.pfTenantId == process.env.REACT_APP_NowCerts_Tenant_Id) {
          setShowNowCertsSettings(true);
          setNowcertsSelected(true);
          setHawksoftSelected(false);
          setVertaforeSelected(false);
          // getAmsAgencySettings();
        }
        if (response.data.pfTenantId == process.env.REACT_APP_Hawksoft_Tenant_Id) {
          setShowHawkSoftSettings(true);
          setHawksoftSelected(true);
          setNowcertsSelected(false);
          setVertaforeSelected(false);
          // getAmsAgencySettings();
        }
        if (response.data.pfTenantId == process.env.REACT_APP_Vertafore_Tenant_Id) {
          setShowVertaforeSettings(true);
          setVertaforeSelected(true);
          setHawksoftSelected(false);
          setNowcertsSelected(false);
          // getAmsAgencySettings();
        }
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the Setting details";
            toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
          }
        } else {
          errorMessage = "Unknown error while loading the Setting details";
          toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
        }
      });
  };
  const onRerunSetupWizardClick = () => {
    var token = CommonValues.GetToken();
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/updatewizardsetup?IsWizardSetupDone=${false}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
    };
    setUpdateSetupLoading(true);
    axios(config)
      .then((response: any) => {
        if (response.data.success) {
          localStorage.setItem("isSetupCompleted", "false");
          navigate("/setupwizard");
        }
        setUpdateSetupLoading(false);
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while selecting the rerun setup";
            toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
          }
        } else {
          errorMessage = "Unknown error while selecting the rerun setup";
          toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
        }
        setUpdateSetupLoading(false);
      });
  };

  return (
    <div className="common-setting-main-page">
      <Toast ref={toast} />

      <Header />
      <div className="content-wrapper container-fluid landing-content-wrapper d-flex">
        <div className="row mx-auto d-flex bg-light p-3 rounded w-100 wrapper-below-div" style={{ minHeight: "75vh" }}>
          <div className="col-12  text-end">
            {updateSetupLoading ? (
              <AtkLoader className=""></AtkLoader>
            ) : (
              <AtkButton label="Rerun Setup Wizard" onClick={onRerunSetupWizardClick} className="pf-primary-btn w-auto " />
            )}
          </div>
          <div className="col-lg-9 col-md-8 col-sm-12 primary-ams-main-block px-0 ">
            <div className="row mx-auto">
              {showPrimaryAms ? (
                <>
                  <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
                    <NowcertsSetting
                      nowcertsSelected={nowcertsSelected}
                      onPrimaryAMSSelected={onNowcertsAMSSelected}
                      getCommonSettings={getCommonSettings}
                    ></NowcertsSetting>
                  </div>
                  {hostname === process.env.REACT_APP_Momentum_Url ? null : (
                    <>
                      <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
                        <HawksoftSetting
                          hawksoftSelected={hawksoftSelected}
                          onPrimaryAMSSelected={onHawksoftAMSSelected}
                          getCommonSettings={getCommonSettings}
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
                        <Ams360Setting
                          vertaforeSelected={vertaforeSelected}
                          onPrimaryAMSSelected={onVertaforeAMSSelected}
                          getCommonSettings={getCommonSettings}
                        />
                      </div>
                    </>
                  )}
                </>
              ) : null}
            </div>
          </div>
          {showAmsAgency || showGigcode ? (
            <div className="col-lg-3 col-md-4 col-sm-12  px-0" style={{ minHeight: "75vh" }}>
              <div className="row mx-auto">
                {showAmsAgency ? (
                  <div className="col-12 mt-2 ">
                    <div className="common-setting-block">
                      <AmsAgencySetting Key={amsAgencySettingKey} />
                    </div>
                  </div>
                ) : null}
                {hostname === process.env.REACT_APP_Momentum_Url ? null : (
                  <>
                    {showGigcode ? (
                      <div className="col-12 ">
                        <div className="gigcode-block">
                          <Gigcode />
                        </div>
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Footer />
    </div>
  );
}
