import { ElementsConsumer } from "@stripe/react-stripe-js";
import axios from "axios";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AtkButton from "../AtlaskitControls/AtkButton";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import CommonValues from "../common/utils";
import AtkMessage from "../AtlaskitControls/AtkMessage";

function AddPaymentCredits(props: any) {
  const toast: any = useRef("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [cardDetailsLoading, setCardDetailsLoading] = useState(false);
  const [userHasPaymentMethod, setUserHasPaymentMethod] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState("");
  const [currentBalance, setCurrentBalance] = useState("0");
  const [errorPaymentAmount, setErrorPaymentAmount] = useState("");
  const [paymentMethodId, setPaymentMethodId] = useState("");
  const { stripe, elements } = props;
  const navigate = useNavigate();

  useEffect(() => {
    getCardDetails();
  }, []);

  const getCardDetails = () => {
    setCardDetailsLoading(true);
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/card-details`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success == true) {
          setCurrentBalance(
            "" + response.data.stripeCardDetails.currentBalance / 100
          );
          setPaymentMethodId(response.data.stripeCardDetails.paymentMethodId);
        }
        if (
          response.data.stripeCardDetails.cardNumber === undefined &&
          response.data.stripeCardDetails.expiryMonth === undefined &&
          response.data.stripeCardDetails.expiryYear === undefined
        ) {
          setUserHasPaymentMethod(false);
        }
        else{
          setUserHasPaymentMethod(true);
        }
        setCardDetailsLoading(false);
      })
      .catch((error) => {
        setCardDetailsLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting current balance",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting current balance",
            life: 3000,
          });
        }
      });
  };

  const confirmPaymentIntent = async (clientSecret: string) => {
    await stripe
      .confirmCardPayment(clientSecret, {
        payment_method: paymentMethodId,
      })
      .then((response: any) => {
        if (response.paymentIntent) {
          saveStripePaymentHistory(response);
        } else if (response.error) {
          saveFailedStripePayment(response);
        }
      })
      .catch((error: any) => {
        setSubmitLoading(false);
      });
  };
  const createPaymentIntent = async (paymentAmount: any) => {
    var token = CommonValues.GetToken();
    let data = JSON.stringify({
      Amount: parseInt(String(Number(paymentAmount) * 100)),
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/payment-intent`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        confirmPaymentIntent(response.data.clientSecret);
      })
      .catch((error: any) => {
        setSubmitLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error occured while creating payment intent.",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error occured while creating payment intent.",
            life: 3000,
          });
        }
      });
  };
  const saveFailedStripePayment = ({ error }: any) => {
    let token = CommonValues.GetToken();
    let data = JSON.stringify({
      amount: parseInt(String(error.payment_intent.amount)),
      paymentIntentId: error.payment_intent.id,
      status: error.payment_intent.status,
      failureMessage: error.message,
      responseJSON: JSON.stringify(error),
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/payment-history`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        setSubmitLoading(false);
        toast.current.show({
          severity: "error",
          detail: error.message,
          life: 3000,
        });
      })
      .catch((error: any) => {
        setSubmitLoading(false);
        if (error.response.status === 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            detail:
              "Unknown error occured while saving failed stripe payment history",
            life: 3000,
          });
        }
      });
  };
  const saveStripePaymentHistory = ({ paymentIntent }: any) => {
    let token = CommonValues.GetToken();
    let data = JSON.stringify({
      amount: parseInt(String(paymentIntent.amount)),
      paymentIntentId: paymentIntent.id,
      status: paymentIntent.status,
      responseJSON: JSON.stringify(paymentIntent),
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/payment-history`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        setSubmitLoading(false);
        if (paymentIntent.status === "succeeded") {
          getCardDetails();
          setPaymentAmount("");
          toast.current.show({
            severity: "success",
            detail: "Payment has succeeded",
            life: 3000,
          });
        }
      })
      .catch((error: any) => {
        setSubmitLoading(false);
        if (error.response.status === 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            detail:
              "Unknown error occured while saving successfull stripe payment history",
            life: 3000,
          });
        }
      });
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);
    //event.preventDefault();
    if (paymentAmount) {
      setErrorPaymentAmount("");
      if (!stripe || !elements) {
        return;
      }
      createPaymentIntent(paymentAmount);
    } else {
      setSubmitLoading(false);
      setErrorPaymentAmount("Please enter credit amount");
    }
  };

  const onPaymentAmountChange = (e: any) => {
    setPaymentAmount(e.target.value);
  };

  const handleAddPaymentMethod = () => {
    props.onTabSelect(1);
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="billing-payment-details">
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="row form-group text-start mt-3">
              <div className="col-md-12 text-16">
                <span>
                  <b>Buy Credits</b>
                </span>
              </div>
            </div>
            {cardDetailsLoading ? (
              <div className="text-center mt-4">
                <AtkLoader />
              </div>
            ) : (
              <>
                <div className="col-lg-12 col-md-12 col-sm-12 mt-3 text-start cred-title">
                  <label>
                    <b>Current Balance</b>
                  </label>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 my-2">
                  <div className="input-group">
                    <span className="input-group-text">$</span>
                    <AtkTextField
                      className="input-field form-control p-0"
                      type="text"
                      id="text"
                      value={currentBalance}
                      isDisabled={true}
                    />
                  </div>
                </div>
                {userHasPaymentMethod ? (
                  <>
                    <div
                      className={`col-lg-12 col-md-12 col-sm-12 mt-3 text-start cred-title ${
                        errorPaymentAmount.length > 0 ? "validation-div" : ""
                      }`}
                    >
                      <label>
                        <b>Credit Amount</b>
                      </label>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                      <div className="input-group ">
                        <span className="input-group-text">$</span>
                        <AtkTextField
                          className="input-field form-control p-0"
                          type="number"
                          minimum="1"
                          id="text"
                          value={paymentAmount}
                          onChange={onPaymentAmountChange}
                          placeholder="Enter credit amount"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 text-start">
                        <span className="text-danger small">
                          {errorPaymentAmount}
                        </span>
                      </div>
                    </div>
                  </>
                ) : null}
              </>
            )}
            <>
              {userHasPaymentMethod ? (
                <>
                  <div className="row">
                    <div className="col-md-12 mt-4 text-end">
                      {" "}
                      {submitLoading ? (
                        <AtkLoader />
                      ) : (
                        <AtkButton
                          className="pf-primary-btn w-auto"
                          label="Buy Credits"
                          type="submit"
                          onClick={handleSubmit}
                        ></AtkButton>
                      )}
                    </div>
                  </div>
                </>
              ) : null}
            </>
            {cardDetailsLoading ? null : userHasPaymentMethod ? null : (
              <div className="row">
                <div className="col-md-12 mt-4 text-center">
                  <AtkMessage messageText="User does not have a payment method for buying credits." />
                  <AtkButton
                    className="pf-primary-btn w-auto mt-3"
                    label="Add Payment Method"
                    type="button"
                    onClick={handleAddPaymentMethod}
                  ></AtkButton>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
    </>
  );
}

export default function InjectedStripePaymentCredits({ onTabSelect }: any) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <AddPaymentCredits
          stripe={stripe}
          elements={elements}
          onTabSelect={onTabSelect}
        />
      )}
    </ElementsConsumer>
  );
}
