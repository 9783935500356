import { useEffect } from "react";
import React from "react";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import CommonValues, { Logout } from "../../common/utils";
import { Navigate, useNavigate } from "react-router-dom";

export default function ShowMore(props: any) {
  const navigate = useNavigate();
  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
  }, []);
  return (
    <div className="row showmore-block">
      <div
        className={
          props.businessProfileError.length > 0
            ? "form-group validation-div col-lg-4 col-md-4 col-sm-12 mb-2"
            : "form-group col-lg-4 col-md-4 col-sm-12 mb-2"
        }
      >
        <label>Business Profile Name </label>
       <span className="text-danger ">*</span>

        <AtkTextField
          className="input-field"
          type="text"
          id="text"
          value={props.businessProfile}
          onChange={props.onBusinessProfileNameChange}
          placeholder="Enter business profile name"
        />
        <span className="text-danger text-12">{props.businessProfileError}</span>
      </div>

      <div
        className={
          props.addressError.length > 0 ? "form-group validation-div col-lg-4 col-md-4 col-sm-12 mb-2" : "form-group col-lg-4 col-md-4 col-sm-12 mb-2"
        }
      >
        <label>Address Line No 1</label>
       <span className="text-danger ">*</span>

        <AtkTextField
          className="input-field"
          type="text"
          id="text"
          value={props.addLine1}
          onChange={props.onAddressChange}
          placeholder="Enter address line no 1"
        />
        <span className="text-danger text-12">{props.addressError}</span>
      </div>

      <div
        className={
          props.cityError.length > 0 ? "form-group validation-div col-lg-4 col-md-4 col-sm-12 mb-2" : "form-group col-lg-4 col-md-4 col-sm-12 mb-2"
        }
      >
        <label>City</label>
       <span className="text-danger ">*</span>

        <AtkTextField className="input-field" type="text" id="text" value={props.city} onChange={props.onCityChange} placeholder="Enter city" />
        <span className="text-danger text-12">{props.cityError}</span>
      </div>

      <div
        className={
          props.stateError.length > 0 ? "form-group validation-div col-lg-4 col-md-4 col-sm-12 mb-2" : "form-group col-lg-4 col-md-4 col-sm-12 mb-2"
        }
      >
        <label>State</label>
       <span className="text-danger ">*</span>

        <AtkTextField className="input-field" type="text" id="text" value={props.state} onChange={props.onStateChange} placeholder="Enter state" />
        <span className="text-danger text-12">{props.stateError}</span>
      </div>

      <div
        className={
          props.countryError.length > 0 ? "form-group validation-div col-lg-4 col-md-4 col-sm-12 mb-2" : "form-group col-lg-4 col-md-4 col-sm-12 mb-2"
        }
      >
        <label>Country</label>
       <span className="text-danger ">*</span>

        <AtkTextField className="input-field" type="text" id="text" value={props.country} onChange={props.onCountryChange} placeholder="Enter country" />
        <span className="text-danger text-12">{props.countryError}</span>
      </div>

      <div
        className={
          props.zipCodeError.length > 0 ? "form-group validation-div col-lg-4 col-md-4 col-sm-12 mb-2" : "form-group col-lg-4 col-md-4 col-sm-12 mb-2"
        }
      >
        <label>Zip Code</label>
       <span className="text-danger ">*</span>

        <AtkTextField className="input-field" type="text" id="text" value={props.zipCode} onChange={props.onZipcodeChange} placeholder="Enter zip code" />
        <span className="text-danger text-12">{props.zipCodeError}</span>
      </div>

      <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
        <label>Email Address</label>

        <AtkTextField className="input-field" type="text" id="text" value={props.businessEmail} onChange={props.onBusinessEmailChange} placeholder="Enter email address" />
        <span className="text-danger text-12">{props.businessEmailError}</span>
      </div>

      <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
        <label>Phone Number</label>

        <AtkTextField className="input-field" type="text" id="text" value={props.phone} onChange={props.onBusinessPhoneNumberChange} placeholder="Enter phone number" />
        <span className="text-danger text-12">{props.businessPhoneNumberError}</span>
      </div>

      <div
        className={
          props.termError.length > 0 ? "form-group validation-div col-lg-4 col-md-4 col-sm-12 mb-2" : "form-group col-lg-4 col-md-4 col-sm-12 mb-2"
        }
      >
        <label>Term</label>
       <span className="text-danger ">*</span>

        <AtkTextField className="input-field" type="text" id="text" value={props.term} onChange={props.onTermChange} placeholder="Enter term" />
        <span className="text-danger text-12">{props.termError}</span>
      </div>
    </div>
  );
}
