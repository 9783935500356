import TextField from "@atlaskit/textfield";
import axios from "axios";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AtkButton from "../../AtlaskitControls/AtkButton";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import AtkMessage from "../../AtlaskitControls/AtkMessage";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import PfdIcon from "../../assets/images/file.png";
import PreviewIcon from "../../assets/images/paper.png";
import RemoveIcon from "../../assets/images/trashcan.png";
import CommonValues from "../../common/utils";
import { Toast } from "primereact/toast";
import PFMultiplePremium from "./PFMultiplePremium";

export default function PFOcrScan(props: any) {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [uploadFile, setUploadFile] = useState<any>([]);
  const [uploadFileLimit, setUploadFileLimit] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [filesCount, setFilesCount] = useState(0);
  const [requestFileCount, setRequestFileCount] = useState("");
  const [uploadingFiles, setUploadingFiles] = useState(false);
  const [uploadingRetry, setUploadingRetry] = useState(false);
  const [incompleteRequestData, setIncompleteRequestData] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isfileuploaded, setIsfileuploaded] = useState(false);
  const [fileNameTitle, setFileNameTitle] = useState("");
  const [fileName, setFileName] = useState("");
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [uploadPdfUrl, setUploadPdfUrl] = useState("");
  const [quoteNumber, setQuoteNumber] = useState("");
  const [premium, setPremium] = useState([""]);
  const [downPayment, setDownPayment] = useState([""]);
  const [financedAmount, setFinancedAmount] = useState([""]);
  const [effectiveAPR, setEffectiveAPR] = useState("");
  const [otherTaxes, setOtherTaxes] = useState([""]);
  const [otherFees, setOtherFees] = useState([""]);
  const [brokerFee, setBrokerFee] = useState("0");
  const [searchClientList, setSearchClientList] = useState<any>([]);
  const [searchClientLoading, setSearchClientLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [clientName, setClientName] = useState("");
  const [nowCertPolicyData, setNowCertPolicyData] = useState<any>([]);
  const [selectedPolicyIds, setSelectedPolicyIds] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [insuredNoPolicyError, setInsuredNoPolicyError] = useState("");
  const [submissionId, setSubmissionId] = useState("");
  const [clientNameErrorMessage, setClientNameErrorMessage] = useState("");
  const [uploadagain, setUploadAgain] = useState(false);
  const [grossPremiumList, setGrossPremiumList] = useState<any>([]);
  const [policyLoading, setPolicyLoading] = useState(false);
  const [showPFHistory, setShowPFHistory] = useState(false);
  const [selectedInsuredDetails, setSelectedInsuredDetails] = useState(0);
  const [premiumErrorMessage,setPremiumErrorMessage]=useState(Array().fill([]));
  const [selectedClientName,setSelectedClientName]=useState("");
  const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
  const [currentBalance, setCurrentBalance] = useState("0");

  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      navigate("/");
      CommonValues.Logout(navigate);
    }
    if (search == "") {
      return;
    }
    setSearchClientLoading(true);
    if (
      CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id
    ) {
      const searchTimeout = setTimeout(() => {
        getNowCertsClients(search);
      }, 10);
      return () => clearTimeout(searchTimeout);
    }
  }, [search]);

  useEffect(()=>{
    getCardDetails()

  },[])

  const onClientSearchChange = (value: any) => {
    if (value.length > 255) {
      return;
    }
    setSearch(value);
    setClientName(value);
  };

  const getCardDetails = () => {
    setCurrentBalanceLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success) {
          setCurrentBalance("" + response.data.currentBalance / 100);
        }
        setCurrentBalanceLoading(false);
      })
      .catch((error) => {
        setCurrentBalanceLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting current balance",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting current balance",
            life: 3000,
          });
        }
      });
  };

  const onDropHandler = (event: any) => {
    event.preventDefault();
    onFileSelectHandler(event.dataTransfer.files);
  };

  const onFileSelectHandler = (files: any) => {
    setFileName("");
    setFileNameTitle("");
    const isValidated = validateSelectedFiles(files);
    if (!isValidated) {
      setErrorMessage("Please select PDF files only");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return false;
    }

    let fileList = [];
    let totalSize = 0;
    let currentFiles = uploadFile;
    let filename = files[0].name;
    if (filename.length > 50) {
      let shortenString = filename.substring(0, 50).concat("...");
      setFileName(shortenString);
      setFileNameTitle(filename);
    } else {
      setFileName(filename);
      setFileNameTitle(filename);
    }
    for (let i = 0, len = files.length; i < len; i++) {
      fileList.push({
        file: files[i],
        name: files[i].name,
        size: files[i].size,
      });
      totalSize = totalSize + files[i].size;
    }

    if (fileList.length > 0) {
      if (currentFiles.length > 0) {
        for (var i = 0, l = currentFiles.length; i < l; i++) {
          for (var j = 0; j < fileList.length; j++) {
            if (currentFiles[i].name === fileList[j].name) {
              fileList.splice(j, 1);
            }
          }
        }
      }
      let allFiles = currentFiles.concat(fileList);
      if (allFiles.length > uploadFileLimit) {
        allFiles = allFiles.slice(0, uploadFileLimit);
        setErrorMessage(
          "Multiple files cannot be uploaded at the same time; please upload a single file"
        );
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
      setUploadFile(allFiles);
      setFilesCount(allFiles.length);
      setRequestFileCount(allFiles.length);
      setFilesCount(allFiles.length);
    }
  };

  const validateSelectedFiles = (files: any) => {
    var regularExpression = /(?:\.([^.]+))?$/;
    var isValid = true;

    for (let index = 0, len = files.length; index < len; index++) {
      const fileExtension: any = regularExpression.exec(files[index].name);
      if (fileExtension === undefined || fileExtension[0] == "") {
        isValid = false;
        break;
      }
      if (fileExtension?.[1].toLowerCase().includes("pdf")) {
        isValid = true;
      } else {
        isValid = false;
        break;
      }
    }
    return isValid;
  };

  const removeFileFromList = () => {
    setUploadFile([]);
    setIsfileuploaded(false);
    setSuccessMessage("");
  };

  const createUploadRequest = () => {
    setLoading(true);

    if (uploadFile.length == 0) {
      setErrorMessage("Please select at least one file to upload");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      setLoading(false);
      return;
    }

    if (selectedClientName.length == 0) {
      toast.current.show({
        severity: "error",
        detail: "Please select client name",
        life: 3000,
      });
      setLoading(false);
      return;
    }

    var FormData = require("form-data");
    var data = new FormData();
    data.append("file", uploadFile[0].file);
    let token = CommonValues.GetToken();
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_IPFS_Url}/api/OCR/pfsmartscan`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        let submissionId = response.data.submissionId;
        let grossPremiumList = response.data.grossPremium;
        setGrossPremiumList(grossPremiumList);
        let premiums: any = [];
        let fees: any = [];
        let taxes: any = [];
        let downPayment: any = [];
        let financedAmount: any = [];
        grossPremiumList.forEach((item: any, index: any) => {
          premiums.push(item.premium.replace(",", ""));
          fees.push(item.fees.replace(",", ""));
          taxes.push(item.taxes.replace(",", ""));
          downPayment.push(item.downPayment.replace(",", ""));
          financedAmount.push(item.amountFinanced.replace(",", ""));
        });
        setOtherFees(fees)
        setOtherTaxes(taxes)
        setDownPayment(downPayment)
        setFinancedAmount(financedAmount)
        setPremium(premiums)
        setIsFileUpload(true);
        setSubmissionId(submissionId);
        setUploadPdfUrl(response.data.fileurl);
        setQuoteNumber(response.data.quoteNumber.split(".")[0]);
        setEffectiveAPR(response.data.effectiveAPR);
        if (response.data.brokerFee !== undefined) {
          setBrokerFee(
            response.data.brokerFee === "" || response.data.brokerFee === null
              ? "0"
              : response.data.brokerFee.replace("$", "").replace(",", "")
          );
        }
        setLoading(false);
        getCardDetails();
      })
      .catch((error: any) => {
        console.log(error);
        localStorage.removeItem("currentPage");
        if (error.response != null && error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else {
          setErrorMessage("Error while creating the request.");
          setTimeout(() => {
            setErrorMessage("");
          }, 3000);
          setLoading(false);
        }
      });
  };

  const getNowCertsClients = (query: string) => {
    var data = {
      SearchValue: query,
    };
    let nowCertsToken = localStorage.getItem("NCToken")
      ? localStorage.getItem("NCToken")
      : "";
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlist?searchText=${query}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setSearchClientList(response.data);
        setSearchClientLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail:
                "Unknown error while loading the insureds data.Please try again later.",
              life: 3000,
            });
          }
        }
        // setErrorMessage(errorMessage);
        setSearchClientLoading(false);
      });
  };

  const onClientSelect = (client: any) => {
    let insuredDatabaseId = client.databaseId;
    setSearch("");
    setSelectedClientName(client.name);
    setClientName(client.name)
    getNowCertsPoliciesList(insuredDatabaseId);
  };

  const getNowCertsPoliciesList = (insuredId: string) => {
    setPolicyLoading(true);
    let nowCertsToken = localStorage.getItem("NCToken")
      ? localStorage.getItem("NCToken")
      : "";
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/insuredpolicies?insuredId=${insuredId}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.length > 0) {
          setPolicyLoading(false);
          setInsuredNoPolicyError("");
          setNowCertPolicyData(response.data);
          setSelectedPolicyIds(response.data[0].databaseId);
        } else {
          setNowCertPolicyData([]);
          setPolicyLoading(false);
          setInsuredNoPolicyError("Insured does not have policy.");
        }
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage =
              "Unknown error while loading the insureds data.Please try again later.";
          }
        }
        setPolicyLoading(false);
      });
  };

  const onSelectPolicies = (rowData: any, options: any) => {
    return (
      <div className="checkbox">
        <label>
          <input
            type="checkbox"
            value=""
            checked={selectedPolicyIds === rowData.databaseId ? true : false}
            onChange={() => setSelectedPolicyIds(rowData.databaseId)}
            className="show-cursor bundle-checkbox"
          />
        </label>
      </div>
    );
  };
  const onQuoteChange = (value: any) => {
    setQuoteNumber(value);
  };
  const onPremiumChange = (e: any,index:any) => {
    const premiumValue = [...premium];
    const errorValue = [...premiumErrorMessage];
    errorValue[index]=""
    setPremiumErrorMessage(errorValue);
    const regex = /^[0-9]*(\.[0-9]{0,2})?$/;
    if (/^[0-9]*(\.[0-9]*)?$/.test(e.target.value) && regex.test(e.target.value)) {
      premiumValue[selectedInsuredDetails] = e.target.value;
    }
    setPremium(premiumValue)
    let premiumIndex=index;
    grossPremiumList.map((item:any,index:any)=>{
       if(premiumIndex==index){
          
        return(
          item.premium=e.target.value
        )
       }
    })
  };

  const onDownPaymentChange = (e: any,index:any) => {
    const downPaymentValue = [...downPayment]
    const regex = /^[0-9]+(\.[0-9]{0,2})?$/;
    if (regex.test(e.target.value) || e.target.value === "") {
      downPaymentValue[selectedInsuredDetails] = e.target.value;
    }
    setDownPayment(downPaymentValue);
    let downPaymentIndex=index;
    grossPremiumList.map((item:any,index:any)=>{
       if(downPaymentIndex==index){
          
        return(
          item.downPayment=e.target.value
        )
       }
    })
  };
  const onFinancedAmountChange = (e: any,index:any) => {
    const financedAmountValue = [...financedAmount];
    const regex = /^[0-9]+(\.[0-9]{0,2})?$/;
    if (regex.test(e.target.value) || e.target.value === "") {
      financedAmountValue[selectedInsuredDetails] = e.target.value;
    }
    setFinancedAmount(financedAmountValue);
    let financedAmountIndex=index;
    grossPremiumList.map((item:any,index:any)=>{
       if(financedAmountIndex==index){
          
        return(
          item.amountFinanced=e.target.value
        )
       }
    })
  };
  const onEffectiveARPChange = (value: any) => {
    setEffectiveAPR(value);
  };
  const onOtherTaxesChange = (e: any,index:any) => {
    const otherTaxesValues = [...otherTaxes];
    const inputValue = e.target.value;
    const regex = /^[0-9]+(\.[0-9]{0,2})?$/;
    if (regex.test(inputValue) || inputValue === "") {
      otherTaxesValues[selectedInsuredDetails] = inputValue;
    }
    setOtherTaxes(otherTaxesValues);
    let otherTaxesIndex=index;
    grossPremiumList.map((item:any,index:any)=>{
       if(otherTaxesIndex==index){ 
        return(
          item.taxes=e.target.value
        )
       }
    })
  };

  const onOtherFeesChange = (e: any,index:any) => {
    let otherFeesValues = [...otherFees];
    const inputValue = e.target.value;
    const regex = /^[0-9]+(\.[0-9]{0,2})?$/;
    if (regex.test(inputValue) || inputValue === "") {
      otherFeesValues[selectedInsuredDetails] = inputValue;
    }
    setOtherFees(otherFeesValues);
    let otherFeesIndex=index;
    grossPremiumList.map((item:any,index:any)=>{
       if(otherFeesIndex==index){ 
        return(
          item.fees=e.target.value
        )
       }
    })
  };
  const onBrokerFeeChange = (value: any) => {
    setBrokerFee(value);
  };

  const validateInsuredDetails = () => {
    setPremiumErrorMessage([""]);
    let isValid = true;
    const errorValue = [...premiumErrorMessage];
    grossPremiumList.map((item:any,index:any)=>{
    if (item.premium.length === 0) {
      isValid = false;
       errorValue[index]="Total premium is required."
       setPremiumErrorMessage(errorValue);
      return isValid;
    }
  })
  return isValid;
};

  const getPolicyEndorsementFeeData = () => {
    let policyEndorsementFee: any = [];
    grossPremiumList.map((item: any, index: any) => {
      {
        let formattedPremium = item.premium.replace("$", "").replace(",", "");
        // let formattedDownPayment = item.downPayment.replace("$", "").replace(",", "");
        // let formattedFinancedAmount = item.amountFinanced.replace("$", "").replace(",", "")
        policyEndorsementFee.push({
          order: index,
          policyId: selectedPolicyIds,
          amount: parseFloat(formattedPremium),
          date: new Date().toJSON(),
          premiumType: 0,
          billingType: 4,
          type: 0,
          followUpDate: new Date().toJSON(),
          // receivables: [
          //   {
          //     type: 0,
          //     order:index,
          //     amountValue: parseFloat(formattedDownPayment),
          //     amountType: 1,
          //     dueDate: new Date().toJSON()
          //   },
          // ],
          // payables: [
          //   {
          //     type: 0,
          //     order:index,
          //     amountValue: parseFloat(formattedDownPayment),
          //     amountType: 1,
          //     dueDate: new Date().toJSON()
          //   },
          // ],
          // financings: [
          //   {
          //     type: 0,
          //     order:index,
          //     amountValue: parseFloat(formattedFinancedAmount),
          //     amountType: 1,
          //     dueDate: new Date().toJSON()
          //   },
          // ],
          // agencyCommissions: [
          //   {
          //     order:index,
          //     commissionType: 0,
          //     commissionValue: 10.0,
          //     type: 0,
          //     dueDate: new Date().toJSON()
          //   }
          // ]
        });
      }
    })

    if (
      (otherFees.length > 0 && otherFees !== null && otherFees !== undefined) ||
      (brokerFee !== "0" && brokerFee !== null && brokerFee !== undefined)
    ) {
      grossPremiumList.map((item: any, index: any) => {
        const formattedOtherFees = parseFloat(
          item.fees.replace("$", "").replace(",", "")
        );
        const formattedBrokerFee = parseFloat(
          brokerFee.replace("$", "").replace(",", "")
        );
        policyEndorsementFee.push({
          order: index,
          policyId: selectedPolicyIds,
          amount: formattedOtherFees + formattedBrokerFee,
          date: new Date().toJSON(),
          premiumType: 1,
          type: 3,
          followUpDate: new Date().toJSON()
        });
      })
    }
    if (otherFees.length > 0 && otherTaxes !== null && otherTaxes !== undefined) {
      grossPremiumList.map((item: any, index: any) => {
        const formattedOtherTaxes = parseFloat(
          item.taxes.replace("$", "").replace(",", "")
        );
        policyEndorsementFee.push({
          order: index,
          policyId: selectedPolicyIds,
          amount: formattedOtherTaxes,
          date: new Date().toJSON(),
          premiumType: 1,
          type: 4,
          followUpDate: new Date().toJSON()
        });
      })
    }
    return policyEndorsementFee;
  };

  const onSubmitClick = () => {
    if (!validateInsuredDetails()) {
      return;
    }
    setSubmitLoading(true);
    let nowCertsToken = localStorage.getItem("NCToken")
      ? localStorage.getItem("NCToken")
      : "";
    const policyEndorsementFee = getPolicyEndorsementFeeData();
    const data = JSON.stringify(policyEndorsementFee);
    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/insertpolicyendorsementfee?submissionId=${submissionId}&ncToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        toast.current.show({
          severity: "success",
          detail: "Policy endorsement fee added successfully",
          life: 3000,
        });
        setSubmitLoading(false);
        setUploadAgain(true);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while adding policy endorsement fee.",
              life: 3000,
            });
          }
        }
        setSubmitLoading(false);
      });
  };

  const handleCancelClick = () => {
    setNowCertPolicyData([]);
    setGrossPremiumList([]);
    setSearch("");
    setClientName("");
    setIsFileUpload(false);
    setUploadPdfUrl("");
    setQuoteNumber("");
    setPremium([""]);
    setDownPayment([""]);
    setFinancedAmount([""]);
    setEffectiveAPR("");
    setOtherTaxes([""]);
    setOtherFees([""]);
    setSelectedPolicyIds("");
    setBrokerFee("0");
    removeFileFromList();
  };

  const uploadAgain = () => {
    setNowCertPolicyData([]);
    setGrossPremiumList([]);
    setSearch("");
    setClientName("");
    setIsFileUpload(false);
    setUploadPdfUrl("");
    setQuoteNumber("");
    setPremium([""]);
    setDownPayment([""]);
    setFinancedAmount([""]);
    setEffectiveAPR("");
    setOtherTaxes([""]);
    setOtherFees([""]);
    setSelectedPolicyIds("");
    setBrokerFee("0");
    removeFileFromList();
    setUploadAgain(false);
  }

  const onSubPolicyDetailsSelect = (value: any) => {
    let indexValue = value;
    setSelectedInsuredDetails(indexValue);
  }

  return (
    <>
      <Toast ref={toast} />
      <div className="row mt-3 d-flex align-items-center">
              
              <div className="col-md-8">
              </div>
              <div className="col-md-2 pf-scan-balance">
              <label>
                  <b>Current Balance</b>
                </label>
              </div>
              <div className="col-md-2">
              {currentBalanceLoading ? (
                  <AtkLoader />
                ) : (
                  <div className="input-group">
                    <span className="input-group-text">$</span>
                    <AtkTextField
                      className="input-field form-control p-0"
                      type="text"
                      id="text"
                      value={currentBalance}
                      isDisabled={true}
                    />
                  </div>
                )}
              </div>
  </div>
      <div className="row main-content-row mx-auto ">

        <div
          className="col-lg-10 col-md-12 col-sm-12 main-content-col"
          style={{ width: "100%" }}
        >
          <div className="row m-3">
            {isFileUpload ? (
              <div className="col">
                <iframe
                  src={uploadPdfUrl}
                  style={{
                    overflow: "scroll",
                    height: "100%",
                    width: "100%",
                  }}
                ></iframe>
              </div>
            ) : (
              <>
                <div
                  className="col uploadNowcertsDoc25 me-3 "
                  onDrop={(e) => {
                    onDropHandler(e);
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="row w-100 ">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-10 p-0 my-1 mb-5 client-search-input-col position-relative mx-auto">
                      <div className="search-bar ">
                        <AtkTextField
                          type="text"
                          id="text"
                          className="search-area "
                          name="clientName"
                          onChange={(e) => {
                            onClientSearchChange(e.target.value);
                          }}
                          value={clientName}
                          //   onKeyDown={onKeyClick}
                          placeholder={"Enter client name"}
                          autoComplete="off"
                        />
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 pf-scan-client-search">
                        <span className="text-danger text-12">{clientNameErrorMessage}</span>
                      </div>

                      {search.length > 0 && (
                        <div
                          id="client-search"
                          className=" position-absolute  "
                        >
                          {searchClientLoading ? (
                            <div className="d-flex justify-content-center my-2 client-loader-div">
                              <AtkLoader />
                            </div>
                          ) : searchClientList.length == 0 ? (
                            <div
                              style={{
                                padding: "5px",
                                background: "white",
                              }}
                            >
                              <span
                                style={{
                                  color: "#ddd",
                                  marginLeft: "10px",
                                }}
                              >
                                No results
                              </span>
                            </div>
                          ) : (
                            searchClientList.map((item: any) => (
                              <div
                                style={{
                                  cursor: "pointer",
                                  width: "100%",
                                }}
                                onClick={() => {
                                  onClientSelect(item);
                                }}
                              >
                                <a
                                  href="#"
                                  style={{
                                    color: "black",
                                    width: "100%",
                                    fontSize: "12px",
                                  }}
                                >
                                  {item.name}
                                </a>
                              </div>
                            ))
                          )}
                        </div>
                      )}
                    </div>
                    {uploadFile.length > 0 ? (
                      <div className="col-12">
                        {uploadFile.map((file: any, index: any) => (
                          <div className="show-pdf-icon">
                            <div className="file-name">
                              <div className="preview-icon">
                                <img src={PreviewIcon} />
                              </div>
                              <div>
                                <div title={fileNameTitle}>
                                  {fileName}
                                </div>
                                <div>
                                  {(parseInt(file.size) / 1000).toFixed(
                                    0
                                  )}
                                  KB
                                </div>
                              </div>
                            </div>
                            {uploadComplete ? (
                              <div>
                                <span className="uploaded">
                                  Uploaded
                                </span>
                              </div>
                            ) : (
                              uploadingFiles == false && (
                                <div
                                  onClick={() => removeFileFromList()}
                                  className="show-cursor"
                                >
                                  <img src={RemoveIcon} />
                                </div>
                              )
                            )}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <>
                        <>
                          <div
                            className="row"
                            style={{ textAlign: "center" }}
                          >
                            <div className="col-12">
                              <img src={PfdIcon} alt="" width="70" />
                            </div>

                            <div className="col-12 mt-4">
                              <div className="col-12">
                                <span className="drag-and-drop">
                                  Drag & Drop file here
                                </span>
                              </div>
                              <div className="col-12 my-2">
                                <span
                                  style={{
                                    color: "black",
                                    fontSize: "large",
                                  }}
                                >
                                  or
                                </span>
                              </div>
                              <label className="mb-0 show-cursor">
                                <span className="browse">
                                  Browse PDF
                                </span>
                                <input
                                  type="file"
                                  name="filefield"
                                  accept=".pdf"
                                  id="bulkFileUploader"
                                  style={{
                                    visibility: "hidden",
                                    display: "none",
                                  }}
                                  onChange={(e) => {
                                    onFileSelectHandler(e.target.files);
                                  }}
                                />
                              </label>
                            </div>
                          </div>
                        </>
                      </>
                    )}

                    <div className="col-12 text-center">
                      {isfileuploaded ? null : (
                        <>
                          {uploadingFiles == false &&
                            incompleteRequestData == false &&
                            uploadingRetry == false &&
                            uploadFile.length > 0 && (
                              <div
                                className="d-flex justify-content-center"
                                style={{
                                  width: "100%",
                                  padding: "20px",
                                }}
                              >
                                {loading == false ? (
                                  <>
                                    <AtkButtonIconBefore
                                      label="Upload"
                                      className="pf-secondary-btn"
                                      onClick={() => {
                                        createUploadRequest();
                                      }}
                                    />
                                  </>
                                ) : (
                                  <div className="d-flex justify-content-center align-items-center p-2">
                                    <AtkLoader></AtkLoader>
                                  </div>
                                )}
                              </div>
                            )}
                        </>
                      )}

                      {errorMessage.length > 0 ? (
                        <div
                          className="d-flex justify-content-center Acord25-upload-file-error"
                          style={{
                            width: "100%",
                            padding: "20px 20px 0px",
                          }}
                        >
                          <AtkMessage
                            appearance="error"
                            messageText={errorMessage}
                          />
                        </div>
                      ) : null}
                      {successMessage.length > 0 ? (
                        <div
                          className="d-flex justify-content-center upload-file-error"
                          style={{
                            width: "100%",
                            padding: "20px 20px 0px",
                          }}
                        >
                          <AtkMessage
                            appearance="success"
                            messageText={successMessage}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </>
            )}
            <div
              className="col p-2 ms-2"
              style={{
                border: "1px solid darkgrey",
                borderRadius: "30px",
                minHeight: "66vh",
              }}
            >
              <div className="col smartscan-tab">

                {grossPremiumList != undefined && grossPremiumList.length > 0 ? (
                  grossPremiumList.map((item: any, index: any) => {
                    return (
                      <span
                        className={selectedInsuredDetails === index ? "policy-name-span active show-cursor" : "policy-name-span show-cursor"}
                        onClick={() => onSubPolicyDetailsSelect(index)}
                      >
                        Insured Details
                      </span>
                    );
                  })
                ) : (
                  <span className="policy-name-span show-cursor">
                    Insured Details
                  </span>
                )}
              </div>

              {grossPremiumList != undefined && grossPremiumList.length > 0 ? (
                grossPremiumList.map((item:any,index: any) => {
                  return (
                    <>
                      {index == selectedInsuredDetails ?
                        <PFMultiplePremium
                          index={index}
                          quoteNumber={quoteNumber}
                          premiumAmount={premium}
                          fees={otherFees}
                          taxes={otherTaxes}
                          downPayment={downPayment}
                          financedAmount={financedAmount}
                          effectiveAPR={effectiveAPR}
                          brokerFee={brokerFee}
                          onQuoteChange={onQuoteChange}
                          onPremiumChange={onPremiumChange}
                          onDownPaymentChange={onDownPaymentChange}
                          onEffectiveARPChange={onEffectiveARPChange}
                          onOtherTaxesChange={onOtherTaxesChange}
                          onOtherFeesChange={onOtherFeesChange}
                          onBrokerFeeChange={onBrokerFeeChange}
                          onFinancedAmountChange={onFinancedAmountChange}
                          premiumErrorMessage={premiumErrorMessage}
                         
                        />
                        : null}
                    </>
                  )
                })) : <>

                <div className="row smartscan-block mx-auto mt-2">
                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <label>Quote Number</label>
                    <TextField
                      className=""
                      type="text"
                      value={quoteNumber}
                      placeholder="Enter quote number"
                    // onChange={(e: any) => {
                    //   onQuoteChange(e.target.value);
                    // }}
                    />
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <label>Premium Amount ($)</label>
                    <span className="text-danger ">*</span>
                    <TextField
                      className=""
                      type="text"
                      value={premium}
                      placeholder="Enter premium amount"
                    // onChange={(e: any) => {
                    //   onPremiumChange(e.target.value);
                    // }}
                    />
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <label>Down Payment ($)</label>
                    <TextField
                      placeholder="Enter down payment"
                      className=""
                      type="text"
                      value={downPayment}
                    // onChange={(e: any) => {
                    //   onDownPaymentChange(e.target.value);
                    // }}
                    />
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <label>Financed Amount ($)</label>
                    <TextField
                      className=""
                      type="text"
                      value={financedAmount}
                      placeholder="Enter financed amount"
                    // onChange={(e: any) => {
                    //   onFinancedAmountChange(e.target.value);
                    // }}
                    />
                  </div>
                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <label>Effective ARP</label>
                    <TextField
                      className=""
                      value={effectiveAPR}
                      placeholder="Enter effective arp"
                      type="text"
                    // onChange={(e: any) => {
                    //   onEffectiveARPChange(e.target.value);
                    // }}
                    />
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <label>Other Taxes</label>
                    <TextField
                      className=""
                      value={otherTaxes}
                      placeholder="Enter other taxes"
                      type="text"
                    // onChange={(e: any) => {
                    //   onOtherTaxesChange(e.target.value);
                    // }}
                    />
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <label>Fees</label>
                    <TextField
                      className=""
                      value={otherFees}
                      placeholder="Enter fees"
                      type="text"
                    // onChange={(e: any) => {
                    //   onOtherFeesChange(e.target.value);
                    // }}
                    />
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <label>Broker Fee</label>
                    <TextField
                      className=""
                      value={brokerFee}
                      placeholder="Enter broker fee"
                      type="text"
                    // onChange={(e: any) => {
                    //   onBrokerFeeChange(e.target.value);
                    // }}
                    />
                  </div>
                </div>
              </>
              }

              {selectedInsuredDetails == 0 ?
                <>
                  <div className="row">
                    {policyLoading ?
                      <div className="d-flex justify-content-center my-2 client-loader-div">
                        <AtkLoader />
                      </div> : <>
                        {nowCertPolicyData.length !== 0 ? (
                          <div className="col-12  history-table mt-3">
                            <DataTable
                              value={nowCertPolicyData}
                              paginator
                              paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                              rows={5}
                              dataKey="id"
                            >
                              <Column
                                header=""
                                field=""
                                body={(rowData, options) =>
                                  onSelectPolicies(rowData, options)
                                }
                              ></Column>
                              <Column
                                header="Title"
                                field="linesOfBusiness[0]"
                                body={(rowData: any) => {
                                  if (
                                    rowData.linesOfBusiness != null ||
                                    rowData.linesOfBusiness !== undefined
                                  ) {
                                    return rowData.linesOfBusiness[0];
                                  }
                                }}
                                sortable
                              ></Column>
                              <Column
                                header="Effective Date"
                                field="effectiveDate"
                                body={(rowData: any) => {
                                  if (
                                    rowData.effectiveDate != null ||
                                    rowData.effectiveDate !== ""
                                  ) {
                                    return moment
                                      .utc(rowData.effectiveDate, "YYYY-MM-DD")
                                      .format("YYYY-MM-DD");
                                  }
                                }}
                                sortable
                              ></Column>
                              <Column
                                header="Policy Number"
                                field="policyNumber"
                                sortable
                              ></Column>
                              <Column
                                header="Total Premium"
                                field="totalPremium"
                                body={(rowData) => (
                                  <span>${rowData.totalPremium}</span>
                                )}
                                sortable
                              ></Column>
                            </DataTable>
                          </div>
                        ) : null}
                      </>
                    }
                  </div>

                  {insuredNoPolicyError.length !== 0 ? (
                    <AtkMessage
                      messageText={insuredNoPolicyError}
                    ></AtkMessage>
                  ) : null}

                  <div className="row">
                    <div className="col-12 d-flex align-items-center  justify-content-end my-2">
                      {uploadagain ?
                        <AtkButton
                          label="Upload again"
                          onClick={uploadAgain}
                          className="pf-secondary-btn w-auto me-2"
                        />
                        :
                        <>
                          {isFileUpload && selectedPolicyIds.length > 0 ? (
                            <>
                              {submitLoading ? (
                                <div className="d-flex justify-content-center align-items-center p-2">
                                  <AtkLoader></AtkLoader>
                                </div>

                              ) : (
                                <AtkButton
                                  label="Submit"
                                  onClick={onSubmitClick}
                                  className="pf-secondary-btn w-auto me-2"
                                />
                              )}
                              <AtkButton
                                label="Cancel"
                                className="pf-primary-btn w-auto"
                                onClick={handleCancelClick}
                              />
                            </>) : null}</>}
                    </div>
                  </div>
                </>
                : null
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}