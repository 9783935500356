import TextField from "@atlaskit/textfield";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CommonValues, { Logout } from "../../common/utils";
export default function PFMultiplePremium(props: any) {
    const navigate = useNavigate();
    useEffect(() => {
        const token = CommonValues.GetToken();
        if (token == "" || token == null) {
          CommonValues.Logout(navigate);
        }
      }, []);
    

    return (
        <>
            <div className="row smartscan-block mx-auto mt-2">
                <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <label>Quote Number</label>
                    <TextField
                        className=""
                        type="text"
                        value={props.quoteNumber}
                        placeholder="Enter quote number"
                    onChange={(e: any) => {
                      props.onQuoteChange(e.target.value);
                    }}
                    />
                </div>

                <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <label>Premium Amount ($)</label>
                    <span className="text-danger ">*</span>
                    <TextField
                        className=""
                        type="text"
                        value={props.premiumAmount.length >0 ? props.premiumAmount[props.index]:""}
                        placeholder="Enter premium amount"
                    onChange={(e: any) => {
                      props.onPremiumChange(e,props.index);
                    }}
                    />
                     {props.premiumErrorMessage.length > 0 ? (
                      <span className="text-danger small">
                        {props.premiumErrorMessage[props.index]}
                      </span>
                    ) : null}
                </div>

                <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <label>Down Payment ($)</label>
                    <TextField
                        placeholder="Enter down payment"
                        className=""
                        type="text"
                        value={props.downPayment != undefined ? props.downPayment[props.index]:""}
                    onChange={(e: any) => {
                      props.onDownPaymentChange(e,props.index);
                    }}
                    />
                </div>

                <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <label>Financed Amount ($)</label>
                    <TextField
                        className=""
                        type="text"
                        value={props.financedAmount != undefined ? props.financedAmount[props.index]:""}
                        placeholder="Enter financed amount"
                    onChange={(e: any) => {
                      props.onFinancedAmountChange(e,props.index);
                    }}
                    />
                </div>
                <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <label>Effective ARP</label>
                    <TextField
                        className=""
                        value={props.effectiveAPR}
                        placeholder="Enter effective arp"
                        type="text"
                    onChange={(e: any) => {
                      props.onEffectiveARPChange(e.target.value);
                    }}
                    />
                </div>

                <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <label>Other Taxes</label>
                    <TextField
                        className=""
                        value={props.taxes != undefined ? props.taxes[props.index]:0}
                        placeholder="Enter other taxes"
                        type="text"
                    onChange={(e: any) => {
                      props.onOtherTaxesChange(e,props.index);
                    }}
                    />
                </div>

                <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <label>Fees</label>
                    <TextField
                        className=""
                        value={props.fees != undefined ? props.fees[props.index]:0}
                        placeholder="Enter other fees"
                        type="text"
                    onChange={(e: any) => {
                      props.onOtherFeesChange(e,props.index);
                    }}
                    />
                </div>

                <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <label>Broker Fee</label>
                    <TextField
                        className=""
                        value={props.brokerFee}
                        placeholder="Enter broker fee"
                        type="text"
                    onChange={(e: any) => {
                      props.onBrokerFeeChange(e.target.value);
                    }}
                    />
                </div>
            </div>
        </>
    )
}